import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    width: '100%',
  },
  textContainer: {
    overflow: 'hidden',
    transition: 'height 0.2s ease-in-out',
  },
  text: {
    '& > p': {
      margin: '0',
    },
  },
  showMore: {
    display: 'none',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
    marginTop: '10px',
    '&.visible': {
      display: 'flex',
    },
  },
});
