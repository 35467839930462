import { DELETE, GET, PATCH, POST, PUT } from 'constants/requestMethods';

const api = {
  // General
  clock: { path: () => '/clock', method: GET },
  toggles: { path: () => '/toggles', method: GET },

  // Me
  me: { path: () => '/me', method: GET },
  updateLang: { path: () => '/me/update-lang', method: PUT },
  updateSpaceIdPreference: { path: () => '/me/spaceid', method: PUT },

  //Teachers
  getTeachers: { path: () => '/teachers', method: POST },

  // Schools
  getSchoolClasses: { path: (schoolId) => `/schools/${schoolId}/classes`, method: GET },
  getSchoolStudents: { path: (schoolId) => `/schools/${schoolId}/students`, method: GET },
  getSchoolGroups: { path: (schoolId) => `/schools/${schoolId}/groups`, method: GET },

  // Groups
  getGroups: { path: () => '/groups', method: GET },
  createGroup: { path: () => '/groups', method: POST },
  getGroup: { path: (groupId) => `/groups/${groupId}`, method: GET },
  leaveGroup: { path: (groupId) => `/groups/${groupId}`, method: DELETE },
  getGroupUsers: { path: (groupId) => `/groups/${groupId}/users`, method: GET },
  getGroupLabels: { path: (groupId) => `/groups/${groupId}/labels`, method: GET },
  getGroupTemplateExercises: { path: (groupId) => `/groups/${groupId}/template-exercises`, method: POST },
  getGroupTests: { path: (groupId) => `/groups/${groupId}/tests`, method: POST },
  removeTeacher: { path: (groupId) => `/groups/${groupId}/teachers/remove`, method: POST },
  addTeachers: { path: (groupId) => `/groups/${groupId}/teachers`, method: POST },
  getGroupTemplateRubrics: { path: (groupId) => `/groups/${groupId}/template-rubrics`, method: POST },
  editGroupSettings: { path: (groupId) => `/groups/${groupId}`, method: POST },
  deleteGroups: { path: () => '/groups/delete', method: PATCH },

  // Tests
  getTest: { path: (testId) => `/tests/${testId}`, method: GET },
  getTestStudents: { path: (testId, search, page) => `/tests/${testId}/students?${page ? `&page=${page}` : ''}${search ? `&search=${search}` : ''}`, method: GET },
  createTest: { path: () => '/tests', method: POST },
  deleteTest: { path: (testId) => `/tests/${testId}`, method: PATCH },
  editTest: { path: (testId) => `/tests/${testId}`, method: PUT },
  addExercises: { path: (testId) => `/tests/${testId}/exercises`, method: POST },
  addInformationBlock: { path: (testId) => `/tests/${testId}/information-blocks`, method: POST },
  addPauseItem: { path: (testId) => `/tests/${testId}/pause-items`, method: POST },
  exportTest: { path: (testId) => `/tests/${testId}/export`, method: POST },
  duplicateTest: { path: (testId) => `/tests/${testId}/duplicate`, method: POST },
  getTestGrades: { path: (testId) => `/tests/${testId}/grades`, method: GET },
  sendTest: { path: (testId) => `/tests/${testId}/send`, method: POST },
  setTestRubric: { path: (testId) => `/tests/${testId}/rubric`, method: PUT },
  addTestSection: { path: (testId) => `/tests/${testId}/sections`, method: POST },
  reorderTestItems: { path: (testId) => `/tests/${testId}/reorder-items`, method: PUT },
  getTestPublication: { path: (testId) => `/tests/${testId}/publication`, method: GET },

  // Template Exercises
  createTemplateExercise: { path: () => '/template-exercises', method: POST },
  getTemplateExercise: { path: (templateExerciseId) => `/template-exercises/${templateExerciseId}`, method: GET },
  deleteTemplateExercises: { path: () => '/template-exercises/delete', method: POST },
  editTemplateExercise: { path: (templateExerciseId) => `/template-exercises/${templateExerciseId}`, method: PUT },
  editTemplateExerciseLabels: { path: (templateExerciseId) => `/template-exercises/${templateExerciseId}/labels`, method: PUT },
  duplicateTemplateExercise: { path: (templateExerciseId) => `/template-exercises/${templateExerciseId}/duplicate`, method: GET },
  sendTemplateExercise: { path: () => '/template-exercises/send', method: POST },
  editTemplateExerciseSettings: { path: (templateExerciseId) => `/template-exercises/${templateExerciseId}/settings`, method: PUT },
  copyPublicTemplateExercise: { path: (templateExerciseId) => `/template-exercises/${templateExerciseId}/explore-copy`, method: POST },

  // Template Rubrics
  getTemplateRubric: { path: (templateRubricId) => `/template-rubrics/${templateRubricId}`, method: GET },
  createTemplateRubric: { path: () => '/template-rubrics', method: POST },
  editTemplateRubric: { path: (templateRubricId) => `/template-rubrics/${templateRubricId}`, method: PUT },
  deleteTemplateRubrics: { path: () => '/template-rubrics/delete', method: POST },
  sendTemplateRubrics: { path: () => '/template-rubrics/send', method: POST },
  duplicateTemplateRubrics: { path: () => '/template-rubrics/duplicate', method: POST },

  // Test Exercises
  editTestExercisePoints: { path: (testExerciseId) => `/test-exercises/${testExerciseId}/points`, method: PUT },
  moveTestExercise: { path: (testExerciseId) => `/test-exercises/${testExerciseId}/move`, method: PATCH },
  removeTestExercise: { path: (testExerciseId) => `/test-exercises/${testExerciseId}`, method: DELETE },
  editTestExercise: { path: (testExerciseId) => `/test-exercises/${testExerciseId}`, method: POST },
  editTestExerciseIsCompact: { path: (testExerciseId) => `/test-exercises/${testExerciseId}/is-compact`, method: PUT },
  getTestExercise: { path: (testExerciseId) => `/test-exercises/${testExerciseId}`, method: GET },

  // Test Exercise Criteria Weights
  editTestExerciseCriteriaWeight: { path: (testExerciseCriteriaWeightId) => `/test-exercise-criteria-weights/${testExerciseCriteriaWeightId}`, method: PUT },

  // Test Rubrics
  editTestRubric: { path: (testRubricId) => `/test-rubrics/${testRubricId}`, method: PUT },

  // Test Sections
  editTestSection: { path: (testSectionId) => `/test-sections/${testSectionId}`, method: PUT },
  deleteTestSection: { path: (testSectionId) => `/test-sections/${testSectionId}`, method: DELETE },

  // Publications
  getStudentPublications: { path: (page) => `/publications?page=${page}`, method: GET },
  createPublication: { path: () => '/publications', method: POST },
  editPublication: { path: (publicationId) => `/publications/${publicationId}`, method: PUT },
  editOnGoingPublication: { path: (publicationId) => `/publications/${publicationId}/ongoing`, method: PUT },
  deletePublication: { path: (publicationId) => `/publications/${publicationId}`, method: DELETE },
  getPublicationBasic: { path: (publicationId) => `/publications/${publicationId}/basic`, method: GET },
  publishGrades: { path: (publicationId) => `/publications/${publicationId}/publish-grades`, method: POST },
  exportStudentAttempts: { path: (publicationId) => `/publications/${publicationId}/student-attempts/export`, method: POST },
  getAttemptsCSV: { path: (publicationId, page) => `/publications/${publicationId}/attempts/csv?page=${page}`, method: GET },
  getPublicationStatistics: { path: (publicationId, basic) => `/publications/${publicationId}/statistics?basic=${basic}`, method: GET },
  createPublicationAttempts: { path: (publicationId) => `/publications/${publicationId}/create-attempts`, method: POST },
  editPublicationExercise: { path: (publicationId, publicationExerciseId) => `/publications/${publicationId}/exercises/${publicationExerciseId}`, method: POST },
  editPublicationExercisePoints: { path: (publicationId, exerciseId) => `/publications/${publicationId}/exercises/${exerciseId}/points`, method: PUT },
  editPublicationExerciseCriteriaWeight: { path: (publicationId, exerciseId) => `/publications/${publicationId}/exercises/${exerciseId}/weight`, method: PUT },
  editPublicationSection: { path: (publicationId, sectionId) => `/publications/${publicationId}/sections/${sectionId}`, method: PUT },

  // Attempts
  createAttempt: { path: () => '/attempts', method: POST },
  deleteAttempt: { path: (attemptId) => `/attempts/${attemptId}`, method: PATCH },
  getAttempt: { path: (attemptId) => `/attempts/${attemptId}`, method: GET },
  getAttemptBaseItems: { path: (attemptId) => `/attempts/${attemptId}/base-items`, method: GET },
  getAttemptNavigationItems: { path: (attemptId) => `/attempts/${attemptId}/navigation-items`, method: GET },
  getAttemptSectionItems: { path: (attemptId, answerSectionId) => `/attempts/${attemptId}/section/${answerSectionId}`, method: GET },
  attemptNextSectionItem: { path: (attemptId, answerSectionId) => `/attempts/${attemptId}/section/${answerSectionId}/next-section-item`, method: PATCH },
  attemptPreviousSectionItem: { path: (attemptId, answerSectionId) => `/attempts/${attemptId}/section/${answerSectionId}/previous-section-item`, method: PATCH },
  attemptNextBaseItem: { path: (attemptId) => `/attempts/${attemptId}/next-base-item`, method: PATCH },
  attemptPreviousBaseItem: { path: (attemptId) => `/attempts/${attemptId}/previous-base-item`, method: PATCH },
  getFullAttempt: { path: (attemptId) => `/attempts/${attemptId}/full`, method: GET },
  completeAttempt: { path: (attemptId) => `/attempts/${attemptId}/complete`, method: PATCH },
  getReviewAnswers: { path: (attemptId) => `/attempts/${attemptId}/review-answers`, method: GET },
  confirmTestPassword: { path: (attemptId) => `/attempts/${attemptId}/confirm-password`, method: POST },
  increaseMediaRepetitions: { path: (attemptId, mediaUrl) => `/attempts/${attemptId}/media?mediaUrl=${mediaUrl}`, method: POST },
  getAttemptMedia: { path: (attemptId, mediaUrl) => `/attempts/${attemptId}/media?mediaUrl=${mediaUrl}`, method: GET },
  verifyExitPin: { path: (attemptId) => `/attempts/${attemptId}/verify-exit-pin`, method: POST },
  moveToItem: { path: (attemptId) => `/attempts/${attemptId}/move-to-item`, method: POST },
  getAttemptAnswer: { path: (attemptId, answerId) => `/attempts/${attemptId}/answers/${answerId}`, method: GET },
  updateAttemptValidation: { path: (attemptId) => `/attempts/${attemptId}/validation`, method: PUT },

  // Answers
  answerExercise: { path: (attemptId, answerId) => `/attempts/${attemptId}/answers/${answerId}`, method: PUT },
  answerJustification: { path: (attemptId, answerId) => `/attempts/${attemptId}/answers/${answerId}/justification`, method: PUT },
  editAnswerGrade: { path: (answerId) => `/answers/${answerId}/grade`, method: PUT },
  editAnswerFeedback: { path: (answerId) => `/answers/${answerId}/feedback`, method: PUT },

  // Answer Criteria Grades
  editAnswerCriteriaGrade: { path: (answerCriteriaGradeId) => `/answer-criteria-grades/${answerCriteriaGradeId}`, method: PATCH },

  // Subjects
  getSubjects: { path: () => '/subjects', method: GET },

  // Years
  getYears: { path: () => '/years', method: GET },

  //Labels
  createLabel: { path: () => '/labels', method: POST },
  editLabel: { path: (labelId) => `/labels/${labelId}`, method: PUT },
  deleteLabel: { path: (labelId) => `/labels/${labelId}`, method: DELETE },

  // Explore
  getPublicItems: { path: () => '/explore', method: POST },

  // Files
  uploadFile: { path: (resize = true) => `/files/upload-file?resize=${resize}`, method: POST, contentType: 'multipart/form-data' },

  // Tours
  startTourTest: { path: () => '/tours', method: POST },
  logTourEvent: { path: () => '/tours/events', method: POST },

  // Publication Exports
  getPublicationExport: { path: (publicationExportId) => `/publication-exports/${publicationExportId}`, method: GET },
  zipPublicationExport: { path: (publicationExportId) => `/publication-exports/${publicationExportId}`, method: POST },

  // Logs
  log: { path: () => '/log', method: POST },
};

export default api;
