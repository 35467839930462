import React, { useCallback, useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import slugify from 'slugify';

import { selectTest, selectTestPublication } from 'actions/testActions';
import api from 'api';
import { WRITE } from 'constants/exerciseOptions';
import { CAPTION, CHOICES, CONNECTING, FILLING, IMAGE, INFORMATION, ORDERING, PAUSE, SEGMENTATION, TEXT, TRUEFALSE } from 'constants/exerciseTypes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';
import { extractOpsText, simpleCompare } from 'utils';

import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import LoadingDocumentGeneration from 'components/test/LoadingDocumentGeneration';

import { DOCUMENT_DOWNLOAD, DOCUMENT_GENERATION, DOCUMENT_IDLE } from './constants';
import useStyles from './styles';

const ExportAttemptsCSVModal = ({ open, close }) => {
  const toast = useToast();
  const [getAttemptsCSVRequest] = useApi(api.getAttemptsCSV);
  const { name } = useSelector(selectTest);
  const publication = useSelector(selectTestPublication);
  const classes = useStyles();

  const [documentState, setDocumentState] = useState(DOCUMENT_IDLE);
  const [csvFile, setCsvFile] = useState(null);

  const getAnswer = (answer) => {
    if (answer.type === CHOICES) {
      return answer.valueChoiceIdentifier || answer.valueMultipleChoices?.map(valueMultipleChoice => valueMultipleChoice.identifier || 'null').join(',');
    }

    if (answer.type === TRUEFALSE) {
      return typeof answer.valueTrueFalse === 'boolean' ? answer.valueTrueFalse : 'null';
    }

    if (answer.type === TEXT) {
      return answer.isShortAnswer ? answer.valueText : extractOpsText(answer.quillAnswer?.ops);
    }

    if (answer.type === IMAGE) {
      return answer.valueImages?.map(valueImage => valueImage.value).join(',');
    }

    if (answer.type === FILLING) {
      const gaps = answer.statement.ops
        .reduce((gaps, op) => {
          if (op.insert.gap) {
            const gap = answer.valueGaps.find(valueGap => valueGap.position === op.attributes.position.toString());
            return [...gaps, gap];
          }

          return gaps;
        }, []);

      if (answer.option !== WRITE) {
        return gaps
          .map(valueGap => valueGap.identifier || 'null')
          .join(',');
      } else {
        return gaps
          .map(valueGap => valueGap.text ? JSON.parse(valueGap.text)[0].insert.trim() : 'null')
          .join(',');
      }
    }

    if (answer.type === ORDERING) {
      return answer.valueOrderItems?.sort((a, b) => simpleCompare(a.order, b.order)).map(valueOrderItem => valueOrderItem.identifier || 'null').join(',');
    }

    if (answer.type === CAPTION && answer.option !== WRITE) {
      return answer.valueGaps?.sort((a, b) => simpleCompare(a.gapCoords?.x, b.gapCoords?.x)).map(valueGap => valueGap.identifier || 'null').join(',');
    }

    if (answer.type === CAPTION && answer.option === WRITE) {
      return answer.valueGaps?.sort((a, b) => simpleCompare(a.gapCoords?.x, b.gapCoords?.x)).map(valueGap => !valueGap.text ? 'null' : `${valueGap.text} (${valueGap.identifier})`).join(',');
    }

    if (answer.type === CONNECTING || answer.type === SEGMENTATION) {
      return answer.valueConnections?.map(valueConnection => `${valueConnection.startIdentifier}->${valueConnection.endIdentifier}` || 'null').join(',');
    }

    return null;
  };

  const createAttemptsCSV = useCallback((data, isFirstPage) => {
    if (data.attempts.length === 0) {
      return;
    }

    data.answers = data.answers.reduce((answers, answer) => {
      const publicationExercise = data.publicationExercises.find(publicationExercise => publicationExercise.id === answer.publicationExerciseId);

      if (publicationExercise.type === INFORMATION || publicationExercise.type === PAUSE) {
        return answers;
      }

      return [
        ...answers,
        {
          ...publicationExercise,
          ...answer,
        },
      ];
    }, []);

    data.answerSections.forEach(answerSection => {
      const sectionAnswers = data.answers
        .filter(answer => answer.answerSectionId === answerSection.id)
        .sort((a, b) => simpleCompare(a.order, b.order));

      answerSection.attemptId = sectionAnswers[0].attemptId;
      answerSection.answers = sectionAnswers;
    });

    data.attempts.forEach(attempt => {
      const attemptSections = data.answerSections.filter(answerSection => answerSection.attemptId === attempt.id);
      const attemptAnswers = data.answers
        .filter(answer => answer.attemptId === attempt.id && answer.answerSectionId === null);

      attempt.items = [...attemptAnswers, ...attemptSections]
        .sort((a, b) => simpleCompare(a.order, b.order));
    });

    let csv = '';

    if (isFirstPage) {
      csv = 'identifier,startedAt,endedAt';

      let sectionCounter = 0, baseAnswerCounter = 0;
      data.attempts[0].items.forEach(item => {
        if (item.answers) {
          sectionCounter++;
          let sectionAnswerCounter = 0;
          for (let i = 0; i < item.answers.length; i++) {
            sectionAnswerCounter++;
            csv += `,s${sectionCounter}a${sectionAnswerCounter}_grade,s${sectionCounter}a${sectionAnswerCounter}_max_grade,s${sectionCounter}a${sectionAnswerCounter}_answered,s${sectionCounter}a${sectionAnswerCounter}_answer`;
          }
          return;
        }

        baseAnswerCounter++;
        csv += `,a${baseAnswerCounter}_grade,a${baseAnswerCounter}_max_grade,a${baseAnswerCounter}_answered,a${baseAnswerCounter}_answer`;
      });
    }

    data.attempts.forEach(attempt => {
      csv += `\n${attempt.userName},${attempt.startedAt},${attempt.endedAt}`;

      attempt.items.forEach(item => {
        if (item.answers) {
          item.answers.forEach(answer => {
            csv += `,${answer.grade},${answer.maxGrade},${answer.answered},"${getAnswer(answer)}"`;
          });
          return;
        }

        csv += `,${item.grade},${item.maxGrade},${item.answered},"${getAnswer(item)}"`;
      });
    });

    setCsvFile((csvFile) => {
      const newBlob = new Blob([csvFile, csv], { type: 'text/csv;charset=utf-8' });
      return newBlob;
    });
  }, []);

  const getAttemptsBatch = useCallback(async (page) => {
    return await new Promise((resolve, reject) => {
      getAttemptsCSVRequest([publication.id, page], null, ({ data }) => {
        if (data.status === 0) {
          const isFirstPage = page === 1;
          createAttemptsCSV(data, isFirstPage);
          resolve({
            status: data.status,
            isLastPage: data.isLastPage,
          });
          return;
        }

        toast.error(lang.oops);
        reject({
          status: data.status,
        });
      });
    });
  }, [getAttemptsCSVRequest, publication, createAttemptsCSV, toast]);

  const exportAttemptsCSV = useCallback(async () => {
    setDocumentState(DOCUMENT_GENERATION);
    setCsvFile(new Blob([], { type: 'text/csv;charset=utf-8' }));

    let page = 1;
    let res;
    do {
      res = await getAttemptsBatch(page);
      page++;
    } while (res.status === 0 && !res.isLastPage);

    if (res.status !== 0) {
      setDocumentState(DOCUMENT_IDLE);
      return;
    }

    setDocumentState(DOCUMENT_DOWNLOAD);
  }, [getAttemptsBatch]);

  const _close = () => {
    setTimeout(() => {
      setDocumentState(DOCUMENT_IDLE);
    }, 300);
    close();
  };

  const download = () => {
    const objectURL = URL.createObjectURL(csvFile);

    const link = document.createElement('a');
    link.href = objectURL;
    link.download = slugify(`${name}.csv`);
    link.target = '_blank';
    link.click();

    URL.revokeObjectURL(objectURL);
    link.remove();
  };

  return (
    <Modal
      open={open}
      close={_close}
      header={lang.test.grades.exportAttemptsCSV}
      center
      transition
      small
    >
      <div className={classes.downloadAttemptsCSVContainer}>
        {documentState === DOCUMENT_IDLE &&
          <Button
            onClick={exportAttemptsCSV}
          >
            {lang.generateDocument}
          </Button>
        }
        {documentState === DOCUMENT_GENERATION &&
          <LoadingDocumentGeneration />
        }
        {documentState === DOCUMENT_DOWNLOAD &&
        <>
          {lang.test.attemptsExport.clickBelowToDownloadDocuments}
          <Button onClick={download}>
            {lang.download}
          </Button>
        </>
        }
      </div>
    </Modal>
  );
};

ExportAttemptsCSVModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default ExportAttemptsCSVModal;
