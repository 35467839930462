import { createUseStyles } from 'react-jss';

export default createUseStyles({
  formulaWrapper: {
    display: 'inline-block',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#cecece',
      padding: '5px',
      borderRadius: '5px',
    },
  },
});
