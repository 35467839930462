import React, { useEffect, useRef, useState } from 'react';
import { Spacer } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectAttemptExercise, updateCriteriaGrade, updateFeedback, updateGrade } from 'actions/attemptActions';
import { selectUserIsAdmin } from 'actions/userActions';
import api from 'api';
import { INFORMATION, PAUSE } from 'constants/exerciseTypes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { quillIsEmpty } from 'utils';

import EntityActionsContainer from 'components/common/entity/EntityActionsContainer';
import EntityBody from 'components/common/entity/EntityBody';
import EntityContent from 'components/common/entity/EntityContent';
import EntityExpandableText from 'components/common/entity/EntityExpandableText';
import EntityHeader from 'components/common/entity/EntityHeader';
import EntitySubHeader from 'components/common/entity/EntitySubHeader';
import EntityTitle from 'components/common/entity/EntityTitle';
import ExerciseAnswer from 'components/exercises/exercise/exercise-answer/ExerciseAnswer';
import ExerciseJustification from 'components/exercises/exercise/exercise-answer/ExerciseJustification';
import ExerciseGrade from 'components/exercises/exercise/exercise-header/ExerciseGrade';
import ExerciseGrader from 'components/exercises/exercise/exercise-header/ExerciseGrader';
import ExerciseSavingSpinner from 'components/exercises/exercise/exercise-header/ExerciseSavingSpinner';
import { IDLE, SAVING, SUCCESS } from 'components/exercises/exercise/exercise-header/ExerciseSavingSpinner/states';
import ExerciseCriteriaGrader from 'components/exercises/exercise/exercise-sub-header/ExerciseCriteriaGrader';
import ExerciseFeedback from 'components/exercises/exercise/ExerciseFeedback';
import ExerciseModelAnswer from 'components/exercises/exercise/ExerciseModelAnswer';
import ExerciseStatement from 'components/exercises/exercise/ExerciseStatement';
import ProcessNodeOptions from 'components/exercises/exercise-form/ProcessNodeOptions';

const GradeableExercise = ({ num, classificationType, disabled, sectionId, exerciseId, dataTour }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const {
    statement,
    type,
    valueText,
    quillAnswer,
    choices,
    valueChoices,
    valueTrueFalse,
    valueImages,
    gaps,
    valueGaps,
    valueOrderItems,
    valueMultipleChoices,
    orderingCorrect,
    correctTrueFalse,
    hasJustification,
    image,
    criteriaGrades,
    grade,
    maxGrade,
    feedback,
    isShortAnswer,
    option,
    isCompact,
    extraText,
    extraTextStartExpanded,
    connectors,
    connections,
    valueConnections,
    modelAnswer,
    gradingOption,
    exerciseCells,
  } = useSelector(selectAttemptExercise(exerciseId, sectionId));
  const [editAnswerGradeRequest] = useApi(api.editAnswerGrade);
  const [editAnswerCriteriaGradeRequest] = useApi(api.editAnswerCriteriaGrade);
  const [editAnswerFeedbackRequest] = useApi(api.editAnswerFeedback);
  const modelAnswerToggle = useFeature(toggles.modelAnswer);
  const isAdmin = useSelector(selectUserIsAdmin);

  const timeoutRef = useRef();
  const [savingState, setSavingState] = useState(IDLE);
  const [dropAnswers, setDropAnswers] = useState(valueGaps || []);

  const hasMultipleCorrectChoices = choices?.filter(choice => choice.isCorrect).length > 1;

  useEffect(() => {
    setDropAnswers(valueGaps);
  }, [valueGaps]);

  const saveGrade = (grade) => {
    setSavingState(SAVING);

    editAnswerGradeRequest([exerciseId], { grade }, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0) {
          setSavingState(SUCCESS);
          setTimeout(() => {
            setSavingState(IDLE);
          }, 300);
          dispatch(updateGrade(exerciseId, grade, sectionId));
          return;
        } else {
          toast.error(lang.oops);
        }

        setSavingState(IDLE);
      }, 300);
    });
  };

  const saveCriteriaGrade = (answerCriteriaGradeId, grade) => {
    setSavingState(SAVING);

    editAnswerCriteriaGradeRequest([answerCriteriaGradeId], { grade }, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0) {
          setSavingState(SUCCESS);
          setTimeout(() => {
            setSavingState(IDLE);
          }, 300);
          dispatch(updateCriteriaGrade(exerciseId, answerCriteriaGradeId, grade, sectionId));
          return;
        } else {
          toast.error(lang.oops);
        }

        setSavingState(IDLE);
      }, 300);
    });
  };

  const saveFeedback = (feedback) => {
    setSavingState(SAVING);
    dispatch(updateFeedback(exerciseId, feedback));

    editAnswerFeedbackRequest([exerciseId], { feedback }, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0) {
          setSavingState(SUCCESS);
          setTimeout(() => {
            setSavingState(IDLE);
          }, 300);
          return;
        } else {
          toast.error(lang.oops);
        }

        setSavingState(IDLE);
      }, 300);
    });
  };

  const onChange = (feedback) => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      saveFeedback(feedback);
    }, 2000);
  };

  const onBlur = (feedback) => {
    clearTimeout(timeoutRef.current);

    saveFeedback(feedback);
  };

  const processNode = (node) => {
    let item;
    const drop = dropAnswers.find(el => el.position === node.attribs['data-position']);
    if (!drop) {
      return null;
    }
    const gapAnswer = dropAnswers.find(answer => answer.position === drop.position);

    if (gapAnswer) {
      if (type === 'filling') {
        item = gaps.find(gap => gap.id === gapAnswer.gapId);
      }
    } else {
      item = null;
    }

    return (
      <ProcessNodeOptions
        id={exerciseId}
        drop={drop}
        item={option === 'write' ? gapAnswer : item}
        gaps={gaps}
        option={option}
        correction={!isAdmin}
      />
    );
  };

  const instructions = [
    {
      shouldProcessNode: (node) => {
        return node.attribs && node.attribs['data-drop'];
      },
      processNode: processNode,
    },
  ];

  const getAnswer = () => {

    if (valueChoices) {
      return valueChoices;
    } else if ((valueText || quillAnswer) && type !== 'choices' && type !== 'true-false') {
      return isShortAnswer ? valueText : quillAnswer;
    } else if (valueTrueFalse !== null) {
      return valueTrueFalse;
    } else if (valueImages) {
      return valueImages.map(type => (type.value));
    } else if (valueGaps) {
      return valueGaps;
    } else if (valueMultipleChoices) {
      return valueMultipleChoices;
    } else if (valueConnections) {
      return valueConnections;
    }
  };

  return (
    <EntityBody
      dataTour={`${dataTour}`}
    >
      <EntityHeader>
        <EntityTitle
          num={num}
          type={type}
        />
        {!isAdmin && (
          <EntityActionsContainer>
            <ExerciseSavingSpinner
              state={savingState}
            />
            <Render when={![INFORMATION, PAUSE].includes(type) && classificationType !== 'none'}>
              <Render when={!criteriaGrades}>
                <ExerciseGrader
                  dataTour={`${dataTour}-grader`}
                  grade={grade}
                  maxGrade={maxGrade}
                  saveGrade={saveGrade}
                  disabled={disabled}
                />
              </Render>
              <Render when={classificationType === 'rubric'}>
                <ExerciseGrade
                  grade={grade}
                  maxGrade={maxGrade}
                />
              </Render>
            </Render>
          </EntityActionsContainer>
        )}
      </EntityHeader>
      {classificationType === 'rubric' && criteriaGrades && !isAdmin && (
        <EntitySubHeader>
          <ExerciseCriteriaGrader
            criteriaGrades={criteriaGrades}
            saveCriteriaGrade={saveCriteriaGrade}
            updateGrade={(newGrade, answerCriteriaGradeId) => dispatch(updateCriteriaGrade(exerciseId, answerCriteriaGradeId, newGrade, sectionId))}
            maxGrade={maxGrade && parseFloat(maxGrade)}
            disabled={disabled}
          />
        </EntitySubHeader>
      )}
      <EntityContent>
        <ExerciseStatement
          statement={statement}
          instructions={instructions}
        />
        <Render when={![INFORMATION, PAUSE].includes(type)}>
          <ExerciseAnswer
            exerciseId={exerciseId}
            type={type}
            choices={choices}
            answer={getAnswer()}
            correctTrueFalse={correctTrueFalse}
            image={image}
            gaps={gaps}
            dropAnswers={dropAnswers}
            orderItems={valueOrderItems}
            orderingCorrect={orderingCorrect}
            hasMultipleCorrectChoices={hasMultipleCorrectChoices}
            isCompact={isCompact}
            isShortAnswer={isShortAnswer}
            option={option}
            connectors={connectors}
            connections={connections}
            exerciseCells={exerciseCells}
            gradingOption={gradingOption}
            correction
            hasWordCount
          />
          <Render when={hasJustification}>
            <ExerciseJustification
              exerciseId={exerciseId}
              justification={hasJustification && (valueText || quillAnswer)}
              hasWordCount
            />
          </Render>
          <Render when={!quillIsEmpty(extraText)}>
            <Spacer px={10} />
            <EntityExpandableText
              text={extraText}
              full={extraTextStartExpanded}
            />
          </Render>
          {!isAdmin && (
            <>
              <Render when={modelAnswerToggle && !quillIsEmpty(modelAnswer)}>
                <Spacer px={10} />
                <ExerciseModelAnswer
                  modelAnswer={modelAnswer}
                />
              </Render>
              <ExerciseFeedback
                dataTour={`${dataTour}-feedback`}
                feedback={feedback}
                onChange={onChange}
                onBlur={onBlur}
                defining
              />
            </>
          )}
        </Render>
      </EntityContent>
    </EntityBody>
  );
};

GradeableExercise.propTypes = {
  num: PropTypes.number.isRequired,
  exercise: PropTypes.shape({
    id: PropTypes.string.isRequired,
    statement: PropTypes.any.isRequired,
    type: PropTypes.string.isRequired,
    choices: PropTypes.arrayOf(PropTypes.object),
    gaps: PropTypes.arrayOf(PropTypes.object),
    valueOrderItems: PropTypes.arrayOf(PropTypes.object),
    exerciseCells: PropTypes.arrayOf(PropTypes.object),
    orderingCorrect: PropTypes.bool,
    correctTrueFalse: PropTypes.bool,
    answer: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.array,
    ]),
    grade: PropTypes.string,
    maxGrade: PropTypes.string,
    feedback: PropTypes.object,
    hasJustification: PropTypes.bool,
    justification: PropTypes.string,
    image: PropTypes.string,
    criteriaGrades: PropTypes.array,
    gradingOption: PropTypes.string,
  }),
  classificationType: PropTypes.string,
  disabled: PropTypes.bool,
  sectionId: PropTypes.string,
  exerciseId: PropTypes.string,
  dataTour: PropTypes.string,
};

export default GradeableExercise;
