import React, { useState } from 'react';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToast } from '@intuitivo-pt/outline-ui';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';

import { EN, PT, selectUserLang } from 'actions/userActions';
import api from 'api';
import { SESSION_LANG } from 'constants/cookies';
import useApi from 'hooks/common/useApi';
import lang from 'lang';

import Tooltip from '../Tooltip';
import Button from 'components/common/Button';

import useStyles from './styles';

const LangSelector = () => {
  const classes = useStyles();
  const locale = useSelector(selectUserLang);
  const toast = useToast();
  const loggedIn = useSelector(state => state.user.loggedIn);
  const [updateLangRequest] = useApi(api.updateLang);

  const [loading, setLoading] = useState(false);

  const toggleLang = () => {
    Cookies.set(SESSION_LANG, locale === PT ? EN : PT, { domain: process.env.REACT_APP_INTUITIVO_DOMAIN, expires: new Date('9999-12-31') });
    setLoading(true);
    updateLangRequest([], { newLang: locale === PT ? EN : PT }, ({ data }) => {
      setLoading(false);

      if (data.status !== 0 && loggedIn) {
        toast.error(lang.oops);
        return;
      }

      window.location.reload();
    });
  };

  return (
    <Tooltip tip={lang.changeLang}>
      <Button
        className={classes.langToggler}
        onClick={toggleLang}
        loading={loading}
      >
        <FontAwesomeIcon icon={faLanguage} />
        {locale.toUpperCase()}
      </Button>
    </Tooltip>
  );
};

export default LangSelector;
