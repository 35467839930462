const toggles = {
  fillTheGaps: 'fill_the_gaps',
  explore: 'explore',
  ordering: 'ordering',
  caption: 'caption',
  sections: 'sections',
  explorePage: 'explore-page',
  azureOauth: 'azure-oauth',
  quillAnswers: 'quill-answers',
  multipleCorrectChoices: 'multiple-correct-choices',
  horizontalChoices: 'horizontal-choices',
  shortTextAnswer: 'short-text-answer',
  ftgExtra: 'ftg-extra',
  captionExtra: 'caption-extra',
  ftgDropdown: 'ftg-dropdown',
  captionDropdown: 'caption-dropdown',
  iave: 'iave',
  ftgWrite: 'ftg-write',
  guestAttempts: 'guest-attempts',
  navigation: 'navigation',
  sectionTimer: 'section-timer',
  pauseItem: 'pause-item',
  textAfterItem: 'text-after-item',
  orderDropdown: 'order-dropdown',
  exportIdentifiers: 'export-identifiers',
  orderChoicesToggle: 'order-choices',
  exportAttemptsCsv: 'export-attempts-csv',
  captionWrite: 'caption-write',
  connecting: 'connecting',
  segmentation: 'segmentation',
  tableExercise: 'table-exercise',
  videoUpload: 'video-upload',
  soundControl: 'sound-control',
  videoControl: 'video-control',
  maintenance: 'maintenance',
  teacherOnboarding: 'teacher-onboarding',
  createTest: 'create-test',
  createExercise: 'create-exercise',
  createRubric: 'create-rubric',
  createGroup: 'create-group',
  feedback: 'feedback',
  exportVersions: 'export-versions',
  statistics: 'statistics',
  plans: 'plans',
  advancedStatistics: 'advanced-statistics',
  asyncAnswers: 'async-answers',
  modelAnswer: 'model-answer',
  classes: 'classes',
  createAttempts: 'create-attempts',
  exploreCurriculum: 'explore-curriculum',
  wordCount: 'word-count',
  editPublication: 'edit-publication',
};

export default toggles;
