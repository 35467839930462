import { ADMIN, STUDENT, SUPERADMIN, TEACHER } from 'constants/roles';
import lang from 'lang';

const PT = 'pt';
const EN = 'en';

/**
 * Action constants
 */
const BOOT_USER = 'BOOT_USER';
const LANG_SET = 'LANG_SET';
const SET_PREFERENCES = 'SET_PREFERENCES';
const SET_FEATURE_TOGGLES = 'SET_FEATURE_TOGGLES';
const SET_SPACE_ID_PREFERENCE = 'SET_SPACE_ID_PREFERENCE';
const INCREMENT_TOTAL_TESTS = 'INCREMENT_TOTAL_TESTS';
const DECREMENT_TOTAL_TESTS = 'DECREMENT_TOTAL_TESTS';
const INCREMENT_TOTAL_EXERCISES = 'INCREMENT_TOTAL_EXERCISES';
const DECREMENT_TOTAL_EXERCISES = 'DECREMENT_TOTAL_EXERCISES';
const INCREMENT_TOTAL_RUBRICS = 'INCREMENT_TOTAL_RUBRICS';
const DECREMENT_TOTAL_RUBRICS = 'DECREMENT_TOTAL_RUBRICS';
const INCREMENT_TOTAL_GROUPS = 'INCREMENT_TOTAL_GROUPS';
const DECREMENT_TOTAL_GROUPS = 'DECREMENT_TOTAL_GROUPS';

/**
 * Action creators
 */
const bootUser = (data) => ({
  type: BOOT_USER,
  data: data,
});

const langSet = (newLang) => ({
  type: LANG_SET,
  lang: newLang,
});

const setPreferences = (preferences) => {
  return {
    type: SET_PREFERENCES,
    subjects: preferences.subjects,
    years: preferences.years,
  };
};

const setFeatureToggles = (featureToggles) => {
  return {
    type: SET_FEATURE_TOGGLES,
    featureToggles: featureToggles,
  };
};

const setSpaceIdPreference = (schoolId) => {
  return {
    type: SET_SPACE_ID_PREFERENCE,
    spaceIdPreference: schoolId,
  };
};

const incrementTotalTests = (amount = 1) => {
  return {
    type: INCREMENT_TOTAL_TESTS,
    amount: amount,
  };
};

const decrementTotalTests = (amount = 1) => {
  return {
    type: DECREMENT_TOTAL_TESTS,
    amount: amount,
  };
};

const incrementTotalExercises = (amount = 1) => {
  return {
    type: INCREMENT_TOTAL_EXERCISES,
    amount: amount,
  };
};

const decrementTotalExercises = (amount = 1) => {
  return {
    type: DECREMENT_TOTAL_EXERCISES,
    amount: amount,
  };
};

const incrementTotalRubrics = (amount = 1) => {
  return {
    type: INCREMENT_TOTAL_RUBRICS,
    amount: amount,
  };
};

const decrementTotalRubrics = (amount = 1) => {
  return {
    type: DECREMENT_TOTAL_RUBRICS,
    amount: amount,
  };
};

const incrementTotalGroups = (amount = 1) => {
  return {
    type: INCREMENT_TOTAL_GROUPS,
    amount: amount,
  };
};

const decrementTotalGroups = (amount = 1) => {
  return {
    type: DECREMENT_TOTAL_GROUPS,
    amount: amount,
  };
};

/**
 * Selectors
 */
const selectUserSubjects = (state) => {
  return state.user.data.subjects;
};

const selectUserYears = (state) => {
  return state.user.data.years;
};

const selectUserIsFirstTime = (state) => {
  return state.user.data.isFirstTime;
};

const selectUserFeatureToggles = (state) => {
  return state.user.data.featureToggles;
};

const selectSchoolName = (schoolId) => {
  return (state) => {
    const school = state.user.data.schools.find(school => school.id === schoolId);

    return school ? school.name : lang.appKeywords.personalSpace;
  };
};

const selectUserLang = (state) => {
  return state.user.lang;
};

const selectUserIsTeacher = (state) => {
  return state.user.data.role === TEACHER;
};

const selectUserLoggedIn = (state) => {
  return state.user.loggedIn;
};

const selectUserIsStudent = (state) => {
  return state.user.data.role === STUDENT;
};

const selectHasPersonalSpace = (state) => {
  return state.user.data.hasPersonalSpace;
};

const selectUserSchools = (state) => {
  return state.user.data.schools;
};

const selectUserSpaceIdPreference = (state) => {
  return state.user.data.spaceIdPreference;
};

const selectUserFullName = (state) => {
  return state.user.data.fullName;
};

const selectUserId = (state) => {
  return state.user.data.id;
};

const selectUserTotalFreeTests = (state) => {
  return state.user.data.freeUsages.totalTests;
};

const selectUserTotalFreeExercises = (state) => {
  return state.user.data.freeUsages.totalExercises;
};

const selectUserFeatureToggleMaxUsages = (featureName) => (state) => {
  const toggle = state.user.data.featureToggles.find(featureToggle => featureToggle.name === featureName);

  if (!toggle) {
    return null;
  }

  return toggle.maxUsages;
};

const selectUserTotalFreeRubrics = (state) => {
  return state.user.data.freeUsages.totalRubrics;
};

const selectUserTotalFreeGroups = (state) => {
  return state.user.data.freeUsages.totalGroups;
};

const selectUserIsSuperadmin = (state) => {
  return state.user.data.role === SUPERADMIN;
};

const selectUserIsAdmin = (state) => {
  return state.user.data.role === ADMIN;
};

const selectUserEmail = (state) => {
  return state.user.data.email;
};

const selectUserCurriculum = (state) => {
  return state.user.data.curriculum;
};

export {
  PT,
  EN,
  BOOT_USER,
  LANG_SET,
  SET_PREFERENCES,
  SET_FEATURE_TOGGLES,
  SET_SPACE_ID_PREFERENCE,
  INCREMENT_TOTAL_TESTS,
  DECREMENT_TOTAL_TESTS,
  INCREMENT_TOTAL_EXERCISES,
  DECREMENT_TOTAL_EXERCISES,
  INCREMENT_TOTAL_RUBRICS,
  DECREMENT_TOTAL_RUBRICS,
  INCREMENT_TOTAL_GROUPS,
  DECREMENT_TOTAL_GROUPS,
  bootUser,
  langSet,
  setPreferences,
  setFeatureToggles,
  setSpaceIdPreference,
  incrementTotalTests,
  decrementTotalTests,
  incrementTotalExercises,
  decrementTotalExercises,
  incrementTotalRubrics,
  decrementTotalRubrics,
  incrementTotalGroups,
  decrementTotalGroups,
  selectUserSubjects,
  selectUserYears,
  selectUserIsFirstTime,
  selectUserFeatureToggles,
  selectSchoolName,
  selectUserLang,
  selectUserIsTeacher,
  selectUserLoggedIn,
  selectUserIsStudent,
  selectHasPersonalSpace,
  selectUserSchools,
  selectUserSpaceIdPreference,
  selectUserFullName,
  selectUserId,
  selectUserTotalFreeTests,
  selectUserTotalFreeExercises,
  selectUserFeatureToggleMaxUsages,
  selectUserTotalFreeRubrics,
  selectUserTotalFreeGroups,
  selectUserIsSuperadmin,
  selectUserEmail,
  selectUserCurriculum,
  selectUserIsAdmin,
};
