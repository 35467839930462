import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  sidebar: {
    height: '100vh',
    width: '237px',
    zIndex: '103',
    backgroundColor: theme.sidebarColor,
    '& .otl-navbarContainer': {
      height: '100vh',
      padding: '0',
    },
    '& .otl-navbarMaxwidth': {
      maxWidth: 'unset',
      flexDirection: 'column',
      overflowY: 'auto',
    },
    '&.open': {
      right: '0',
    },
    transition: 'right 300ms ease-in-out',
  },
  pageLink: {
    display: 'flex',
    alignItems: 'center',
    height: '43px',
    width: '187px',
    borderRadius: '30px',
    marginBottom: '18px',
    cursor: 'pointer',
    fontSize: '14px',
    color: theme.sidebarText,
    '&:hover': {
      backgroundColor: theme.shade0,
    },
    '&.selected': {
      backgroundColor: '#25C89430',
      color: '#25C894',
      '& svg': {
        color: '#25C894',
      },
    },
  },
  pageIcon: {
    fontSize: '20px',
    color: theme.sidebarText,
  },
  pageLabel: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '10px',
  },
  iconWrapper: {
    width: '25px',
    marginLeft: '23px',
    marginRight: '13px',
  },
  logoWrapper: {
    marginTop: '49px',
    marginBottom: '66px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },
  premiumPillWrapper: {
    cursor: 'pointer',
  },
  separator: {
    height: '11px',
    borderBottom: '1px solid #BEBEBE',
    width: '185.5px',
    marginBottom: '26px',
  },
  profileWrapper: {
    marginTop: 'auto',
    marginBottom: '64px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  userName: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: '18px',
  },
  userEmail: {
    textAlign: 'center',
    fontSize: '12px',
    marginBottom: '26px',
    width: '80%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  buttons: {
    display: 'flex',
    gap: '30px',
    marginTop: '26px',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: theme.sidebarColor,
    boxShadow: '0px 4px 4px #0000004D',
    borderRadius: '6px',
    height: '30px',
    color: theme.sidebarText,
    '&:hover': {
      backgroundColor: theme.shade0,
    },
  },
  langToggler: {
    gap: '0.25em',
  },
  iaveLogo: {
    width: '60%',
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    sidebar: {
      right: '-237px',
    },
  },
}));
