import React, { useState } from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';
import { parseQlFormula } from 'utils';

import FormulaModal from '../FormulaModal';
import expressions from '../FormulaModal/expressions';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const EditableFormula = ({ node }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <span>
      <Tooltip
        tip={lang.edit}
        place={'right'}
      >
        <span
          dangerouslySetInnerHTML={{ __html: parseQlFormula(`<span class="ql-formula">${node.getAttribute('value')}</span>`) }}
          onClick={() => setOpen(true)}
          className={classes.formulaWrapper}
        />
      </Tooltip>
      <FormulaModal
        open={open}
        close={() => setOpen(false)}
        value={node.getAttribute('value')}
        insert={(value) => {
          node.setAttribute('value', value);
        }}
        enableMathSymbols={node.getAttribute('data-mathSymbols')}
        mathSymbols={node.getAttribute('data-mathSymbols') !== 'undefined' ? JSON.parse(node.getAttribute('data-mathSymbols')) : expressions}
      />
    </span>
  );
};

EditableFormula.propTypes = {
  node: PropTypes.node,
};

export default EditableFormula;
