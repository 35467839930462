import React, { useState, Fragment, useCallback, useEffect, useMemo } from 'react';
import { faRocket, faCopy, faUsers, faPaperPlane, faCog, faPencilRuler, faUser, faLockOpen, faLock, faUserGroup, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { publishTest, selectTest, selectTestPublication } from 'actions/testActions';
import { incrementTotalTests, selectUserFeatureToggleMaxUsages, selectUserIsAdmin, selectUserTotalFreeTests } from 'actions/userActions';
import api from 'api';
import { INFORMATION, PAUSE } from 'constants/exerciseTypes';
import { PERSONAL_SPACE } from 'constants/spaces';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';
import { cleanAndSortGroups } from 'utils';
import { getTestClassificationType, getTestClassificationTypeIcon, getTestType, getTestTypeIcon } from 'utils/tests';

import SendTestModal from '../SendTestModal';
import SettingsModal from '../SettingsModal';
import SecondaryPageHeader from 'components/common/layout/SecondaryPageHeader';
import UpgradePremiumModal from 'components/common/plans/UpgradePremiumModal';
import PublishModal from 'components/test/publish-modal/PublishModal';
import ExportAttemptsModal from 'components/test/tabs/grades-tab/ExportAttemptsModal';
import EntityInformation from 'components/tests/Entitynformation';

const Header = ({ fetchTest, reload }) => {
  const toast = useToast();
  const history = useHistory();
  const { spaceId } = useParams();
  const test = useSelector(selectTest);
  const isAdmin = useSelector(selectUserIsAdmin);
  const exploreToggle = useFeature(toggles.explore);
  const dispatch = useDispatch();
  const [getGroupsRequest] = useApi(api.getGroups);
  const [duplicateTestRequest] = useApi(api.duplicateTest);
  const totalFreeTests = useSelector(selectUserTotalFreeTests);
  const createTestToggle = useFeature(toggles.createTest, totalFreeTests);
  const createTestToggleMaxUsages = useSelector(selectUserFeatureToggleMaxUsages(toggles.createTest));

  const [loading, setLoading] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showEditNameModal, setShowEditNameModal] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [groups, setGroups] = useState([]);
  const [upgradePremiumModal, setUpgradePremiumModal] = useState(false);
  const [exportValidAttemptsModal, setExportValidAttemptsModal] = useState(false);
  const publication = useSelector(selectTestPublication);

  const fetchGroups = useCallback(() => {
    const schoolId = spaceId === 'p' ? null : spaceId;
    getGroupsRequest([schoolId], null, ({ data }) => {
      if (data.status === 0) {
        const newGroups = cleanAndSortGroups(data.groups);
        setGroups(newGroups);
      }
    });
  }, [getGroupsRequest, spaceId]);

  useEffect(() => {
    if (isAdmin) {
      return;
    }
    fetchGroups();
  }, [fetchGroups, isAdmin]);

  const duplicateTest = () => {
    if (!createTestToggle) {
      setUpgradePremiumModal(true);
      return;
    }

    setLoading(true);
    const duplicatedTestName = lang.copyOf + ' ' + test.name;

    duplicateTestRequest([test.id], { name: duplicatedTestName }, ({ data }) => {
      setLoading(false);
      if (data.status === 0) {
        if (spaceId === PERSONAL_SPACE) {
          dispatch(incrementTotalTests());
        }

        toast.success(lang.test.successDuplicateTest);
        history.push({
          pathname: routes.test.ref(spaceId, data.testId),
          search: 'tab=exercises',
        });
        reload();
        return;
      }

      toast.error(lang.oops);
    });
  };

  const getTestInformation = () => {
    const totalPoints = test.items.reduce((total, item) => {
      total += parseFloat(item.points ? item.points : item?.sectionTotal ? item.sectionTotal : 0);
      return total;
    }, 0);

    const totalItems = test.items
      .filter(exercise => ![INFORMATION, PAUSE].includes(exercise.type))
      .reduce((total, item) => {
        if (item.itemType === 'section') {
          const sectionItems = item.exercises
            .filter(exercise => ![INFORMATION, PAUSE].includes(exercise.type));

          return total + sectionItems.length;
        }

        return total + 1;
      }, 0);

    const testInformations = [
      {
        icon: getTestTypeIcon(test.type),
        label: getTestType(test.type),
      },
      {
        icon: getTestClassificationTypeIcon(test.classificationType),
        label: getTestClassificationType(test.classificationType),
      },
      {
        icon: faPencilRuler,
        label: `${totalItems} ${totalItems === 1 ? lang.tests.teacher.exercise : lang.tests.teacher.exercises}`,
      },
      {
        icon: faUser,
        label: test.authorName,
      },
      {
        icon: faCheck,
        label: `${totalPoints.toFixed(2)} ${totalPoints === 1 ? lang.test.grades.point : lang.test.grades.points}`,
      },
      {
        icon: test.isPublic ? faLockOpen : faLock,
        label: test.isPublic ? lang.contentSettings.public : lang.contentSettings.private,
        hide: !exploreToggle,
      },
      ...(test.isPublic ?
        [
          {
            icon: faUserGroup,
            label: test.usages + ' ' + lang.copies,
            hide: !exploreToggle,
          },
        ]
        : []),
    ];

    return (
      <EntityInformation
        informations={testInformations}
      />
    );
  };

  const totalWeights = useMemo(() => {
    if (!test.rubric) {
      return;
    }

    let totalWeights = `${lang.test.rubric.totalPonderations}:`;

    const testExercises = test.items
      .flatMap(item => item.itemType === 'section' ? (item.exercises || []) : item)
      .filter(testExercise => ![INFORMATION, PAUSE].includes(testExercise.type));

    test.rubric.criteria.forEach((criteria, index) => {
      let totalWeight = 0;
      let totalPoints = 0;

      testExercises.forEach((exercise) => {
        const criteriaWeight = exercise.criteriaWeights.find(criteriaWeight => criteriaWeight.rubricCriteriaId === criteria.id);
        totalWeight += (parseInt(criteriaWeight.weight) / 100) * parseInt(exercise.points);
        totalPoints += parseInt(exercise.points);
      });

      totalWeight /= totalPoints;
      totalWeight *= 100;

      totalWeights += ` ${criteria.name} (${totalWeight.toFixed(2) || 0}%)${index === test.rubric.criteria.length - 1 ? '' : ','}`;
    });

    return totalWeights;
  }, [test.rubric, test.items]);

  const extraInformations = [
    {
      icon: faUsers,
      label: test.groupName.length === 0 ? lang.appKeywords.personalGroup : test.groupName,
    },
    {
      label: getTestInformation(),
    },
    {
      label: totalWeights || '',
      hide: test.classificationType !== 'rubric' || !test.rubric || test.items.length === 0,
    },
  ];

  const actions = [
    {
      type: 'icon',
      icon: faRocket,
      label: test.isPublished ? lang.published : lang.publish,
      onClick: () => setShowPublishModal(true),
      active: test.isPublished,
      dataTour: 'assessment-publish',
      hide: isAdmin,
    },
    {
      type: 'icon',
      icon: faCopy,
      label: lang.duplicate,
      onClick: duplicateTest,
      loading: loading,
      hide: isAdmin,
    },
    {
      type: 'icon',
      icon: faPaperPlane,
      label: lang.send,
      onClick: () => setShowSendModal(true),
      hide: isAdmin,
    },
    {
      type: 'icon',
      icon: faCog,
      label: lang.settings,
      onClick: () => setShowEditNameModal(true),
      hide: isAdmin,
    },
    {
      type: 'button',
      label: lang.test.attemptsExport.downloadAttempts,
      onClick: () => setExportValidAttemptsModal(true),
      hide: !isAdmin,
    },
  ];

  const afterSubmitPublication = (publication) => {
    dispatch(publishTest(publication));
    fetchTest();
  };

  const extraActions = [];

  return (
    <Fragment>
      <ExportAttemptsModal
        open={exportValidAttemptsModal}
        close={() => setExportValidAttemptsModal(false)}
        publicationId={publication?.id}
      />
      <PublishModal
        open={showPublishModal}
        close={() => setShowPublishModal(false)}
        test={test}
        refresh={fetchTest}
        afterSubmit={afterSubmitPublication}
      />
      <SettingsModal
        open={showEditNameModal}
        close={() => setShowEditNameModal(false)}
        test={test}
        refresh={fetchTest}
      />
      <SendTestModal
        open={showSendModal}
        close={() => setShowSendModal(false)}
        test={test}
        groupId={test.groupId}
        groups={groups}
        refresh={fetchTest}
      />
      <UpgradePremiumModal
        open={upgradePremiumModal}
        close={() => setUpgradePremiumModal(false)}
        message={lang.plans.exceededTests(createTestToggleMaxUsages)}
      />
      <SecondaryPageHeader
        header={test.name}
        extraInformations={!isAdmin && extraInformations}
        actions={actions}
        extraActions={!isAdmin && extraActions}
      />
    </Fragment>
  );
};

Header.propTypes = {
  fetchTest: PropTypes.func,
  reload: PropTypes.func,
};

export default Header;
