import React, { useCallback, useEffect, useState, Fragment, useMemo } from 'react';
import { faFileExcel, faFilePdf, faRocket, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Pagination, Row } from '@intuitivo/outline';
import { useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import { saveAs } from 'file-saver';
import { ReactComponent as InvalidIcon } from 'icons/invalid-icon.svg';
import { ReactComponent as PendingIcon } from 'icons/pending-icon.svg';
import { ReactComponent as ValidIcon } from 'icons/valid-icon.svg';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectAttempt } from 'actions/attemptActions';
import { selectTest, selectTestClassificationType, selectTestPublication } from 'actions/testActions';
import { selectUserIsAdmin } from 'actions/userActions';
import api from 'api';
import { VALID, INVALID, PENDING } from 'constants/attemptValidation';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import getFileData from 'services/grades-service/getFileData';
import toggles from 'toggles';

import { calculatePercentage } from '../../../../../utils/statistics';
import DonutChart from '../../statistics-tab/DonutChart';
import ScoreCard from '../../statistics-tab/ScoreCard';
import ExportAttemptsCSVModal from '../ExportAttemptsCSVModal';
import ExportAttemptsModal from '../ExportAttemptsModal';
import PublishGradesModal from '../PublishGradesModal';
import GradeableAttempt from 'components/attempt/GradeableAttempt';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import Input from 'components/common/Input';
import Loading from 'components/common/Loading';
import Tooltip from 'components/common/Tooltip';
import DeleteAttemptModal from 'components/test/DeleteAttemptModal';

import useStyles from './styles';
const TAB_STATISTICS = 'statistics';
const DEBOUNCE_TIME = 500;
const POLLING_TIME = 10_000;

const CorrectionTab = ({ changeTab }) => {
  const classes = useStyles();
  const toast = useToast();
  const { testId } = useParams();

  const statsToggle = useFeature(toggles.statistics);
  const exportAttemptsCsvToggle = useFeature(toggles.exportAttemptsCsv);

  const publication = useSelector(selectTestPublication);
  const { name: testName } = useSelector(selectTest);
  const classificationType = useSelector(selectTestClassificationType);
  const isAdmin = useSelector(selectUserIsAdmin);
  const attempt = useSelector(selectAttempt);

  const [getTestStudentsRequest, requestLoading, abortRequest] = useApi(api.getTestStudents);
  const [getTestGradesRequest] = useApi(api.getTestGrades);
  const [getPublicationStatisticsRequest] = useApi(api.getPublicationStatistics);

  const [loading, setLoading] = useState(true);
  const [publishGradesModal, setPublishGradesModal] = useState(false);
  const [exportResolutionsModal, setExportResolutionsModal] = useState(false);
  const [showDeleteAttemptModal, setShowDeleteAttemptModal] = useState(false);
  const [selectedAttemptToDelete, setSelectedAttemptToDelete] = useState({ attemptId: '', fullName: '' });
  const [students, setStudents] = useState([]);
  const [hasStudents, setHasStudents] = useState(false);
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState(null);
  // query
  const [attemptSearchString, setAttemptSearchString] = useState(null);
  const [attemptsTotalPages, setAttemptsTotalPages] = useState(1);
  const [attemptsPage, setAttemptsPage] = useState(1);

  const [gradesPublished, setGradesPublished] = useState(publication ? publication.gradesPublished : false);
  const [exportAttemptsCSVModal, setExportAttemptsCSVModal] = useState(false);
  const [basicStats, setBasicStats] = useState([]);

  const fetchStudents = useCallback(({ search, page } = {}, firstLoad = false) => {
    return new Promise((resolve) => {
      getTestStudentsRequest([testId, search, page], null, ({ data }) => {
        setLoading(false);

        if (data.status === 0) {
          if (firstLoad) {
            setHasStudents(data.students.length !== 0);
          }
          setStudents(data.students);
          setTotal(parseFloat(data.total));
          setAttemptsTotalPages(data.pages);
          setSelected(selected =>
            selected === null && data.students.length !== 0
              ? data.students[0]
              : selected,
          );
          resolve(data);
        }
      });
    });
  }, [getTestStudentsRequest, testId]);

  const toggleDeleteAttemptModal = (event, student) => {
    event.stopPropagation();
    if (student.attemptId) {
      setShowDeleteAttemptModal(show => !show);
      setSelectedAttemptToDelete(student);
    }
  };

  const paginate = useCallback((page) => {
    fetchStudents({ search: attemptSearchString, page });
    setAttemptsPage(page);
  }, [fetchStudents, attemptSearchString]);

  const refreshAfterAttemptDelete = useCallback(() => {
    const remainingStudents = students.filter(s => s.attemptId !== selectedAttemptToDelete.attemptId);
    setStudents(remainingStudents);
    setSelected(selected => selected.attemptId === selectedAttemptToDelete.attemptId
      ? remainingStudents[0] ?? null
      : selected,
    );
  }, [selectedAttemptToDelete.attemptId, students]);

  const searchAttempts = useMemo(
    () => debounce(
      (search) => fetchStudents({ search }).then(() => setAttemptsPage(1)),
      DEBOUNCE_TIME,
    ),
    [fetchStudents],
  );

  const onAttemptSearchChange = useCallback((event) => {
    const { target: { value } } = event;
    searchAttempts(value);
    if (requestLoading) {
      abortRequest();
    }
    setAttemptSearchString(value);
  }, [requestLoading, searchAttempts, abortRequest]);

  useEffect(() => {
    fetchStudents({ page: 1 }, true);
  }, [fetchStudents]);

  useEffect(() => {
    if (!publication || !statsToggle || isAdmin) {
      return;
    }

    getPublicationStatisticsRequest([publication.id, true], null, ({ data }) => {
      if (data.status === 0) {
        setBasicStats(data.basicStats);
      }
    });
  }, [getPublicationStatisticsRequest, publication, statsToggle, isAdmin]);

  useEffect(() => {
    if (!publication) {
      return;
    }

    let intervalId;
    if (publication.isOngoing) {
      intervalId = setInterval(() => {
        fetchStudents({ search: attemptSearchString, page: attemptsPage });
      }, POLLING_TIME);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [publication, fetchStudents, attemptSearchString, attemptsPage]);

  useEffect(() => {
    if (!attempt || !attempt.id) {
      return;
    }

    let newGrade = 0;

    attempt.items.forEach(item => {
      if (item.itemType === 'section' && item.exercises) {
        item.exercises.forEach(exercise => {
          newGrade += parseFloat(exercise.grade);
        });
      } else if (item.itemType === 'exercise') {
        newGrade += parseFloat(item.grade);
      }
    });

    setStudents((students) => {
      const newStudents = [...students];
      const student = newStudents.find(student => student.attemptId === attempt.id);

      if (!student) {
        return newStudents;
      }

      student.grade = newGrade;

      return newStudents;
    });
  }, [attempt]);

  const getStudentsList = () => {
    if (students.length === 0) {
      return (
        <div className={classes.studentListItem}>
          { lang.test.grades.noStudentsFound }
        </div>)
      ;
    }
    return students.map((student, index) => {
      const gradePercentage = calculatePercentage(student.grade, total);

      return (
        <div
          data-tour={`assessment-tab-grades-student-attempt-${index}`}
          key={`${student.attemptId}-${student.fullName}`}
          className={cx(classes.studentBtn, classes.studentListItem, {
            selected: !!selected && student.attemptId === selected.attemptId && student.fullName === selected.fullName,
          })}
          onClick={() => setSelected(student)}
        >
          <div className={classes.studentGradeAndActions}>
            {!isAdmin && (
              <Tooltip tip={!student.attemptId && lang.test.grades.deleteAttemptUnavailable} place="left">
                <span
                  className={student.attemptId ? classes.deleteIconWrapper : classes.deleteIconWrapperDisabled}
                  onClick={(event) => toggleDeleteAttemptModal(event, student)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </span>
              </Tooltip>
            )}
            <div className={classes.studentName}>
              {student.fullName}
            </div>
          </div>
          {classificationType !== 'none' && !isAdmin &&
            <div className={cx(classes.grade, { failed: !gradePercentage || gradePercentage < 50 })}>
              {gradePercentage || gradePercentage === 0 ? gradePercentage.toFixed(2) : '??'}
              %
            </div>
          }
          {isAdmin && (
            <div className={classes.validationIcon}>
              {student.validation === VALID && <ValidIcon />}
              {student.validation === INVALID && <InvalidIcon />}
              {student.validation === PENDING && <PendingIcon />}
            </div>
          )}
        </div>
      );
    });
  };

  const exportGrades = () => {
    getTestGradesRequest([testId], null, ({ data }) => {
      if (data.status === 0) {
        const file = getFileData(data.attempts);

        saveAs(new File([file], `${testName}.xlsx`, { type: 'application/octet-stream' }));
      } else if (data.status === 5) {
        toast.warning(lang.test.grades.noAnswersYet);
      } else {
        toast.error(lang.oops);
      }
    });
  };

  if (loading) {
    return (
      <Loading active />
    );
  }

  if (!hasStudents) {
    return (
      <div className={classes.noStudents}>
        {lang.test.grades.noStudents}
      </div>
    );
  }

  return (
    <Fragment>
      {!isAdmin && (
        <>
          <DeleteAttemptModal
            open={showDeleteAttemptModal}
            close={() => setShowDeleteAttemptModal(false)}
            attempt={selectedAttemptToDelete}
            refresh={refreshAfterAttemptDelete}
          />
          <Row>
            <Col
              xl={12}
              className={classes.attemptActionsContainer}
            >
              <ExportAttemptsCSVModal
                open={exportAttemptsCSVModal}
                close={() => setExportAttemptsCSVModal(false)}
              />
              {exportAttemptsCsvToggle &&
              <Button
                className={classes.exportResolutionsButton}
                onClick={() => setExportAttemptsCSVModal(true)}
                sibling
              >
                <FontAwesomeIcon
                  icon={faFileExcel}
                  className={classes.publishGradesIcon}
                />
                {lang.test.grades.exportAttemptsCSV}
              </Button>
              }
              {classificationType !== 'none' &&
              <Button
                className={classes.exportResolutionsButton}
                onClick={exportGrades}
                sibling
              >
                <FontAwesomeIcon
                  icon={faFileExcel}
                  className={classes.publishGradesIcon}
                />
                {lang.test.grades.exportGrades}
              </Button>
              }
              <ExportAttemptsModal
                open={exportResolutionsModal}
                close={() => setExportResolutionsModal(false)}
                publicationId={publication.id}
                classificationType={classificationType}
              />
              <Button
                className={classes.exportResolutionsButton}
                onClick={() => setExportResolutionsModal(true)}
                sibling
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  className={classes.publishGradesIcon}
                />
                {lang.test.attemptsExport.exportAttemptsPDF}
              </Button>
              <Tooltip tip={!publication.hasFinished ? lang.test.grades.unavailable : null}>
                <PublishGradesModal
                  open={publishGradesModal}
                  close={() => setPublishGradesModal(false)}
                  publicationId={publication.id}
                  gradesPublished={gradesPublished}
                  setGradesPublished={setGradesPublished}
                />
                <Button
                  dataTour="assessment-tab-grades-publish-grades-button"
                  className={classes.publishGradesButton}
                  green={gradesPublished}
                  disabled={!publication.hasFinished || gradesPublished}
                  onClick={() => setPublishGradesModal(true)}
                  sibling
                >
                  <FontAwesomeIcon
                    icon={faRocket}
                    className={classes.publishGradesIcon}
                  />
                  {gradesPublished ? lang.test.grades.correctionsPublished : lang.test.grades.publishCorrections}
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </>
      )}
      {
        statsToggle && !isAdmin &&
          <Row className={classes.parentRow}>
            <Card
              dataTour="assessment-tab-grades-statistics"
              className={classes.testStatisticsCard}
              header={lang.appKeywords.statistics}
            >
              <div className={classes.testStatistics}>
                <ScoreCard stats={basicStats} total={total} />
                <Col
                  xl={3}
                  className={classes.donutChart}
                >
                  <DonutChart positiveGrades={basicStats.positiveGrades} negativeGrades={basicStats.negativeGrades} />
                </Col>
              </div>
              <Col
                xl={12}
                className={classes.statButtonRow}
              >
                <Button
                  className={classes.moreStatsButton}
                  onClick={() => changeTab(TAB_STATISTICS)}
                >
                  {lang.seeMore}
                </Button>
              </Col>

            </Card>
          </Row>
      }
      <Row>
        <Col
          sm={12}
          xl={3}
          className={cx(classes.studentsContainer, { admin: isAdmin })}
        >
          <Input
            type="text"
            value={attemptSearchString}
            onChange={onAttemptSearchChange}
            placeholder={lang.test.grades.studentSearch}
            className={cx(classes.studentSearchInput, { admin: isAdmin })}
          />
          <Pagination
            pages={attemptsTotalPages}
            bottom
            page={attemptsPage}
            setPage={paginate}
            className={classes.pagination}
          >
            <Card
              dataTour="assessment-tab-grades-student-attempts"
              className={classes.studentListCard}
              header={lang.appKeywords.students}
            >

              <div className={classes.studentList}>
                {getStudentsList()}
              </div>
            </Card>
          </Pagination>
          <Input
            type="select"
            value={selected && { value: selected.id, label: selected.fullName }}
            options={students.map((student) => ({ value: student.id, label: student.fullName, attemptId: student.attemptId }))}
            onChange={(value) => setSelected({ id: value.value, fullName: value.label, attemptId: value.attemptId })}
            className={classes.studentSelect}
          />
        </Col>
        <Col sm={12} xl={9}>
          <GradeableAttempt
            attemptId={selected && selected.attemptId}
            student={selected}
            setStudents={setStudents}
            total={total}
            testHasEnded={publication.hasFinished}
            classificationType={classificationType}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

CorrectionTab.propTypes = {
  changeTab: PropTypes.func,
};

export default CorrectionTab;
