import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { faMicrophone, faSquareRootAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Quill from 'quill';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

import { selectUserIsStudent } from 'actions/userActions';
import api from 'api';
import { MAX_SIZE_1GB } from 'constants/fileMaxSizes';
import { CUSTOM_VIDEO, YOUTUBE } from 'constants/videoTypes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import AudioModal from '../AudioModal';
import expressions from '../FormulaModal/expressions';
import ReactQuill from '../ReactQuill';
import VideoModal from '../VideoModal';
import FormulaModal from 'components/common/rich-text/FormulaModal';
import Tooltip from 'components/common/Tooltip';

import AudioBlot from './AudioBlot';
import CustomAudioBlot from './CustomAudioBlot';
import CustomVideoBlot from './CustomVideoBlot';
import FormulaBlot from './FormulaBlot';
import GapBlot from './GapBlot';
import ImageBlot from './ImageBlot';
import useStyles from './styles';

Quill.register(GapBlot);
Quill.register(CustomVideoBlot);
Quill.register(AudioBlot);
Quill.register(CustomAudioBlot);
Quill.register(ImageBlot);
Quill.register(FormulaBlot);

const RichText = ({ value, defaultValue, placeholder, inline, mathSymbols, enableMathSymbols, onChange, onBlur, rref, className, disabled, autoComplete, minified }) => {
  const classes = useStyles();
  const toast = useToast();
  const [uploadFileRequest] = useApi(api.uploadFile, true);
  const videoUploadToggle = useFeature(toggles.videoUpload);
  const soundControlToggle = useFeature(toggles.soundControl);
  const iaveToggle = useFeature(toggles.iave);
  const isStudent = useSelector(selectUserIsStudent);

  const [showFormulaModal, setShowFormulaModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [id] = useState(`id-${v4()}`);
  const ref = useRef(rref);

  const apiUploadFile = useCallback((file, options) => {
    if (file.size > MAX_SIZE_1GB) {
      toast.warning(lang.fileTooLarge1Gb);
      return;
    }

    const resize = !iaveToggle;
    uploadFileRequest([resize], { file }, ({ data }) => {
      if (data.status === 0) {
        const quill = ref.current.getEditor();
        const range = quill.getSelection(true);

        let type = data.mimetype.split('/')[0];
        let value = `${process.env.REACT_APP_S3_URL}/${data.key}`;

        if (type === 'video') {
          type = 'custom-video';
          value = {
            src: `${process.env.REACT_APP_S3_URL}/${data.key}`,
            ...options,
          };
        }

        if (soundControlToggle && type === 'audio') {
          type = 'custom-audio';
          value = {
            src: `${process.env.REACT_APP_S3_URL}/${data.key}`,
            ...options,
          };
        }

        quill.insertText(range.index, '\n', Quill.sources.USER);
        quill.insertEmbed(range.index + 1, type, value, Quill.sources.USER);
        quill.insertText(range.index + 2, '\n', Quill.sources.USER);
        quill.setSelection(range.index + 3);
        return;
      }

      toast.error(lang.oops);
    });
  }, [iaveToggle, uploadFileRequest, toast, soundControlToggle]);

  const imageButtonHandler = useCallback(() => {
    if (ref.current) {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();
      input.onchange = async () => {
        const file = input.files[0];

        apiUploadFile(file);
      };
    }
  }, [apiUploadFile]);

  const audioButtonHandler = useCallback(() => {
    if (soundControlToggle) {
      const quill = ref.current.getEditor();
      quill.getSelection(true);
      setShowAudioModal(true);
    }

    if (!soundControlToggle) {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'audio/*');
      input.click();
      input.onchange = async () => {
        const file = input.files[0];

        apiUploadFile(file);
      };
    }
  }, [apiUploadFile, soundControlToggle]);

  const formulaHandler = useCallback(() => {
    const quill = ref.current.getEditor();
    quill.getSelection(true);
    setShowFormulaModal(true);
  }, []);

  const videoHandler = useCallback(() => {
    const quill = ref.current.getEditor();
    quill.getSelection(true);
    setShowVideoModal(true);
  }, []);

  const disableMatcher = useCallback(() => {
    const Delta = Quill.import('delta');
    return new Delta().insert('');
  }, []);

  useEffect(() => {
    const editor = ref.current.getEditor();
    editor.clipboard.addMatcher('IMG', disableMatcher);
    editor.clipboard.addMatcher('PICTURE', disableMatcher);
  }, [ref, disableMatcher]);

  const addFormula = useCallback((value) => {
    if (ref.current) {
      const quill = ref.current.getEditor();
      const range = quill.getSelection(true);

      quill.insertEmbed(range.index, 'formula', { value, mathSymbols: JSON.stringify(mathSymbols) }, Quill.sources.USER);
      quill.insertText(range.index + 1, ' ', Quill.sources.USER);
      quill.setSelection(range.index + 2);
    }
  }, [ref, mathSymbols]);

  const addVideo = useCallback((value, type, canPause, limitRepetitions, maxRepetitions) => {
    if (!ref.current) {
      return;
    }

    const quill = ref.current.getEditor();
    const range = quill.getSelection(true);

    if (type === YOUTUBE) {
      const youtubeMatch = value.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) ||
        value.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/);
      const vimeoMatch = value.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/);

      let src = value;
      if (youtubeMatch) {
        src = `${youtubeMatch[1] || 'https'}://www.youtube.com/embed/${youtubeMatch[2]}?showinfo=0`;
      }

      if (vimeoMatch) {
        src = `${vimeoMatch[1] || 'https'}://player.vimeo.com/video/${vimeoMatch[2]}/`;
      }

      quill.insertText(range.index, '\n', Quill.sources.USER);
      quill.insertEmbed(range.index + 1, 'video', src, Quill.sources.USER);
      quill.insertText(range.index + 2, '\n', Quill.sources.USER);
      quill.setSelection(range.index + 2);
    }

    if (type === CUSTOM_VIDEO) {
      apiUploadFile(value, { canPause, limitRepetitions, maxRepetitions });
    }
  }, [ref, apiUploadFile]);

  const memoizedModals = useMemo(() => (
    <>
      <FormulaModal
        open={showFormulaModal}
        close={() => setShowFormulaModal(false)}
        insert={addFormula}
        mathSymbols={mathSymbols ?? expressions}
        enableMathSymbols={enableMathSymbols}
      />
      <VideoModal
        open={showVideoModal}
        close={() => setShowVideoModal(false)}
        insert={addVideo}
      />
      <AudioModal
        open={showAudioModal}
        close={() => setShowAudioModal(false)}
        insert={apiUploadFile}
      />
    </>
  ), [
    showFormulaModal, showVideoModal, showAudioModal,
    addFormula, addVideo, mathSymbols,
    enableMathSymbols, apiUploadFile,
  ]);

  return (
    <div
      className={cx(classes.quillContainer, className)}
      onDrop={(e) => e.preventDefault()}
    >
      {memoizedModals}
      <div id={id} className={cx(classes.formatContainer, classes.toolbar, { disabled })}>
        <span className="ql-formats">
          <select className="ql-size" defaultValue="normal">
            <option value="small">
              {lang.common.fontSize.small}
            </option>
            <option value="normal">
              {lang.common.fontSize.normal}
            </option>
            <option value="large">
              {lang.common.fontSize.large}
            </option>
            <option value="huge">
              {lang.common.fontSize.huge}
            </option>
          </select>
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-script" value="sub" />
          <button className="ql-script" value="super" />
          <select className="ql-align" />
          <select className="ql-color" />
          <select className="ql-background" />
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
        </span>
        {(!iaveToggle || !isStudent) && (
          <span className={cx(classes.formatContainer, 'ql-formats')}>
            <button className="ql-link" />
            <button className="ql-image" />
            <button className="ql-video" />
            <button className="ql-audio">
              <FontAwesomeIcon icon={faMicrophone} />
            </button>
          </span>
        )}
        <span className="ql-formats">
          <button className={cx(classes.formulas, 'ql-formula', { show: enableMathSymbols ?? true })} />
        </span>
      </div>
      <ReactQuill
        ref={ref}
        value={value}
        defaultValue={defaultValue}
        theme={inline ? 'bubble' : 'snow'}
        readOnly={disabled}
        onChange={disabled ? null : onChange}
        onBlur={disabled ? null : onBlur}
        placeholder={placeholder}
        autoComplete={autoComplete}
        modules={{
          toolbar: {
            container: `#${id}`,
            handlers: {
              image: imageButtonHandler,
              formula: formulaHandler,
              audio: audioButtonHandler,
              video: videoUploadToggle ? videoHandler : undefined,
            },
          },
          keyboard: {
            bindings: {
              'list autofill': {
                prefix: /(\*|\[ ?\]|\[x\])$/,
              },
            },
          },
        }}
        style={{
          width: '100%',
          backgroundColor: 'white',
          color: 'black',
        }}
        formats={[
          'bold', 'code', 'italic', 'link', 'size', 'strike', 'underline',
          'blockquote', 'header', 'indent', 'list', 'align', 'direction',
          'color', 'background', 'script',
          'formula', 'image', 'audio', 'video', 'gap', 'custom-video', 'custom-audio',
        ]}
      />
      {inline && !minified && (enableMathSymbols ?? true) && (
        <div className={classes.formulaBtnContainer}>
          <Tooltip
            tip={lang.exerciseForm.formulaHeader}
            place="right"
          >
            <FontAwesomeIcon
              icon={faSquareRootAlt}
              className={cx(classes.formulaBtn, { disabled })}
              onClick={disabled ? () => { } : formulaHandler}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

RichText.propTypes = {
  value: PropTypes.object,
  defaultValue: PropTypes.object,
  placeholder: PropTypes.string,
  inline: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  rref: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  enableMathSymbols: PropTypes.bool,
  mathSymbols: PropTypes.object,
  autoComplete: PropTypes.string,
  minified: PropTypes.bool,
};

export default RichText;
