import LocalizedStrings from 'react-localization';

const lang = new LocalizedStrings({
  en: {
    ok: 'Ok',
    add: 'Add',
    create: 'Create',
    publish: 'Publish',
    published: 'Published',
    submit: 'Submit',
    hide: 'Hide',
    preview: 'Preview',
    edit: 'Edit',
    cancel: 'Cancel',
    erase: 'Erase',
    'delete': 'Delete',
    remove: 'Remove',
    duplicate: 'Duplicate',
    discard: 'Discard',
    clear: 'Clear',
    saveChanges: 'Save changes',
    save: 'Save',
    copy: 'Copy',
    oops: 'Something went wrong. Please try again',
    waitSeconds: 'This process might take a few seconds',
    waitMinutes: 'This process might take a few minutes',
    waitTime: 'This process might take some time',
    date: 'Date',
    previous: 'Previous',
    next: 'Next',
    'export': 'Export',
    en: 'English',
    pt: 'Portuguese',
    unauthorizedMessage: 'Looks like you\'re not yet qualified to be in this area... Maybe next year!',
    notFoundMessage: 'Oh no! It seems that you lost your way around our website... Maybe it\'s not that Intuitive.',
    errorMessage: 'Something wrong happened... We\'ll be looking into fixing it as soon as possible.',
    noPermissionSpace: 'You don\'t have the permissions to access this space.',
    home: 'Home',
    account: 'Account',
    signout: 'Sign out',
    finished: 'Finished',
    example: 'Example',
    'private': 'Private',
    confirm: 'Confirm',
    total: 'Total',
    optional: 'optional',
    workInProgress: 'We are working to improve this feature',
    enable: 'Enable',
    disable: 'Disable',
    noOptionsAvailable: 'No options available',
    close: 'Close',
    'continue': 'Continue',
    'in': 'in',
    goBack: 'Go back',
    name: 'Name',
    email: 'E-mail',
    shortcuts: 'Shortcuts',
    leave: 'Leave',
    send: 'Send',
    comingSoon: 'Coming soon!',
    comment: 'Comment',
    help: 'Help',
    feedback: 'Feedback',
    copied: 'Copied',
    noEmail: 'No email',
    selectAll: 'Select all',
    general: 'General',
    discardWarning: 'If you continue, all the additions will be lost.',
    notFound: 'Not found',
    copyOf: 'Copy of',
    otherOptions: 'Other options',
    reschedule: 'Reschedule',
    results: 'Results',
    'new': 'New',
    filterBy: 'Filter by',
    copies: 'Copies',
    orderBy: 'Order by',
    oldest: 'Oldest',
    newest: 'Newest',
    relevancy: 'Relevancy',
    showMore: 'Show more',
    showLess: 'Show less',
    settings: 'Settings',
    scrollToSides: 'Scroll to the sides',
    download: 'Download',
    fileTooLarge: 'The file can\'t be larger than 10Mb',
    fileTooLarge1Gb: 'The file can\'t be larger than 1Gb',
    drag: 'Drag',
    dropHere: 'Drop here',
    changeLang: 'Change language',
    logout: 'Log out',
    video: 'Video',
    audio: 'Audio',
    tutorial: 'Tutorials',
    continueWith: 'Continue with',
    or: 'Or',
    notYou: 'Not your account?',
    guest: 'Guest',
    select: 'Select',
    internetWentWrong: 'Something went wrong. Please check your internet connection and try again',
    openFormatting: 'Open formatting',
    openMedia: 'Open media',
    appNotAvailable: 'The website is not available through browser anymore. Please use the application to access the exams.',
    generateDocument: 'Generate document',
    refresh: 'Refresh',
    seeMore: 'See more',
    deleteDisabled: 'You cannot delete other users\' content',
    andXMore: (x) => `And ${x} more...`,
    words: 'Words',
    contentSettings: {
      'public': 'Public',
      'private': 'Private',
    },
    common: {
      edit: 'Edit',
      cancel: 'Cancel',
      discard: 'Discard',
      finish: 'Finish',
      goBack: 'Go back',
      somethingWentWrong: 'Something went wrong. Please try again',
      internetWentWrong: 'Something went wrong. Please check your internet connection and try again',
      createdBy: 'Created by',
      publish: 'Publish',
      published: 'Published',
      name: 'Name',
      fontSize: {
        small: 'Small',
        normal: 'Normal',
        large: 'Large',
        huge: 'Huge',
      },
    },
    time: {
      day: 'day',
      days: 'days',
      hour: 'hour',
      hours: 'hours',
      minute: 'minute',
      minutes: 'minutes',
      second: 'second',
      seconds: 'seconds',
    },
    appKeywords: {
      classes: 'Classes',
      tests: 'Tests',
      test: 'Test',
      threads: 'Threads',
      admin: 'Administrator',
      lesson: 'Lesson',
      subject: 'Subject',
      'class': 'Class',
      exercises: 'Exercises',
      exercise: 'Exercise',
      correction: 'Correction',
      answer: 'Answer',
      grade: 'Grade',
      group: 'Group',
      groups: 'Groups',
      personalGroup: 'My content',
      allGroups: 'All groups',
      personalSpace: 'Personal space',
      students: 'Students',
      premium: 'Premium',
      premiumFreeTrial: 'Premium (free trial)',
      premiumInfo: 'Experimental period for an indefinite duration. At the moment, you can use the platform and all its functionalities for free. Later, we will introduce different paid plans for teachers, but there will always be a free plan, despite some limitations in terms of functionalities.',
      rubrics: 'Rubrics',
      rubric: 'Rubric',
      criterion: 'Criteria',
      criteria: 'Criteria',
      classic: 'Classic',
      noClassification: 'No classification',
      worksheet: 'Worksheet',
      assessment: 'Assessment',
      assessments: 'Assessments',
      spaces: 'Spaces',
      space: 'Space',
      statistics: 'Statistics',
      teachers: 'Teachers',
      events: 'Events',
      exams: 'Exams',
      examGroupsMin: 'Exam groups',
    },
    tests: {
      title: 'Testes',
      teacher: {
        subtitle: 'All the tests created for the classes you lecture',
        exercise: 'item',
        exercises: 'items',
        resolution: 'resolution',
        resolutions: 'resolutions',
        publish: 'Publish',
        grades: 'Grades',
        stateUnpublished: 'Not published',
        stateUpcoming: 'Scheduled',
        stateOngoing: 'Ongoing',
        stateComplete: 'Completed',
        noTests: 'No tests have been created yet',
      },
      student: {
        subtitle: 'All the tests made by your teachers',
        noTests: 'There are currently no tests made available for you',
        opensIn: 'Opens in',
        openingClosesAt: 'Opening closes at',
        testEndsAt: 'Test ends at',
        testNotOpen: 'The test hasn\'t opened yet',
        testWindowClosed: 'The test\'s opening window closed. It is no longer possible to open the test',
        testComplete: 'You already completed the test. All your answers were saved',
        testCompleteTime: 'The test has already ended',
        testPasswordModal: 'Test\'s password',
        testWrongPassword: 'The password is wrong',
      },
    },
    test: {
      title: 'Test',
      duplicateTest: 'Duplicate test',
      question: 'Item',
      informationBlock: 'Information block',
      information: 'Information',
      'true': 'True',
      'false': 'False',
      textPlaceholder: 'Write here your answer',
      justificationLabel: 'Justification',
      justificationPlaceholder: 'Write here your justification',
      dragImageHint: 'Drag and drop images here, or click to select images',
      dropImageHint: 'Drop to add',
      addQuestion: 'Add content',
      selectExercises: 'Select exercises',
      addExercises: 'Add exercises',
      selectedExercises: 'selected exercises',
      successAddExercises: 'The exercises were added with success',
      successAddExercise: 'The exercise was added with success',
      successAddInformationBlock: 'The information block was added with success',
      successDuplicateTest: 'The assessment was duplicated with success',
      errorAddExists: 'It is not possible to add exercises that are already added to the test',
      selectExercisesFromOtherSpace: 'You can only use exercises created by you if you select other spaces!',
      selectRubricsFromOtherSpace: 'You can only use rubrics created by you if you select other spaces!',
      createNewExercise: 'Create a new exercise',
      addInformationBlock: 'Add information block',
      selectExerciseFromDb: 'Select exercise from database',
      thisTestIsInvalid: 'This test is invalid',
      youAlreadyCompleted: 'You already completed this test before',
      deleteTest: 'Delete assessment?',
      deleteTestInfo: 'Are you sure you want to delete this assessment?',
      deleteTestInfo2: 'This action is not reversible.',
      successDeleteTest: 'The assessment was successfully deleted',
      deleteErrorDoesNotExist: 'Delete could not be done because the assessment does not exist',
      timeRemaining: 'Time remaining',
      finishTest: 'Finish',
      finishHintConfirm: 'Are you sure you want to finish now?',
      finishHintIncomplete: 'There are still items to be answered. Are you sure you want to finish now?',
      finishTestSuccess: 'You have finished. Good job!',
      finishTestEnded: 'You already finished before',
      finishErrorNetwork: 'An error ocurred finishing. Please check your internet connection and try again',
      answerError: (num) => `An error ocurred saving the answer to item ${num}. Please try again.`,
      answerErrorNetwork: (num) => `An error ocurred saving the answer to item ${num}. Please check your internet connection and try again.`,
      answered: 'Answered',
      attemptWindow: 'Opening window',
      duration: 'Duration',
      stateScheduled: 'Scheduled',
      stateOngoing: 'Ongoing',
      stateFinished: 'Finished',
      moveUp: 'Move up',
      moveDown: 'Move down',
      errorMovePublished: 'The order could not be changed because the test is published. Unpublish if you wish to make changes',
      hintMoveNotPossible: 'That move is not possible',
      removeFromTest: 'Remove from test',
      removeExerciseFromTest: 'Remove exercise from the assessment',
      removeInformationBlockFromTest: 'Remove information block from the assessment',
      removeSectionFromTest: 'Remove section from the assessment',
      hintConfirmRemove: 'Are you sure you want to remove this exercise from the assessment?',
      hintConfirmRemoveInformationBlock: 'Are you sure you want to remove this information block from the assessment?',
      hintConfirmRemoveSection: 'Are you sure you want to remove this section from the assessment?',
      errorRemovePublished: 'The exercise could not be removed because the test is published. Unpublish if you wish to make changes',
      link: 'Link',
      copied: 'Copied',
      password: 'Password',
      compacted: 'Compact',
      presentationFull: 'Full',
      presentationIncremental: 'Incremental',
      navigationLinear: 'Linear',
      navigationNonLinear: 'Non-Linear',
      presentationFullTip: 'All items visible at the same time',
      presentationIncrementalTip: 'Only one item visible at a time',
      navigationLinearTip: 'Not being able to go back to update an answer',
      navigationNonLinearTip: 'Being able to go back to update an answer',
      header: {
        exportPhysical: 'Physical version',
      },
      testForm: {
        createTest: 'Create assessment',
        editTest: 'Edit test',
        testName: 'Assessment\'s name',
        errorNameRequired: 'It is required to set a name',
        errorSubjectRequired: 'It is required to choose a subject',
        errorGroupRequired: 'It is required to choose a group',
        errorNoExercises: 'It is required to add at least one exercise',
        successCreateTest: 'The test was created with success',
        successEditTest: 'The test was updated with success',
        classificationTypeLabel: 'Classification type',
        whoCanSee: 'Who can see this test?',
        privacyTypeLabel: 'Privacy type',
        classicClassificationTypeTip: 'The exercises are graded and a final grade is given',
        rubricClassificationTypeTip: 'The level of the students is evaluated through the defined criteria',
        noClassificationTypeTip: 'The exercises are not graded e there is no final grade',
        testTypeLabel: 'Assessment type',
        testTypeTip: 'Assessment with a time limit scheduled for a specific date',
        worksheetTypeTip: 'Assessment that stays available until the delivery date',
        'public': 'Public',
        publicTip: 'Visible on the "Explore" page',
        'private': 'Private',
        privateTip: 'Visible only to you or to the groups that you share the exercise with',
      },
      publishModal: {
        testFormat: 'Assessment format',
        navigationType: 'Navigation type',
        errorTypeRequired: 'It is required to choose a type of the test',
        testShuffleLabel: 'Shuffle the exercises\' order',
        shuffleNoneLabel: 'Don\'t shuffle anything',
        shuffleBaseLabel: 'Shuffle sections and exercises outside the sections, and keep order of exercises inside the sections',
        shuffleSectionsLabel: 'Keep order of sections and exercises outside the sections, and shuffle exercises inside the sections',
        shuffleAllLabel: 'Shuffle all the sections, exercises outside the sections, and exercises inside the sections',
        publishTest: 'Publish assessment',
        unpublishTest: 'Unpublish assessment',
        rescheduleTest: 'Reschedule assessment',
        editTest: 'Edit assessment',
        editPublication: 'Edit publication',
        successUnpublishTest: 'The test was unpublished with success',
        beforePublishing: 'Before publishing...',
        labelStartsAt: 'Starting date and time',
        labelEndsAt: 'Ending date and time',
        labelAttemptWindow: 'Opening window (in minutes)',
        labelDuration: 'Solving duration (in minutes)',
        labelPassword: 'Password',
        labelExitPin: 'Exit pin',
        hintExitPin: 'This field defines a test exit pin for students using the exam-taking app',
        labelTargets: 'Classes and students',
        hintAttemptWindow: 'This field defines how many minutes the students have to start the test',
        hintTargets: 'This field defines which classes and students can open the test',
        errorStartsAtRequired: 'It is required to set a starting date',
        errorStartsAtPast: 'The starting date must be in the future',
        errorEndsAtRequired: 'It is required to set a ending date',
        errorEndsAtBeforeStartsAt: 'The ending date must be after the starting date',
        errorAttemptWindowRequired: 'It is required to set an opening window',
        errorAttemptWindowBoundaries: 'This value must be between 1 and 4320 (3 days)',
        errorDurationRequired: 'It is required to set a duration',
        errorDurationBoundaries: 'This value must be between 1 and 1440 (1 day)',
        errorEditDuration: (duration) => `The duration must be greater than ${duration} minutes`,
        successPublishTest: 'The assessment was published with success',
        successRescheduleTest: 'The test was rescheduled with success',
        successEditPublication: 'The publication was updated with success',
        publishErrorDoesNotExist: 'The test could not be published because it does not exist',
        publishErrorNoLesson: 'The test could not be published because it must belong to a class',
        publishErrorNoExercises: 'The test could not be published because it does not have any exercises',
        publishErrorAlreadyPublished: 'The test could not be published because it is already published',
        republishErrorNotPublished: 'The test could not be rescheduled because it is not published',
        publishErrorAlreadyStarted: 'It was not possible to update the test\'s publish information because the test has started or starts in less than 1 minute',
        unpublishErrorDoesNotExist: 'The test could not be unpublished because it does not exist',
        unpublishErrorAlreadyUnpublished: 'the test could not be unpublished because it is already unpublished',
        publishPreHint: 'Before being possible to publish, this assessment should:',
        hintHaveExercises: 'Have at least 1 exercise',
        errorTargetsRequired: 'It is required to choose at least one class or one student',
        hintInformationBlocksShuffle: 'All the information blocks will remain in the same place, only the exercises will be shuffled. If you are using an information block to give context to following exercises please consider unchecking this option.',
        hintHasRubric: 'Have a rubric selected',
        hintAreWeightsCorrect: 'Have the total of the weights per exercise match 100%',
        hintSectionsHaveExercises: 'Have at least 1 exercise in each section',
        showRubricLabel: 'Show the rubric to students during the test',
        opensIn: 'Opens in:',
        endsIn: 'Ends in:',
        scheduledFor: 'Scheduled for',
        openedOn: 'Opened on',
        endedOn: 'Ended on',
        endsOn: 'Ends on',
        immediateFeedbackLabel: 'Immediate feedback: student can see the result and solutions immediately after the test ends',
        modelAnswerLabel: 'Model answer: student can see the model answer after the test ends',
        linearNavigation: 'Linear navigation: The student cannot go back to previous items',
        nonLinearNavigation: 'Non-linear navigation: The student can go back to previous items',
        createAttempts: 'Create attempts',
        successCreateAttempts: 'The attempts were created with success',
      },
      grades: {
        noAttempt: 'No submission',
        point: 'Point',
        points: 'Points',
        defineGrade: 'Define grade',
        assignGrade: 'Assign grade',
        maxValueExceeded: 'The grade value can\'t exceed the max value defined',
        notEmpty: 'The grade value can\'t be empty',
        noStudents: 'There are currently no students assigned to this assessment.',
        maxValueLabel: 'Maximum:',
        negativeValueError: 'The points assigned to an exercise can\'t be negative',
        testOf: 'Test of',
        generateGrades: 'Generate grades',
        successUpdatePoints: 'The exercise points were updated with success!',
        successUpdatePointsWarn: 'The exercise points were updated with success! Don\'t forget to generate the grades again',
        pointsTooHigh: 'The points assigned to the exercise are too high!',
        studentName: 'Student name',
        noAnswersYet: 'No student has attempted the assessment yet to generate grades',
        submission: 'Submission',
        successUpdateGrade: 'The grade was updated with success!',
        submissionLink: 'Submission link',
        publishCorrections: 'Publish corrections',
        correctionsPublished: 'Corrections published',
        exportResolutions: 'Export student resolutions',
        addComment: 'Add comment',
        successUpdateFeedback: 'The comment was updated with success!',
        unavailable: 'This will become available once the test finishes',
        unavailableStudent: 'This will become available once the student finishes the test',
        publishCorrectionsInformation: 'An e-mail will be sent to each student notifying them that their assessment was corrected. The e-mail will contain a link where they can see the attempt and feedback.',
        successUpdateCriteriaGrade: 'The criteria grade was successfully updated',
        criteriaGradeNotEmptyError: 'The criteria grade can\'t be empty',
        criteriaGradeNegativeValueError: 'The criteria grade can\'t have a negative value',
        criteriaGradeTooHighError: 'The criteria grade can\'t be higher than 5',
        exportAttemptsCSV: 'Export resolutions CSV',
        exportGrades: 'Export grades Excel',
        deleteAttemptUnavailable: 'This student has not yet started an attempt.',
        deleteAttemptConfirm: (name) => `Are you sure you want to delete ${name} attempt?`,
        deleteAttemptConfirmSubtitle: 'This action is not reversible.',
        successDeleteAttempt: 'The attempt was deleted successfully.',
        studentSearch: 'Search for students',
        noStudentsFound: 'No students found',
        showModelAnswer: 'Model answer: student can see the model answer when reviewing the assessment',
        notFinished: 'Not finished',
        attemptDates: 'Started at - Ended at',
      },
      stats: {
        classAverage: 'Average',
        classMedian: 'Mean',
        averageInfo: 'The average value is calculated by adding all test results and dividing by the number of tests',
        medianInfo: 'The median is the middle value obtained when all test results are ordered from lowest to highest',
        positive: 'Positive',
        negative: 'Negative',
        highest: 'Highest grade',
        lowest: 'Lowest grade',
        question: 'Question',
        sortOrder: 'Sort: ',
        lowToHigh: 'Ascending',
        highToLow: 'Descending',
        avrgGrade: 'Average grade',
        avrgNormalized: 'Average normalized grade',
        avrgNormalizedInfo: 'Order questions by average normalized grade in ascending or descending order',
        avrgQuestionInfo: 'The average grade obtained in this question',
        premiumStats: 'Get a premium membership to view more advanced statistics',
        bestQuestion: 'Question with the best answers',
        worstQuestion: 'Question with the worst answers',
        bestQuestionShort: 'Best question',
        worstQuestionShort: 'Worst question',
        noAnswer: 'No answer',
        distributionChart: 'Number of results',
        choices: 'Multiple choice',
        ascSort: 'Click to sort ascending',
        descSort: 'Click to sort descending',
        cancelSort: 'Click to cancel sorting',
        fastestTime: 'Fastest time',
        slowestTime: 'Slowest time',
        averageTime: 'Average time',
        inProgress: 'In progress...',
        studentName: 'Student name',
        studentGrade: 'Grade',
        studentRank: 'Rank',
        previewAnswer: 'Preview exercise',
        studentCount: 'Number of students',
        gradeCount: 'Number of grades',
        gradeCountTip: 'In the represented intervals, such as 0% to 10%, the lower limit (0%) is included in the bar and the upper limit is not, except for the last interval, 90% to 100%, which includes both. ',
      },
      physicalVersion: {
        physicalVersion: 'Physical Version',
        exportTest: 'Export assessment',
        generalSettings: 'General settings',
        versionsLabel: 'Number of versions',
        versionsPlaceholder: 'Insert the number of versions',
        localeLabel: 'Language',
        shuffleExercisesLabel: 'Shuffle exercise\'s order',
        shuffleChoicesLabel: 'Shuffle choice\'s order from multiple choice exercises',
        noExercises: 'The test needs at least one exercise to be exported',
        notEnoughExercises: 'There is not enough exercises to satisfy the requested versions.',
        headerSettings: 'Header settings',
        studentInput: 'Student fills',
        testName: 'Assessment name',
        testNamePlaceholder: 'Insert here the assessment name',
        testNameEmptyError: 'The assessment name can\'t be empty',
        displayVersion: 'Display the assessment version',
        schoolName: 'School name',
        schoolNamePlaceholder: 'Insert here the school name',
        schoolNameEmptyError: 'The school name can\'t be empty',
        schoolLogo: 'School logo',
        schoolLogoPlaceholder: 'Drag or click here to insert an image',
        schoolLogoEmptyError: 'You must insert an image for the school logo',
        subject: 'Subject',
        subjectPlaceholder: 'Insert here the subject name',
        subjectEmptyError: 'The subject name can\'t be empty',
        testDuration: 'Assessment duration (in minutes)',
        testDurationPlaceholder: 'Insert here the assessment duration',
        testDurationEmptyError: 'The test duration can\'t be empty',
        schoolYear: 'School year',
        schoolYearPlaceholder: 'Insert here the school year',
        schoolYearEmptyError: 'The school year can\'t be empty',
        'class': 'Class',
        classPlaceholder: 'Insert here the class',
        classEmptyError: 'The class can\'t be empty',
        date: 'Assessment date',
        datePlaceholder: 'Insert here the assessment date',
        dateEmptyError: 'The assessment date can\'t be empty',
        exercisesSettings: 'Exercises settings',
        includeZone: 'Include answer zone',
        askForName: 'Student\'s name',
        askForCode: 'Student\'s code',
        askForGrade: 'Student grade',
        extraFields: 'Extra fields',
        extraFieldButton: 'Add new field',
        extraFieldLabel: 'Field name:',
        extraFieldLabelPlaceholder: 'Insert here the field name',
        customFieldEmptyLabelError: 'You must insert a field name',
        extraFieldContent: 'Field content:',
        extraFieldContentPlaceholder: 'Insert here the field content',
        customFieldEmptyContentError: 'You must insert the field content',
        numberOfLines: 'Number of answer lines',
        numberOfLinesJustify: 'Number of justification lines',
        showLines: 'Show lines',
        includeRubric: 'Include rubric in the exported versions',
        noRubric: 'You haven\'t selected a rubric yet',
        sectionWithoutExercises: 'This section doesn\'t have exercises',
      },
      attemptsExport: {
        includeSchoolLogo: 'Include school logo',
        schoolLogoPlaceholder: 'Drag or click here to insert an image',
        schoolLogoEmptyError: 'You must insert an image for the school logo',
        includeGrade: 'Include grade',
        includeRubric: 'Include rubric',
        waitWhileGenerateDocuments: 'Wait while we generate the documents',
        clickBelowToDownloadDocuments: 'Click below to download the documents',
        exportAttemptsPDF: 'Export attempts PDF',
        generatingPDFs: 'Generating the PDFs',
        zippingPDFs: 'Zipping the PDFs',
        generateSuccessDescription: 'The exams were sucessfully exported to PDF! Click in the button below to download.',
        includeModelAnswer: 'Include model answer',
        exportAttemptsNotAvailable: 'There are no attempts to export',
        downloadAttempts: 'Download attempts',
        downloadValidatedExplanation: 'Only validated attempts will be exported',
      },
      send: {
        sendTest: 'Send assessment',
        keepCopy: 'Keep original and send a copy',
        successSendTest: 'The assessment has been sent successfully',
        errorSendTestGroup: 'You must choose a group to send the assessment.',
        errorSendTestSchool: 'You must choose a space to send the assessment.',
        errorSendTestToMySpace: 'You can\'t send assessments from other users to your personal space.',
      },
      settingsModal: {
        name: 'Name',
        successEditSettings: 'The test settings were updated with success',
      },
      rubric: {
        addRubric: 'Add rubric',
        selectRubricFromBank: 'Select rubric from bank',
        successSetRubric: 'The rubric has been successfully added',
        changeRubric: 'Change rubric',
        noRubric: 'There is no rubric associated with this test yet',
        totalPonderations: 'Total ponderations for the rubric criteria',
        notEmpty: 'The exercise criteria weight can\'t be empty',
        negativeValueError: 'The exercise criteria weight can\'t have a negative value',
        weightTooHigh: 'The exercise criteria weight can\'t be over 100%',
        successUpdateCriteriaWeight: 'The exercise criteria weight was updated with success',
        errorTotal: 'The total of the weights for an exercise must equal 100%',
        createRubric: 'Create new rubric',
        editRubric: 'Edit rubric',
        successEditRubric: 'The rubric was updated with success',
      },
      exercises: {
        addSection: 'Add section',
        sectionNameLabel: 'Section name',
        sectionDescriptionLabel: 'Section description',
        sectionNameRequiredError: 'The section name is required',
        addSectionSuccessful: 'The section was successfully added to the test',
        editSectionSuccessful: 'The section was successfully updated',
        sectionPresentation: 'Section presentation',
        sectionNavigation: 'Section navigation',
        sectionTimerLabel: 'Section timer in minutes (optional)',
        sectionTimerWarning: 'The section timer only works on incremental tests',
        pauseItem: 'Pause item',
        addPauseItem: 'Add pause item',
        addPauseItemStepHeader: 'Pause message',
        addPauseItemPlaceholder: 'Write here the pause message',
        successAddPauseItem: 'The pause item was created successfully',
        editPublishedExerciseWarning: 'Once the assessment is published, certain details cannot be edited and upon saving the changes, the exercise will be automatically graded again and any previous grades will be replaced.',
        editPointsSuccess: 'The exercise points were successfully updated',
        editPointsSuccessAfterPublish: 'The exercise points were successfully updated. The grades might take some time to update',
        editWeightsSuccess: 'The exercise criteria weights were successfully updated',
        editWeightsSuccessAfterPublish: 'The exercise criteria weights were successfully updated. The grades might take some time to update',
      },
    },
    exerciseForm: {
      createExerciseOf: 'Create exercise of',
      editExerciseOf: 'Edit exercise of',
      editExercise: 'Edit exercise',
      selectExerciseType: 'Select exercise type',
      typeChoices: 'Multiple choice',
      typeTrueFalse: 'True / False',
      typeText: 'Text',
      typeImage: 'Images submission',
      typeFilling: 'Fill the gaps',
      typeConnecting: 'Connecting',
      typeOrdering: 'Ordering',
      typeSmartChoices: 'Smart multiple choice',
      typeCaption: 'Captioning',
      typeSegmentation: 'Segmentation',
      typeTable: 'Table',
      newChoices: 'New multiple choice exercise',
      newTrueFalse: 'New true / false exercise',
      newText: 'New text exercise',
      newImage: 'New image submission exercise',
      newSmartChoices: 'New smart multiple choice exercise',
      editChoices: 'Editing multiple choice exercise',
      editTrueFalse: 'Editing true / false exercise',
      editText: 'Editing text exercise',
      editImage: 'Editing image submission exercise',
      editSmartChoices: 'Editing smart multiple choice exercise',
      discardTypeTitle: 'Discard exercise',
      discardTypeContent: 'If you continue, all the additions will be lost',
      saveWarningContent: 'Upon saving, the exercise will be automatically graded again and any previous grades will be replaced.',
      statement: 'Statement',
      statementPlaceholder: 'Write here the statement',
      statementPlaceholderInformation: 'Write here the information',
      noEmptyStatement: 'The statement can\'t be empty',
      noEmptyFormula: 'You cannot insert an empty formula',
      noInvalidFormula: 'The formula seems to be invalid. If it has initial or ending spaces remove them. Please fix it before continuing',
      trueFalse: 'True / false',
      noEmptyTruth: 'It is required to select a truth value',
      choices: 'Choices',
      choicesTip: 'Add the different options and choose the correct option by clicking the checkmark button on the left side of the option.',
      oneCorrectOption: 'As you published the assessment with the exercise having only one correct answer, you must keep only one correct option.',
      multipleCorrectOptions: 'As you published the assessment with the exercise having more than one correct answer, you must keep at least two correct options.',
      itemstoOrder: 'Items to order',
      itemsToOrderTip: 'The items should be ordered in the order you consider as correct. The items will be randomized when presented to the students.',
      option: 'Option',
      addOption: 'Add new option',
      removeOption: 'Remove option',
      noEmptyOption: 'There cannot be empty options',
      noDuplicateOptions: 'There cannot be duplicate options',
      noAnswerChoiceDefined: 'It is required to define the correct option',
      editMultipleOptionFail: 'It is required to define at least two correct options',
      item: 'Item',
      addItem: 'Add new item',
      removeItem: 'Remove item',
      noEmptyItem: 'There cannot be empty items',
      noDuplicateItems: 'There cannot be duplicate items',
      noAnswerGapDefined: 'It is required to define at least one gap',
      noExtraWordDefined: 'It is required to define at least one extra option for each gap',
      previewAndSave: 'Preview and save',
      saveExercise: 'Save exercise',
      successExerciseCreated: 'Exercise created with success',
      successExerciseEdited: 'Changes saved with success',
      successExerciseEditedAfterPublish: 'Changes saved with success. The grades might take some time to update',
      advancedMode: 'LaTeX mode',
      formulaHeader: 'Add formula',
      formulaLabel: 'Write here your formula',
      formulaReference: 'Supported formula reference',
      formulaSectionGeneral: 'General',
      formulaSectionMath: 'Math',
      videoHeader: 'Add video',
      youtube: 'Youtube video',
      youtubeLink: 'Youtube link',
      invalidUrl: 'It is only possible to insert Youtube videos links',
      noVideoChosen: 'You didn\'t choose any link',
      myVideo: 'My video',
      addVideoLabel: 'Click to add a video',
      justificationLabel: 'Justification',
      justificationText: 'Add space for justification',
      askForImage: 'Image',
      askForImageError: 'Wrong file type! Please insert only image files',
      caption: 'Caption',
      createCaptions: 'Create the captions',
      editCaptions: 'Edit the captions',
      createCaptionsDescription: 'Click the image to add a caption. You can press and hold a caption to be able to drag it.',
      editCaptionsDescription: 'Click a caption to edit the text.',
      captionWarning: 'Warning: This exercise has a better experience when answering it on a computer.',
      shortAnswer: 'Answer',
      shortAnswerText: 'Turn into short answer exercise',
      characterLimit: 'Character limit',
      characterLimitError: 'Specify the number of characters',
      extraText: 'Extra text',
      extraTextPlaceholder: 'Insert here the extra text',
      extraTextStartOpen: 'Start fully expanded',
      identifiers: 'Identifiers',
      useMathSymbols: 'Use math symbols (Student)',
      wordCountLabel: 'Show word count (Student)',
      exerciseSettings: {
        label: 'Exercise Settings',
        title: 'Exercise Title',
        whoCanSee: 'Who can see this exercise?',
        areas: 'Which subjects are relevant to this exercise?',
        years: 'For which grades?',
        errorTitleRequired: 'It is required to pick a title',
        errorSubjectRequired: 'It is required to pick a subject',
        errorYearRequired: 'It is required to pick a grade',
        'public': 'Public',
        publicTip: 'Visible on the "Explore" page',
        'private': 'Private',
        privateTip: 'Visible only to you or to the groups that you share the exercise with',
      },
      gap: {
        choosingGaps: 'Choosing the words to hide',
        choosingGapsSub: 'Select the words you want to hide from the students',
        noGaps: 'There are no words in the statement yet',
        choosingGapExtraOption: 'Exercise options (student side)',
        dragDropOption: 'Show the list of hidden words (dragging)',
        dropdownOption: 'Show the list of hidden words (dropdown)',
        writeOption: 'Don\'t how the list of hidden words (write)',
        example: 'Example',
        write: 'Write...',
        correctionAuto: 'Automatic correction by the platform',
        correctionManual: 'Correction made by the teacher',
        askForExtraGapsButton: 'Save extra words',
        addExtraGaps: 'Add extra words',
        editExtraGaps: 'Edit extra words',
        addExtraGapsSub: 'Write extra words to add difficulty to this exercise',
        editExtraGapsSub: 'Edit the extra words to add difficulty to this exercise',
        addGapOptions: 'Add options for gap: ',
        editGapOptions: 'Edit options for gap: ',
        word: 'Word',
        orderGaps: 'Order words',
        noExtraGaps: 'There are no extra words to edit',
      },
      choice: {
        shuffleChoices: 'Shuffle multiple choices',
      },
      connecting: {
        shuffleConnectors: 'Shuffle connectors',
        connectors: 'Connectors',
        connector: 'Connector',
        connections: 'Connections',
        addConnector: 'Add connector',
        noEmptyConnector: 'There cannot be empty connectors',
        noConnections: 'It is required to have at least one connection',
        noConnectors: 'It is required to have at least two connectors',
        noConnectorsLeft: 'It is required to have at least one connector on the left column',
        noConnectorsRight: 'It is required to have at least one connector on the right column',
      },
      segmentation: {
        createConnectorsAndConnections: 'Create connectors and connections',
        createConnectorsAndConnectionsDescription: 'Click the image to add a connector. You can create a connection by clicking the two connectors you want to connect.',
        showOnlyLine: 'Show only line',
      },
      table: {
        tableGrading: 'Grading options',
        gradeByRow: 'By row',
        gradeByTable: 'By table',
        gradeByRowTip: 'The exercise will be graded by row, meaning the student has to answer correctly on every column of the row, by either selecting it or not, if not the row is considered incorrect.',
        gradeByTableTip: 'The exercise will be graded by table, meaning that every single cell has to be answered correctly, otherwise the exercise is considered incorrect.',
        noEmptyHeaderCell: 'There cannot be empty main header cells',
        headerPlaceholder: 'Header',
        rowGradingExplanation: 'Graded by row',
        tableGradingExplanation: 'Graded by table',
      },
      audio: {
        audioHeader: 'Add audio',
        addAudioLabel: 'Click to add an audio',
        askForAudioError: 'Wrong file type! Please insert only audio files',
        noAudioChosen: 'You didn\'t choose an audio file',
        canPauseLabel: 'The student can pause the audio execution',
        canPause: 'Can pause',
        cannotPause: 'Cannot pause',
        limitRepetitionsLabel: 'Limit the amount of times the student can repeat the audio',
        maxRepetitionsLabel: 'Maximum repetitions',
        repetitions: 'repetitions',
        retryMessage: 'An error occurred. Try to play the audio again',
      },
      video: {
        audioHeader: 'Add video',
        addAudioLabel: 'Click to add a video',
        askForAudioError: 'Wrong file type! Please insert only video files',
        noAudioChosen: 'You didn\'t choose an video file',
        canPauseLabel: 'The student can pause the video execution',
        canPause: 'Can pause',
        cannotPause: 'Cannot pause',
        limitRepetitionsLabel: 'Limit the amount of times the student can repeat the video',
        maxRepetitionsLabel: 'Maximum repetitions',
        repetitions: 'repetitions',
      },
      modelAnswer: 'Model Answer',
      modelAnswerPlaceholder: 'Insert here the model answer',
    },
    exercises: {
      title: 'Exercises database',
      subtitle: 'All the exercises created',
      createNewExercise: 'Create exercise',
      createNewExerciseSubtitle: 'Here we\'re going to create a new exercise',
      cancelCreation: 'Cancel creation',
      cancelEdit: 'Cancel edit',
      creatingNewExerciseOn: 'Creating a new exercise on',
      editingExercise: 'Editing an exercise on',
      deleteExercise: 'Delete exercise',
      deleteExercises: 'Delete exercises',
      deleteExerciseInfo: 'Are you sure you want to delete this exercise?',
      deleteExercisesInfo: 'Are you sure you want to delete these exercises?',
      deleteExerciseInfo2: 'This action is not reversible',
      successDeleteExercise: 'The exercise was successfully deleted',
      successDeleteExercises: 'The exercises were successfully deleted',
      successDuplicateExercise: 'The exercise was duplicated with success',
      deleteErrorDoesNotExist: 'Delete could not be done because the exercise already does not exist',
      previewExercise: 'Preview exercise',
      previewErrorDoesNotExist: 'Preview could not be loaded because the exercise does not exist anymore',
      noExercises: 'No exercises have been created yet.',
      noExercisesForFilters: 'There\'s no exercises for the selected filters.',
      gapList: 'Words/expressions list:',
      gapListUnused: 'Unused words/expressions list:',
      labels: {
        addLabel: 'Add label',
        createLabel: 'Create label',
        repeatedLabelError: 'There is already a label with that name',
        labelName: 'Label name',
        errorNoColorSelected: 'It is required to set a color',
        errorNameRequired: 'It is required to set a name',
        labels: 'Labels',
        editLabels: 'Edit labels',
        successEditLabel: 'The label was successfully edited',
        successDeleteLabel: 'The label was successfully deleted',
      },
      filters: 'Filters',
      teacherFilter: 'Teachers',
      typeFilter: 'Exercise types',
      order: 'Order',
      orderNewest: 'Newest',
      orderOldest: 'Oldest',
      orderLastModified: 'Last modified',
      send: {
        sendExercise: 'Send exercise',
        sendExercises: 'Send exercises',
        keepCopy: 'Keep original and send a copy',
        sendLabels: 'Send labels to the other group',
        successSendExercise: 'The exercise has been sent successfully',
        successSendExercises: 'The exercises have been sent successfully',
        errorSendExerciseGroup: 'You must choose a group to send the exercise.',
        errorSendExercisesGroup: 'You must choose a group to send the exercises.',
        errorSendExercisesSchool: 'You must choose a space to send the exercises.',
        errorSendExercisesToMySpace: 'You can\'t send exercises from other users to your personal space.',
      },
      modelAnswer: 'Model answer',
    },
    attempt: {
      nextExercise: 'Next exercise',
      nextItem: 'Next',
      previousExercise: 'Previous exercise',
      previousItem: 'Previous',
      finishedAttempt: 'Finished. You can close the tab.',
      finishedAttemptIave: 'Test finished. Wait for teacher\'s instructions.',
      characterLimitReached: 'Maximum number of characters reached',
      timesUp: 'Section time\'s up',
      unsavedAnswers: 'There was an error saving your answer(s). Check your internet connection and try to save again',
      unsavedAnswersFinish: 'You have unsaved answers. Are you sure you want to finish?',
      answersSaved: 'The answers were successfully saved',
      errorSaveAnswers: 'An error ocurred while saving the answers',
      exitPinModal: {
        pinLabel: 'Please input the exit exam pin provided by the supervisor',
        pinPlaceholder: 'Pin',
        modalHeader: 'Exit exam pin',
        wrongPin: 'The pin is not correct',
        emptyPin: 'You must insert a pin to proceed',
      },
      oops: 'An error occurred while saving your answer(s). Check your internet connection and try again',
      continueWithoutSaving: 'Continue without saving',
      saveAndContinue: 'Save and continue',
      status: 'Status',
      statusValid: 'Valid',
      statusInvalid: 'Invalid',
      statusPending: 'Pending',
      statusUpdated: 'Successfully updated',
    },
    groups: {
      title: 'Groups',
      teachers: 'teachers',
      teacher: 'teacher',
      createNewGroup: 'Create group',
      groupName: 'Group\'s name',
      successCreateGroup: 'The group was successfully created',
      hintTargets: 'This field defines which teachers will be added to the group',
      errorNameRequired: 'It is required to set a name',
      noGroups: 'Still don\'t have a group? Create one and start collaborating with other teachers!',
      successEditGroup: 'The group was successfully edited',
      userNotAllowed: 'The user has already exceeded the limit of groups they can join',
    },
    group: {
      title: 'Group',
      leaveGroup: 'Leave group',
      leaveGroupInfo: 'Are you sure you want to leave this group?',
      leaveGroupInfo2: 'This action is not reversible and you will lose access to all the group\'s content.',
      successLeaveGroup: 'You successfully left the group',
      removeTeacher: 'Remove teacher?',
      removeTeacherInfo: 'Are you sure you want to remove this teacher?',
      successRemoveTeacher: 'The teacher was removed successfully',
      successAddTeacher: 'The teachers were added successfully',
      addTeacher: 'Add teachers',
      hintTargets: 'Insert the e-mails of the teachers that you want to add to the group',
      hintTargetsCreatable: 'Insert the e-mails of the teachers that you want to add to the group. You can add e-mails of teachers that are not in Intuitivo in order to invite them.',
      groupSettings: 'Group settings',
      deleteGroup: 'Delete group',
      deleteGroups: 'Delete groups',
      deleteGroupInfo: 'Are you sure you want to delete the group? You won\'t be able to recover the contents afterwards. This action is not reversible.',
      deleteGroupsInfo: 'Are you sure you want to delete these groups? You won\'t be able to recover the contents afterwards. This action is not reversible.',
      successDeleteGroup: 'The groups was successfully deleted',
      removeSelected: 'Remove selected',
    },
    publication: {
      password: 'Test\'s password',
      testWindowClosedInfo: 'The test\'s opening window closed.',
      studentName: 'Student name',
      errorNameRequired: 'It is required to insert your name.',
      errorEmail: 'The e-mail you provided is incorrect.',
      passwordRequired: 'It is required to provide a password.',
      emailHint: 'The inserted e-mail will be used to send you your grade and feedback once it\'s published.',
      continueAsGuest: 'Continue as guest',
      continueAs: (name) => `Continue as ${name}`,
      notYou: 'Not you?',
      enterAsNewStudent: 'Enter as a new student',
    },
    rubrics: {
      title: 'Rubrics',
      noRubrics: 'No rubrics have been created yet',
      previewRubric: 'Preview rubric',
      performanceLevels: 'Performance levels',
      createRubric: 'Create rubric',
      editRubric: 'Edit rubric',
      deleteRubric: 'Delete rubric',
      deleteRubrics: 'Delete rubrics',
      deleteRubricInformation: 'Are you sure you want to delete this rubric? This action is not reversible.',
      deleteRubricsInformation: 'Are you sure you want to delete these rubrics? This action is not reversible.',
      successDeleteRubric: 'The rubric was successfully deleted',
      successDeleteRubrics: 'The rubrics were successfully deleted',
      successDuplicateRubric: 'The rubric was successfully duplicated',
      form: {
        rubricNameLabel: 'Rubric name',
        rubricDescriptionLabel: 'Rubric description',
        criteriaNameLabel: 'Criteria name',
        criteriaDescriptionLabel: 'Criteria description',
        performanceLevelDescriptionLabel: 'Performance level description',
        addCriteria: 'Add criteria',
        discardRubric: 'Discard rubric',
        nameRequiredError: 'It is required to insert a name for the rubric.',
        criteriaNameRequiredError: 'It is required to insert a name for the criteria.',
        criteriaRequiredError: 'It is required to create at least one criteria for this rubric.',
        createRubricSuccess: 'The rubric was successfully created',
        editRubricSuccess: 'The rubric was successfully updated',
      },
      send: {
        sendRubric: 'Send rubric',
        sendRubrics: 'Send rubrics',
        keepCopy: 'Keep original and send a copy',
        successSendRubric: 'The rubric has been sent successfully',
        successSendRubrics: 'The rubrics have been sent successfully',
        errorSendRubricGroup: 'You must choose a group to send the rubric.',
        errorSendRubricsGroup: 'You must choose a group to send the rubrics.',
        errorSendRubricsSchool: 'You must choose a space to send the rubrics.',
        errorSendRubricsToMySpace: 'You can\'t send rubrics from other users to your personal space.',
      },
    },
    explore: {
      title: 'Explore',
      header: 'Explore the available contents',
      headerXs: 'Explore',
      contentsListHeader: 'Most used contents, according to the selected filters',
      contentsListHeaderLg: 'Most used contents',
      contentsListHeaderXs: 'Contents',
      noResultsHeader: 'Oops... We couldn\'t find any content matching your search :(',
      noResultsDescription: 'Try searching for a more general term, change the filters or verify if there is some error in the search keywords.',
      noResultsDescription2: 'If these tips don\'t work, we invite you to create new content.',
      createNewExercise: 'Create exercise',
      endMessage: 'You\'ve seen all the results corresponding to your search.',
      scrollBackToTopMessage: 'Scroll back to the top of the page',
      searchPlaceholder: 'Search for contents in Intuitivo...',
      addFilter: 'Add filter',
      subjectAreas: 'Subject areas',
      schoolYears: 'School years',
      numberOfUses: 'Usages',
      copyInformation: 'To which group do you which to copy this exercise?',
      successCopyExercise: 'The exercise was successfully copied to your group',
      infoTip: 'Fill in your preferences on your profile page for a more personalized experience',
      searchAll: 'All content',
    },
    profilePreferences: {
      setProfilePreferences: 'Before you continue, help us improve your experience.',
      saveProfilePreferences: 'The preferences were successfully saved',
      warningProfilePreferences: 'To change your preferences go to the "Account" page',
      subjects: 'Which subjects are you teaching?',
      years: 'To which grades?',
    },
    login: {
      loginWithMicrosoft: 'Login with Microsoft',
      poweredBy: 'Powered by',
    },
    tours: {
      tip: 'Guides',
      title: 'Do you need help?',
      startTour: 'Start',
      subtitles: [
        'In this section you can find multiple guides to help you interact with Intuitivo.',
        'To start, simply select one of the guides available below.',
      ],
      back: 'Back',
      next: 'Next',
      complete: 'Complete',
      introduction: {
        title: 'Introduction',
        subtitle: 'Learn the basics of Intuitivo',
        steps: {
          introduction: 'Welcome to Intuitivo, we have put together a guide to help you get started. To continue click on the "Next" button. If you\'re already familiar with Intuitivo, you can close this message.',
          testCard: 'To help you get started with Intuitivo, we\'ve created a simple assessment to explore together.',
          testCardPublishButton: 'Published assessments will show up with this green colored icon. If not yet published, it will be grey.',
          testCardLeft: 'Click on this card to see more details for this assessment.',
          tabExercises: 'Right now, you\'re in the "Exercises" tab. Here you can see which exercises constitute the assessment.',
          tabGrade: 'For this guide, the assessment has already been published and has had 2 submissions. Select the "Correction" tab to see the student\' submissions',
          statistics: 'You can see the general stats for this assessment.',
          attempts: 'You also have access to this list of students\' names and grades who have submitted their answers for this assessment. Clicking on a name will show the student\'s answers.',
          attemptContainer: 'This entire section is dedicated to the selected student\'s individual attempt.',
          attemptHeader: 'The header of each attempt contains information about the student, as well as final grade and submission link.',
          multipleChoice: 'You\'ll notice that the student has answered correctly to this "Multiple Choice" exercise.',
          multipleChoiceGrader: 'Intuitivo automatically grades some exercise types, as is the case for this "Multiple Choice" exercise. The student has obtained the maximum score, however, you can freely edit this value.',
          openQuestionGrader: 'For "Text exercises" you\'ll have to manually grade it.',
          openQuestionFeedback: 'You can also provide feedback on a per question basis.',
          publishGradesButton: 'Once you\'ve graded the assessment for all students, you can publish grades and corrections. Students which have provided an email address in the beginning of the test will receive their grade by email.',
          tabStatistics: 'In the "Statistics" tab, you can have a detailed view of students performance.',
          assessmentSidebarOption: 'Let\'s go back to the "Assessments" page.',
          testCardDropdown: 'When you\'re done exploring this assessment, you can delete it at any time by clicking on this menu and choosing the "Delete" option.',
          hub: 'You can have another go at this guide at anytime. We recommend you explore this section in case you need help with creating assessments and exercises.',
        },
      },
      assessments: {
        title: 'Assessments',
        subtitle: 'Learn how to create tests and worksheets',
        steps: {
          sidebarGoToPage: 'First, go to the "Assessments" page.',
          sidebar: 'You can access assessments on the "Assessments" page.',
          createButton: 'To create an assessment, click on the "Create Assessment" button.',
          nameInput: 'Give a name to your assessment, something like "My first assessment".',
          typeInput: `Choose an assessment type:
            <ul>
              <li><strong>Test:</strong> Has a time limit and is scheduled for a specific date.</li>
              <li><strong>Worksheet:</strong> Accessible until a delivery date.</li>
            </ul>
            Let's leave the default "Test" option.`,
          classificationInput: `Choose an assessment type:
            <ul>
              <li><strong>Classic:</strong> Student submissions are graded.</li>
              <li><strong>Rubric:</strong> Have a defined assessment criteria (You can learn more about rubrics on the "Rubrics" tour).</li>
              <li><strong>No Classification:</strong> No grade is given to student submissions.</li>
            </ul>
            Let's leave the default "Classic" option.`,
          groupInput: 'You can save this assessment on any group you belong to. (You can learn more about groups on the "Groups" tour).',
          submitButton: 'Complete assessment creation by submitting this form.',
          exercisesTab: 'You\'re currently on the "Exercises" tab. Here is where you can add content to your assessment, like exercises, sections and information blocks.',
          gradesTab: 'When your assessment is published and is answered by your students, you\'l be able to see their submissions in the "Correction" tab.',
          physicalTab: 'In the "Physical Version" tab, you can download your assessment as a PDF document.',
          contentForm: 'In this section you can add content to your assessment.',
          createNewExercise: 'Let\'s do exactly that. Click on "Create a new exercise".',
          exerciseType: 'You can choose what exercise type you want to create.',
          trueOrFalseExerciseType: 'Choose the "True/False" option.',
          exerciseStatement: 'Write the exercise\'s statement, something like "Are dolphins mammals?"',
          exerciseCorrectAnswer: 'In this section you can choose the correct answer.',
          exerciseTrue: 'In this case, let\'s choose the "True" option.',
          exerciseSubmit: 'You\'re all set. This exercise will also be added to your "Exercise" library (You can learn more about exercises on the "Exercise" guide). For now, click on "Confirm".',
          exerciseGrade: 'You can edit the grade of single exercises.',
          exerciseEdit: 'You can edit an exercise anytime by clicking this button.',
          exerciseDelete: 'An exercise can also be deleted from an assessment.',
          publishButton: 'You\'re test is now ready to be published. Click on the "Publish" button.',
          publishFormFormat: 'You can choose if you want to show all exercises at once, or have only one exercise visible at a time.',
          publishFormStart: 'Choose a start date for this test.',
          publishFormTolerance: 'Choose the number of minutes a student can be late for the test.',
          publishFormDuration: 'Choose how much time your students have to complete this test',
          publishFormPassword: 'You can choose to protect your test by setting a password which will be requested when your students start your test.',
          publishFormShuffle: 'You can choose to shuffle the test\'s questions, that way all your students will have a different test.',
          publishFormFeedback: 'You can also choose to allow your students to have access to the test\'s answers after submitting.',
          publishFormModelAnswer: 'If the immediate feedback option is chosen, you can choose to allow your students to have access to the exercise model answers after submitting.',
          publishFormSubmit: 'You\'re all set, you can now publish your test by clicking in "Publish".',
          publishLink: 'Congratulations, your test has been published. Now you simply need to share this link with your students for them to take the test.',
          publishCloseModal: 'You can now close this window.',
          publishButtonFinal: 'You can view the previous window again by clicking on this button.',
          hub: 'This and other guides are always available on the Guide section.',
        },
      },
      explore: {
        title: 'Explore',
        subtitle: 'Learn how to use and adapt other teachers\' content.',
        steps: {
          sidebarExplore: 'To start this guide, go to the "Explore" page.',
          sidebar: 'You can access assessments on the "Explore" page.',
          exerciseList: 'On this page you can find all the exercises and assessments created and made public by other schools and teachers that use Intuitivo. To contribute, just save your own content as public during exercise or assessment creation.',
          header: 'On this section you have access to a toll which allows you to search public contents.',
          headerTextInput: 'You can freely search for keywords.',
          headerContentDropdown: 'You can also filter by content type: Assessment, Exercises or both',
          headerFilters: 'There\'s also the option to search by School Year or Subject. By default, you profile preferences are selected (You cna change them on your account settings).',
          exerciseCardClick: 'Click on the following content for a detailed view.',
          exerciseCardPreview: 'Here you can preview the content in more detail',
          exerciseCardPreviewCopy: 'To add this content to you profile you simply need to click on "Copy".',
          exerciseCardPreviewSelectGroup: 'Then you need to choose the destination group for this content.',
          exerciseCardPreviewSubmit: 'And finally click on "Confirm".',
          exerciseCardClosed: 'Now you can freely see and edit this content on the "Assessments" or "Exercises" page, depending on the type.',
          hub: 'You can come back to this and other guides anytime, by clicking on the "Guide" hub.',
        },
      },
      groups: {
        title: 'Groups',
        subtitle: 'Learn how to create and use groups to collaborate with your colleagues',
        steps: {
          sidebarGoToPage: 'To start this guide, go to the "Groups" page.',
          sidebar: 'You can access groups on the "Groups" page.',
          createNewGroup: 'To create a new group click on the "Create Group" button',
          formNameInput: 'Name this group, for example "Math Teachers - Junior Year',
          formTeachersInput: 'In this section you can add your colleagues that are also using Intuitivo through their email address. However, for the purpose of this guide you can leave this field blank.',
          formConfirm: 'Finish your new group creation by clicking on "Confirm".',
          groupCard: 'Each group will be presented as a card named after it. Click on it to have a detailed view.',
          teachersTable: 'In this table you can see all group members\' name and email address.',
          addTeacherButton: 'To add new members, you can click on this button, which will present you with a form to add the new members\' email address.',
          groupShortcuts: `
            <p>
              On this card, you can find shortcuts to the "Assessments", "Exercises" and "Rubrics" pages for this group.
              In each of these pages you'll find a dropdown to navigate between groups, as the following image illustrates:
            </p>
            <img style="width: 100%" src="${process.env.REACT_APP_S3_URL}/resources/groups-header-shortcut-en.png" style="width: 100%">
          `,
          leaveGroup: 'To leave a group simply click on this menu and select the "Leave Group" option.',
          hub: 'You can come back to this and other guides anytime, by clicking on the "Guide" hub.',
        },
      },
      rubrics: {
        title: 'Rubrics',
        subtitle: 'Learn how to create and use rubrics on your assessments',
        steps: {
          sidebarGoToPage: 'To start this guide, go to the "Rubrics" page.',
          sidebar: 'You can access assessments on the "Rubrics" page.',
          createNewButton: 'To create a new Rubric click on "Create rubric".',
          nameInput: 'Name your rubric, for example "Assessment Criteria - History"',
          descriptionInput: 'You can write a description to add more context',
          criteriaCard: 'In this section you can add all the criteria you think are necessary. Criteria levels range from 1 to 5.',
          criteria0NameInput: 'Name this criteria "Contents".',
          criteria0ValueInput: `
            Next, you can add performance descriptors to this rubric. For example:
            <ul>
              <li><strong>(5):</strong> Explains completely what was intended;</li>
              <li><strong>(4):</strong> Explains completely what was partially intended;</li>
              <li><strong>(3):</strong> Explains incompletely what was intended;</li>
              <li><strong>(2):</strong> Explains incompletely what was partially intended;
              <li><strong>(1):</strong> Explains incompletely only part of what was intended;</li>
            </ul>`,
          addCriteriaButton: 'Add a new criteria by clicking on this button',
          criteria1NameInput: 'Name this criteria "Communication".',
          criteria1ValueInput: 'Similarly to the last criteria, you can add what performance descriptors you deem as necessary. However, for our case you can leave them blank.',
          criteria1RemoveButton: `
            If you want to delete a criteria, just click on the icon
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" class="svg-inline--fa fa-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path>
            </svg>`,
          exerciseExample: `
            <p>
              During an assessment's correction of type rubric, each question's assessment will be done by criteria.
              That way you can attribute specific weight to each criteria for each exercise, as the next image illustrates:
            </p>
            <img src="${process.env.REACT_APP_S3_URL}/resources/exercise-rubric-example-en.png" style="width: 100%">

            <p>If you need to, there "Assessments" and "Introduction" guides demonstrate assessment creation, publication and correction.</p>
          `,
          groupDropdown: 'Just like in other examples, you can choose a group to save this rubric on. The "Groups" guide contains more information about this feature.',
          saveButton: 'Finish the creation of this rubric by clicking on "Save".',
          card: 'A created rubric will show up as such.',
          cardActions: `
            In this section of the rubric's card you can:
            <ul>
              <li>Preview;</li>
              <li>Edit;</li>
              <li>Send to another group;</li>
              <li>Duplicate;</li>
              <li>Delete;</li>
            </ul>
          `,
          hub: 'You can come back to this and other guides anytime, by clicking on the "Guide" hub.',
        },
      },
      exercises: {
        title: 'Exercises',
        subtitle: 'Learn how to create and use exercises',
        steps: {
          sidebarGoToPage: 'First, go to the "Exercises" page.',
          sidebar: 'You can access exercises on the "Exercises" page.',
          createExerciseButton: 'To create an exercise, click on the button "Create Exercise"',
          createExerciseType: 'There are multiple types of exercises to choose from. We recommend you dedicate some time to explore all these different types. If you need any guidance, you can always check our <a href="https://intuitivo.pt/en/support/tutorials" target="_blank">Tutorials</a> page.',
          createExerciseTypeText: 'For the purposes of this guide, create a simple "Text" exercise.',
          editorHeaderTypeDropdown: 'The first step for exercise creation is complete. However, if you need to change the exercise type you can do it here.',
          editorHeaderGroupDropdown: 'Likewise, you can choose in which group this exercise will be saved. For more information about groups, there\'s the "Groups" guide available on the guide section.',
          editorStatement: `
            Write down the exercise statement. You can add multiple media contents such as images, videos and audios. You also have access to a math formula editor.
            <br/>
            For this example exercise, you can write down something like <i>"Which city was the capital of the Anglo-Saxon kingdom of Wessex?"</i>.
          `,
          editorShortAnswer: 'Depending on the type of exercise, you\'ll have different customization options available. For text exercises you can limit the answer\'s number of characters and allow access to the maths formula editor.',
          editorExtraText: 'In every exercise type, you can also add extra text to the exercise, which will be shown below the student answer field.',
          editorModelAnswer: 'Depending on the exercise type, you can also add a model answer to the exercise, which will not be shown to the student during the assessment but can be optionally presented after the assessment ends.',
          editorPreview: 'You\'ll always have a preview available. This will be what your students will see during an assessment.',
          editorSettingsFormTitle: `
            To finish exercise creation, you'll have to give it a name.
            <br/>
            How about something like <i>"The capital of Wessex"</i>?
          `,
          editorSettingsFormVisibility: `
            Next you can choose if you want to keep your exercise public or private:
            <ul>
              <li><strong>Public:</strong> the exercise will be available on the <i>"Explore"</i> page and can be copied and used by other teachers.
              <li><strong>Private:</strong> the exercise will only be available for you and group members.</li>
            </ul>
            For more information about the <i>"Explore"</i> and <i>"Groups"</i> page, there are guides walking you through those features.
          `,
          editorSettingsFormVisibilityPrivate: 'Let\'s keep the exercise private. Select the <i>"Private"</i> option.',
          editorLabelsButton: 'If necessary, you can create and add labels to catalogue your exercises. This effort will help you filter and search exercises.',
          editorHeaderSaveExercise: 'Finally, complete exercise creation by clicking on the "Save" button.',
          exerciseCard: 'An exercise will show up like this',
          exerciseCardActions: `
            In this section you can:
            <ul>
              <li>Preview the exercise;</li>
              <li>Edit;</li>
              <li>Send to another group;</li>
              <li>Duplicate;</li>
              <li>Delete;</li>
            </ul>
          `,
          exerciseFilters: `
            You also have the possibility of filtering exercises by:
            <ul>
              <li>Authoring teacher;</li>
              <li>Type;</li>
              <li>Labels;</li>
            </ul>
            And sort by:
            <ul>
              <li>Most recent;</li>
              <li>Oldest;</li>
              <li>Last modified;</li>
            </ul>
          `,
          hub: 'You can come back to this and other guides anytime, by clicking on the "Guide" hub.',
        },
      },
    },
    plans: {
      premium: 'Premium',
      free: 'Basic',
      exceededTests: (limit) => `You exceeded the limit of ${limit} free ${limit === 1 ? 'assessment' : 'assessments'} created by you across all groups that you participate. Delete assessments or upgrade to premium with one of the options below.`,
      exceededTestsTip: (limit) => `You exceeded the limit of ${limit} free ${limit === 1 ? 'assessment' : 'assessments'} created by you across all groups that you participate. Delete assessments or upgrade to premium.`,
      upgrade: 'UPGRADE',
      getPremium: 'Get Premium',
      unlimitedAccessMessage: 'Unlock unlimited access to Intuitivo individually.',
      recommendSchool: 'Recommend School',
      recommend: 'RECOMMEND',
      dedicatedSpace: 'Unlock a dedicated space for your school with unlimited access to Intuitivo.',
      exceededExercises: (limit) => `You have exceeded the limit of ${limit} free ${limit === 1 ? 'exercise' : 'exercises'} created by you across all groups that you participate. Delete exercises or upgrade to premium with one of the options below.`,
      exceededExercisesTip: (limit) => `You have exceeded the limit of ${limit} free ${limit === 1 ? 'exercise' : 'exercises'} created by you across all groups that you participate. Delete exercises or upgrade to premium.`,
      exceededRubrics: (limit) => `You have exceeded the limit of ${limit} free ${limit === 1 ? 'rubric' : 'rubrics'} created by you across all groups that you participate. Delete rubrics or upgrade to premium with one of the options below.`,
      exceededRubricsTip: (limit) => `You have exceeded the limit of ${limit} free ${limit === 1 ? 'rubric' : 'rubrics'} created by you across all groups that you participate. Delete rubrics or upgrade to premium.`,
      exceededGroups: (limit) => `You have exceeded the limit of ${limit} free ${limit === 1 ? 'group' : 'groups'}. Leave groups or upgrade to premium with one of the options below.`,
      exceededSections: (limit) => `You have exceeded the limit of ${limit} free ${limit === 1 ? 'section' : 'sections'} per test. Delete sections or upgrade to premium with one of the options below.`,
      premiumFeature: 'This is a premium feature. Upgrade to premium to unlock it',
      warningCreatePremiumExercise: 'You can create but you can\'t publish an assessment with this type of exercises.',
      warningPublishTestWithPremiumExercise: 'You can\'t publish an assessment that contains premium exercises. Delete those exercises or upgrade to premium with one of the options below.',
      warningPublishTestWithExceededSections: (limit) => `You can't publish an assessment that contains more than ${limit} ${limit === 1 ? 'section' : 'sections'}. Delete sections or upgrade to premium with one of the options below.`,
      warningExportTestMaxUsages: (limit) => `You can only export up to ${limit} ${limit === 1 ? 'version' : 'versions'}. Upgrade to premium to unlock more.`,
      schools: 'Schools',
    },
    navigation: {
      title: 'Navigation',
      section: 'Section',
    },
    curricula: {
      pt_PT: 'Portuguese',
      other: 'Other',
      curriculum: 'Curriculum',
    },
  },
  pt: {
    ok: 'Ok',
    add: 'Adicionar',
    create: 'Criar',
    publish: 'Publicar',
    published: 'Publicado',
    submit: 'Submeter',
    hide: 'Esconder',
    preview: 'Visualizar',
    edit: 'Editar',
    cancel: 'Cancelar',
    erase: 'Apagar',
    'delete': 'Apagar',
    remove: 'Remover',
    duplicate: 'Duplicar',
    discard: 'Descartar',
    clear: 'Limpar',
    saveChanges: 'Guardar alterações',
    save: 'Gravar',
    copy: 'Copiar',
    oops: 'Algo não correu bem. Por favor tente novamente',
    waitSeconds: 'Este processo pode demorar alguns segundos',
    waitMinutes: 'Este processo pode demorar alguns minutos',
    waitTime: 'Este processo pode demorar algum tempo',
    date: 'Data',
    previous: 'Anterior',
    next: 'Seguinte',
    'export': 'Exportar',
    en: 'Inglês',
    pt: 'Português',
    unauthorizedMessage: 'Parece que ainda não é suficientemente qualificado para estar nesta área... Talvez no próximo ano!',
    notFoundMessage: 'Oh não! Parece que se perdeu no nosso site... Talvez não seja assim tão Intuitivo.',
    errorMessage: 'Algo de errado aconteceu... Vamos verificar e corrigir o mais rápido possível.',
    noPermissionSpace: 'Não tem permissões para aceder a este espaço.',
    home: 'Início',
    account: 'Conta',
    signout: 'Sair',
    finished: 'Terminado',
    example: 'Exemplo',
    'private': 'Privado',
    confirm: 'Confirmar',
    total: 'Total',
    optional: 'opcional',
    workInProgress: 'Estamos a trabalhar para melhorar esta funcionalidade',
    enable: 'Ativar',
    disable: 'Desativar',
    noOptionsAvailable: 'Nenhuma opção disponível',
    close: 'Fechar',
    'continue': 'Continuar',
    'in': 'em',
    goBack: 'Voltar atrás',
    name: 'Nome',
    email: 'E-mail',
    shortcuts: 'Atalhos',
    leave: 'Sair',
    send: 'Enviar',
    comingSoon: 'Em breve!',
    comment: 'Comentário',
    help: 'Ajuda',
    feedback: 'Sugestões',
    copied: 'Copiado',
    noEmail: 'Sem email',
    selectAll: 'Selecionar tudo',
    general: 'Geral',
    discardWarning: 'Se continuar, todas as adições serão perdidas.',
    notFound: 'Não encontrado',
    copyOf: 'Cópia de',
    otherOptions: 'Outras opções',
    reschedule: 'Reagendar',
    results: 'Resultados',
    'new': 'Novo',
    filterBy: 'Filtrar por',
    copies: 'Cópias',
    orderBy: 'Ordenar por',
    oldest: 'Mais antigo',
    newest: 'Mais recente',
    relevancy: 'Relevância',
    showMore: 'Ver mais',
    showLess: 'Ver menos',
    settings: 'Definições',
    scrollToSides: 'Move para os lados',
    download: 'Descarregar',
    fileTooLarge: 'O ficheiro não pode ser maior que 10Mb',
    fileTooLarge1Gb: 'O ficheiro não pode ser maior que 1Gb',
    drag: 'Arrastar',
    dropHere: 'Arrasta para aqui',
    changeLang: 'Mudar linguagem',
    logout: 'Terminar sessão',
    video: 'Vídeo',
    audio: 'Áudio',
    tutorial: 'Tutoriais',
    continueWith: 'Continuar com',
    or: 'Ou',
    notYou: 'Não é a sua conta?',
    guest: 'Convidado',
    select: 'Selecionar',
    internetWentWrong: 'Algo não correu bem. Por favor verifique a sua conexão à internet e tente novamente',
    openFormatting: 'Abrir formatação',
    openMedia: 'Abrir media',
    appNotAvailable: 'O website já não está disponível a partir do browser. Por favor utilize a aplicação para aceder às provas.',
    generateDocument: 'Gerar documento',
    refresh: 'Atualizar',
    seeMore: 'Ver mais',
    deleteDisabled: 'Não pode apagar conteúdos de outros utilizadores',
    andXMore: (x) => `E ${x} outros...`,
    words: 'Palavras',
    contentSettings: {
      'public': 'Público',
      'private': 'Privado',
    },
    common: {
      edit: 'Editar',
      cancel: 'Cancelar',
      discard: 'Descartar',
      finish: 'Terminar',
      goBack: 'Voltar atrás',
      somethingWentWrong: 'Algo não correu bem. Por favor tente novamente',
      internetWentWrong: 'Algo não correu bem. Por favor verifique a sua conexão à internet e tente novamente',
      createdBy: 'Criado por',
      publish: 'Publicar',
      published: 'Publicado',
      name: 'Nome',
      preview: 'Pré-visualizar',
      fontSize: {
        small: 'Pequeno',
        normal: 'Normal',
        large: 'Grande',
        huge: 'Gigante',
      },
    },
    time: {
      day: 'dia',
      days: 'dias',
      hour: 'hora',
      hours: 'horas',
      minute: 'minuto',
      minutes: 'minutos',
      second: 'segundo',
      seconds: 'segundos',
    },
    appKeywords: {
      classes: 'Turmas',
      tests: 'Testes',
      test: 'Teste',
      threads: 'Fóruns',
      admin: 'Administrador',
      lesson: 'Aula',
      subject: 'Matéria',
      'class': 'Turma',
      exercises: 'Exercícios',
      exercise: 'Exercício',
      correction: 'Correção',
      answer: 'Resposta',
      grade: 'Nota',
      group: 'Grupo',
      groups: 'Grupos',
      personalGroup: 'Meus conteúdos',
      allGroups: 'Todos os grupos',
      personalSpace: 'Espaço pessoal',
      students: 'Estudantes',
      premium: 'Premium',
      premiumFreeTrial: 'Premium (período experimental)',
      premiumInfo: 'Período experimental por duração indefinida. Neste momento, pode utilizar a plataforma e todas as suas funcionalidades de forma gratuita e ilimitada. Mais tarde, iremos introduzir diferentes planos para professores, sendo que haverá sempre um plano gratuito apesar de ter certas limitações em termos de funcionalidades.',
      rubrics: 'Rubricas',
      rubric: 'Rubrica',
      criterion: 'Critério',
      criteria: 'Critérios',
      classic: 'Clássico',
      noClassification: 'Sem classificação',
      worksheet: 'Ficha',
      assessment: 'Avaliação',
      assessments: 'Avaliações',
      spaces: 'Espaços',
      space: 'Espaço',
      statistics: 'Estatísticas',
      teachers: 'Professores',
      events: 'Eventos',
      exams: 'Exames',
      examGroupsMin: 'Grupos Provas',
    },
    tests: {
      title: 'Testes',
      teacher: {
        subtitle: 'Todos os testes criados para as turmas a que dá aulas',
        exercise: 'item',
        exercises: 'itens',
        resolution: 'resolução',
        resolutions: 'resoluções',
        publish: 'Publicar',
        grades: 'Notas',
        stateUnpublished: 'Não publicado',
        stateUpcoming: 'Agendado',
        stateOngoing: 'A decorrer',
        stateComplete: 'Terminado',
        noTests: 'Ainda não existe nenhum teste criado',
      },
      student: {
        subtitle: 'Todos os testes criados pelos seus professores',
        noTests: 'Não existem testes atualmente disponíveis para si',
        opensIn: 'Abre em',
        openingClosesAt: 'Abertura fecha em',
        testEndsAt: 'Teste termina em',
        testNotOpen: 'O teste ainda não abriu',
        testWindowClosed: 'A tolerância de abertura do teste terminou. Já não será possível abrir o teste',
        testComplete: 'Este teste já foi completado. Todas as respostas foram guardadas',
        testCompleteTime: 'O teste já terminou',
        testPasswordModal: 'Password do teste',
        testWrongPassword: 'A password está errada',
      },
    },
    test: {
      title: 'Teste',
      duplicateTest: 'Duplicar teste',
      question: 'Item',
      informationBlock: 'Bloco de informação',
      information: 'Informação',
      'true': 'Verdadeiro',
      'false': 'Falso',
      textPlaceholder: 'Escreva aqui a resposta',
      justificationLabel: 'Justificação',
      justificationPlaceholder: 'Escreva aqui a justificação',
      dragImageHint: 'Arraste imagens para aqui, ou clique para selecionar imagens',
      dropImageHint: 'Largue para adicionar',
      addQuestion: 'Adicionar conteúdo',
      selectExercises: 'Selecionar exercícios',
      addExercises: 'Adicionar exercícios',
      selectedExercises: 'exercícios selecionados',
      successAddExercises: 'Os exercícios foram adicionados com sucesso',
      successAddExercise: 'O exercício foi adicionado com sucesso',
      successAddInformationBlock: 'O bloco de informação foi adicionado com sucesso',
      successDuplicateTest: 'A avaliação foi duplicada com sucesso',
      errorAddExists: 'Não é possível adicionar exercícios que já foram adicionados ao teste',
      selectExercisesFromOtherSpace: 'Só pode utilizar exercícios criados por si se selecionar outro espaço!',
      selectRubricsFromOtherSpace: 'Só pode utilizar rubricas criadas por si se selecionar outro espaço!',
      createNewExercise: 'Criar um novo exercício',
      addInformationBlock: 'Adicionar bloco de informação',
      selectExerciseFromDb: 'Selecionar exercício do banco',
      thisTestIsInvalid: 'Este teste é inválido',
      youAlreadyCompleted: 'Já concluíu este teste anteriormente',
      deleteTest: 'Apagar a avaliação?',
      deleteTestInfo: 'Tem a certeza que quer apagar esta avaliação?',
      deleteTestInfo2: 'Esta ação não é reversível.',
      successDeleteTest: 'A avaliação foi apagada com sucesso',
      deleteErrorDoesNotExist: 'Não foi possível apagar a avaliação porque não existe',
      timeRemaining: 'Tempo restante',
      finishTest: 'Terminar',
      finishHintConfirm: 'Tens a certeza que queres terminar agora?',
      finishHintIncomplete: 'Ainda existem itens por responder. Tem a certeza que pretende terminar agora?',
      finishTestSuccess: 'Terminado. Bom trabalho!',
      finishTestEnded: 'Já tinha terminado antes',
      finishErrorNetwork: 'Ocorreu um erro ao terminar. Por favor verifique a sua conexão à internet e tente novamente',
      answerError: (num) => `Ocorreu um erro a guardar a resposta ao item ${num}. Por favor tente novamente.`,
      answerErrorNetwork: (num) => `Ocorreu um erro a guardar a resposta ao item ${num}. Por favor verifique a sua conexão à internet e tente novamente.`,
      answered: 'Respondida',
      attemptWindow: 'Tolerância de abertura',
      duration: 'Duração',
      stateScheduled: 'Agendado',
      stateOngoing: 'A decorrer',
      stateFinished: 'Terminado',
      moveUp: 'Mover para cima',
      moveDown: 'Mover para baixo',
      errorMovePublished: 'Não foi possível alterar a ordem porque o teste se encontra publicado. Despublique o teste para efetuar alterações',
      hintMoveNotPossible: 'Mover o exercício nessa direção não é possível',
      removeFromTest: 'Remover do teste',
      removeExerciseFromTest: 'Remover exercício da avaliação',
      removeInformationBlockFromTest: 'Remover bloco de informação da avaliação',
      removeSectionFromTest: 'Remover secção da avaliação',
      hintConfirmRemove: 'Tem a certeza que quer remover este exercício da avaliação?',
      hintConfirmRemoveInformationBlock: 'Tem a certeza que quer remover este bloco de informação da avaliação?',
      hintConfirmRemoveSection: 'Tem a certeza que quer remover esta secção da avaliação?',
      errorRemovePublished: 'Não foi possível remover o exercício porque o teste se encontra publicado. Despublique o teste para efetuar alterações',
      link: 'Link',
      copied: 'Copiado',
      password: 'Password',
      compacted: 'Compacto',
      presentationFull: 'Completo',
      presentationIncremental: 'Incremental',
      navigationLinear: 'Linear',
      navigationNonLinear: 'Não-linear',
      presentationFullTip: 'Todos os itens visíveis ao mesmo tempo',
      presentationIncrementalTip: 'Um item visível de cada vez',
      navigationLinearTip: 'Sem possibilidade de voltar atrás para alterar uma resposta',
      navigationNonLinearTip: 'Com possibilidade de voltar atrás para alterar uma resposta',
      header: {
        exportPhysical: 'Versão física',
      },
      testForm: {
        createTest: 'Criar avaliação',
        editTest: 'Editar teste',
        testName: 'Nome da avaliação',
        errorNameRequired: 'É obrigatório preencher o nome',
        errorSubjectRequired: 'É obrigatório escolher uma disciplina',
        errorGroupRequired: 'É obrigatório escolher um grupo',
        errorNoExercises: 'É obrigatório adicionar pelo menos um exercício',
        successCreateTest: 'O teste foi criado com sucesso',
        successEditTest: 'O teste foi editado com sucesso',
        classificationTypeLabel: 'Tipo de classificação',
        whoCanSee: 'Quem pode ver este teste?',
        privacyTypeLabel: 'Tipo de privacidade',
        classicClassificationTypeTip: 'Os exercícios são pontuados e uma nota final é dada',
        rubricClassificationTypeTip: 'O nível dos alunos é avaliado através dos critérios definidos',
        noClassificationTypeTip: 'Os exercícios não são classificados e não existe nota final',
        testTypeLabel: 'Tipo de avaliação',
        testTypeTip: 'Avaliação agendada para um horário específico, com tempo limite para o aluno resolver',
        worksheetTypeTip: 'Avaliação que fica disponível até à data limite de entrega',
        'public': 'Público',
        publicTip: 'Vísivel na página "Explorar"',
        'private': 'Privado',
        privateTip: 'Visível apenas para si ou para os grupos com os quais partilhar o exercício',
      },
      publishModal: {
        testFormat: 'Formato de avaliação',
        navigationType: 'Tipo de navegação',
        errorTypeRequired: 'É obrigatório escolher o tipo do teste',
        testShuffleLabel: 'Baralhar a ordem dos exercícios',
        shuffleNoneLabel: 'Não baralhar nada',
        shuffleBaseLabel: 'Baralhar as secções e exercícios fora das secções, e manter ordem dos exercícios dentro das secções',
        shuffleSectionsLabel: 'Manter ordem das secções e dos exercícios fora das secções, e baralhar exercícios dentro das secções',
        shuffleAllLabel: 'Baralhar todas as secções, exercícios fora das secções e exercícios dentro das secções',
        publishTest: 'Publicar avaliação',
        unpublishTest: 'Despublicar avaliação',
        rescheduleTest: 'Reagendar avaliação',
        editTest: 'Editar avaliação',
        editPublication: 'Editar publicação',
        successUnpublishTest: 'O teste foi despublicado com sucesso',
        beforePublishing: 'Antes de publicar...',
        labelStartsAt: 'Data e hora de abertura',
        labelEndsAt: 'Data e hora de fecho',
        labelAttemptWindow: 'Tolerância de abertura (em minutos)',
        labelDuration: 'Duração da resolução (em minutos)',
        labelPassword: 'Password',
        labelExitPin: 'Pin de saída',
        hintExitPin: 'Este campo define um pin de saída do teste para os alunos que usarem a aplicação de realização de provas',
        labelTargets: 'Turmas e estudantes',
        hintAttemptWindow: 'Este campo define quantos minutos os alunos têm para iniciar o teste',
        hintTargets: 'Este campo define quais turmas e alunos podem realizar o teste',
        errorStartsAtRequired: 'É obrigatório definir uma data de abertura',
        errorStartsAtPast: 'A data de abertura deve ser no futuro',
        errorEndsAtRequired: 'É obrigatório definir uma data de fecho',
        errorEndsAtBeforeStartsAt: 'A data de fecho deve ser depois da data de abertura',
        errorAttemptWindowRequired: 'É obrigatório definir os minutos de tolerância de abertura',
        errorAttemptWindowBoundaries: 'Este valor deve estar entre 1 e 4320 (3 dias)',
        errorDurationRequired: 'É obrigatório definir os minutos de duração do teste',
        errorDurationBoundaries: 'Este valor deve estar entre 1 e 1440 (1 dia)',
        errorEditDuration: (duration) => `A duração precisa de ser maior que ${duration} minutos`,
        successPublishTest: 'A avaliação foi publicada com sucesso',
        successRescheduleTest: 'O teste foi reagendado com sucesso',
        successEditPublication: 'A publicação foi editada com sucesso',
        publishErrorDoesNotExist: 'Não foi possível publicar o teste porque o teste não existe',
        publishErrorNoLesson: 'Não foi possível publicar o teste porque o teste deve pertencer a uma turma',
        publishErrorNoExercises: 'Não foi possível publicar o teste porque o teste não contém nenhum exercício',
        publishErrorAlreadyPublished: 'Não foi possível publicar o teste porque o teste já estava publicado',
        republishErrorNotPublished: 'Não foi possível reagendar o teste porque o teste não se encontra publicado',
        publishErrorAlreadyStarted: 'Não foi possível alterar a publicação porque o teste já começou ou falta menos de 1 minuto para começar',
        unpublishErrorDoesNotExist: 'Não foi possível despublicar o teste porque o teste não existe',
        unpublishErrorAlreadyUnpublished: 'Não foi possível despublicar o teste porque o teste já se encontra despublicado',
        publishPreHint: 'Antes de ser possível publicar, esta avaliação deve:',
        hintHaveExercises: 'Ter pelo menos 1 exercício',
        errorTargetsRequired: 'É obrigatório escolher pelo menos uma turma ou um aluno',
        hintInformationBlocksShuffle: 'Todos os blocos de informação ficarão no mesmo sítio, apenas os exercícios serão baralhados. Se está a usar um bloco de informação para dar contexto a exercícios seguintes por favor considere desselecionar esta opção.',
        hintHasRubric: 'Ter uma rubrica selecionada',
        hintAreWeightsCorrect: 'Ter o total das ponderações por exercício igual a 100%',
        hintSectionsHaveExercises: 'Todas as secções devem ter pelo menos 1 exercício',
        showRubricLabel: 'Mostrar rubrica aos estudantes durante o teste',
        opensIn: 'Abre em:',
        endsIn: 'Termina em:',
        scheduledFor: 'Agendado para',
        openedOn: 'Abriu a',
        endedOn: 'Terminou a',
        endsOn: 'Termina a',
        immediateFeedbackLabel: 'Feedback imediato: estudante pode ver o seu resultado e as soluções imediatamente após o teste terminar',
        modelAnswerLabel: 'Resposta modelo: estudante pode ver a resposta modelo após o teste terminar',
        linearNavigation: 'Navegação linear: estudante não pode voltar a itens anteriores',
        nonLinearNavigation: 'Navegação não-linear: estudante pode voltar a itens anteriores',
        createAttempts: 'Gerar provas',
        successCreateAttempts: 'As provas foram geradas com sucesso',
      },
      grades: {
        noAttempt: 'Sem submissão',
        point: 'Ponto',
        points: 'Pontos',
        defineGrade: 'Definir pontuação',
        assignGrade: 'Atribuir pontuação',
        maxValueExceeded: 'O valor da nota não pode exceder o valor máximo definido',
        notEmpty: 'O valor da nota não pode estar vazio',
        noStudents: 'De momento, não há estudantes atribuídos a esta avaliação.',
        maxValueLabel: 'Máximo:',
        negativeValueError: 'Os pontos atribuídos a um exercício não podem ser negativos',
        testOf: 'Teste de',
        generateGrades: 'Gerar notas',
        successUpdatePoints: 'Os pontos do exercício foram atualizados com sucesso!',
        successUpdatePointsWarn: 'Os pontos do exercício foram atualizados com sucesso! Não se esqueça de gerar as notas de novo',
        pointsTooHigh: 'A cotação atribuída ao exercício é demasiado alta!',
        studentName: 'Nome do estudante',
        noAnswersYet: 'Ainda nenhum aluno realizou a avaliação para poder gerar notas',
        submission: 'Submissão',
        successUpdateGrade: 'A nota foi atualizada com sucesso!',
        submissionLink: 'Link da submissão',
        publishCorrections: 'Publicar correções',
        correctionsPublished: 'Correções publicadas',
        exportResolutions: 'Exportar resoluções',
        addComment: 'Adicionar comentário',
        successUpdateFeedback: 'O comentário foi atualizado com sucesso!',
        unavailable: 'Ficará disponível quando o teste terminar',
        unavailableStudent: 'Ficará disponível quando o aluno terminar o teste',
        publishCorrectionsInformation: 'Um e-mail será enviado para cada aluno notificando que a avaliação deles já foi corrigida. O e-mail irá conter um link onde eles poderão ver o teste e comentários.',
        successUpdateCriteriaGrade: 'A nota do critério foi atualizada com sucesso',
        criteriaGradeNotEmptyError: 'A nota do critério não pode estar vazia',
        criteriaGradeNegativeValueError: 'A nota do critério não pode ser negativa',
        criteriaGradeTooHighError: 'A nota do critério não pode ser maior que 5',
        exportAttemptsCSV: 'Exportar resoluções CSV',
        exportGrades: 'Exportar notas Excel',
        deleteAttemptUnavailable: 'Este aluno ainda não começou a avaliação.',
        deleteAttemptConfirm: (name) => `Tem a certeza que quer apagar a submissão de ${name}?`,
        deleteAttemptConfirmSubtitle: 'Esta ação não é reversível.',
        successDeleteAttempt: 'A submissão foi apagada com sucesso.',
        studentSearch: 'Pesquisar por alunos',
        noStudentsFound: 'Sem resultados',
        showModelAnswer: 'Resposta modelo: o estudante pode ver a resposta modelo ao rever a avaliação',
        notFinished: 'Não terminado',
        attemptDates: 'Início - Conclusão',
      },
      stats: {
        classAverage: 'Média',
        classMedian: 'Mediana',
        averageInfo: 'A média é calculada através da soma do resultado de todos os testes e posterior divisão pelo número total de testes',
        medianInfo: 'A mediana é o valor que se encontra no centro quando se ordenam os resultados dos testes do menor para o maior',
        positive: 'Positivas',
        negative: 'Negativas',
        highest: 'Nota mais alta',
        lowest: 'Nota mais baixa',
        question: 'Pergunta',
        sortOrder: 'Ordem: ',
        lowToHigh: 'Ascendente',
        highToLow: 'Descendente',
        avrgGrade: 'Nota média',
        avrgNormalized: 'Nota média normalizada',
        avrgNormalizedInfo: 'Ordenar as perguntas em ordem descendente ou ascendente pela nota média normalizada',
        avrgQuestionInfo: 'A nota média obtida nesta pergunta',
        premiumStats: 'Obtenha uma assinatura premium para ver estatísticas mais avançadas',
        bestQuestion: 'Pergunta com melhores respostas',
        worstQuestion: 'Pergunta com piores respostas',
        bestQuestionShort: 'Melhor pergunta',
        worstQuestionShort: 'Pior pergunta',
        noAnswer: 'Sem resposta',
        distributionChart: 'Número de resultados',
        choices: 'Escolha múltipla',
        ascSort: 'Clique para organizar por ordem ascendente',
        descSort: 'Clique para organizar por ordem descendente',
        cancelSort: 'Clique para cancelar a organização',
        fastestTime: 'Tempo mais rápido',
        slowestTime: 'Tempo mais lento',
        averageTime: 'Tempo médio',
        inProgress: 'Ainda a decorrer...',
        studentName: 'Nome do estudante',
        studentGrade: 'Classificação',
        studentRank: 'Posição',
        previewAnswer: 'Pré-visualizar resposta',
        studentCount: 'Número de estudantes',
        gradeCount: 'Número de resultados',
        gradeCountTip: 'Nos intervalos representados, como 0% a 10%, o limite inferior (0%) é contabilizado na barra e o limite superior não, com a exceção do último intervalo, 90% a 100%, que inclui ambos.',
      },
      physicalVersion: {
        physicalVersion: 'Versão Física',
        exportTest: 'Exportar avaliação',
        generalSettings: 'Configurações gerais',
        versionsLabel: 'Número de versões',
        versionsPlaceholder: 'Inserir o número de versões',
        localeLabel: 'Linguagem',
        shuffleExercisesLabel: 'Baralhar ordem dos exercícios',
        shuffleChoicesLabel: 'Baralhar ordem das escolhas dos exercícios de escolha múltipla',
        noExercises: 'O teste precisa de ter pelo menos um exercício para ser exportado',
        notEnoughExercises: 'Não existe exercícios suficientes para satisfazer o número de versões.',
        headerSettings: 'Configuração do cabeçalho',
        studentInput: 'Estudante a preencher',
        testName: 'Nome da avaliação',
        testNamePlaceholder: 'Insira aqui o nome da avaliação',
        testNameEmptyError: 'O nome da avaliação não pode estar vazio',
        displayVersion: 'Mostrar versão da avaliação',
        schoolName: 'Nome da escola',
        schoolNamePlaceholder: 'Insira aqui o nome da escola',
        schoolNameEmptyError: 'O nome da escola não pode estar vazio',
        schoolLogo: 'Logotipo da escola',
        schoolLogoPlaceholder: 'Arraste para aqui ou clique para inserir a imagem',
        schoolLogoEmptyError: 'Deve inserir uma imagem do logotipo da escola',
        subject: 'Disciplina',
        subjectPlaceholder: 'Insira aqui o nome da disciplina',
        subjectEmptyError: 'O nome da disciplina não pode estar vazio',
        testDuration: 'Duração da avaliação (em minutos)',
        testDurationPlaceholder: 'Insira aqui a duração da avaliação',
        testDurationEmptyError: 'A duração do teste não pode estar vazia',
        schoolYear: 'Ano escolar',
        schoolYearPlaceholder: 'Insira aqui o ano escolar',
        schoolYearEmptyError: 'O ano escolar não pode estar vazio',
        'class': 'Turma',
        classPlaceholder: 'Insira aqui a turma',
        classEmptyError: 'O nome da turma não pode estar vazio',
        date: 'Data da avaliação',
        datePlaceholder: 'Insira aqui a data da avaliação',
        dateEmptyError: 'A data da avaliação não pode estar vazia',
        exercisesSettings: 'Configurações de exercícios',
        includeZone: 'Incluir zona de resposta',
        askForName: 'Nome do aluno',
        askForCode: 'Código do aluno',
        askForGrade: 'Nota do aluno',
        extraFields: 'Campos extra',
        extraFieldButton: 'Adicionar campo novo',
        extraFieldLabel: 'Nome do campo:',
        extraFieldLabelPlaceholder: 'Insira aqui o nome do campo',
        customFieldEmptyLabelError: 'Deve inserir o nome do campo',
        extraFieldContent: 'Conteúdo do campo:',
        extraFieldContentPlaceholder: 'Insira aqui o conteúdo do campo',
        customFieldEmptyContentError: 'Deve inserir o conteúdo do campo',
        numberOfLines: 'Número de linhas para resposta',
        numberOfLinesJustify: 'Número de linhas para justificação',
        showLines: 'Mostrar linhas',
        includeRubric: 'Incluir rubrica nas versões exportadas',
        noRubric: 'Ainda não selecionaste nenhuma rubrica',
        sectionWithoutExercises: 'Esta secção não tem exercícios',
      },
      attemptsExport: {
        includeSchoolLogo: 'Incluir logotipo da escola',
        schoolLogoPlaceholder: 'Arraste para aqui ou clique para inserir a imagem',
        schoolLogoEmptyError: 'Deve inserir uma imagem do logotipo da escola',
        includeGrade: 'Incluir nota',
        includeRubric: 'Incluir rubrica',
        waitWhileGenerateDocuments: 'Aguarde enquanto geramos os documentos',
        clickBelowToDownloadDocuments: 'Clique abaixo para descarregar os documentos',
        exportAttemptsPDF: 'Exportar provas PDF',
        generatingPDFs: 'A gerar os PDFs',
        zippingPDFs: 'A criar o ficheiro zip',
        generateSuccessDescription: 'As provas foram exportadas para PDF com sucesso! Clique no botão abaixo para fazer download.',
        includeModelAnswer: 'Incluir resposta modelo',
        exportAttemptsNotAvailable: 'Não existem provas para exportar',
        downloadAttempts: 'Descarregar provas',
        downloadValidatedExplanation: 'Apenas as provas que foram validadas vão ser exportadas',
      },
      send: {
        sendTest: 'Enviar avaliação',
        keepCopy: 'Manter original e enviar uma cópia',
        successSendTest: 'A avaliação foi enviada com sucesso',
        errorSendTestGroup: 'Tem de escolher um grupo para mandar a avaliação.',
        errorSendTestSchool: 'Tem de escolher um espaço para mandar a avaliação.',
        errorSendTestToMySpace: 'Não pode enviar uma avaliação de outro utilizador para o seu espaço pessoal.',
      },
      settingsModal: {
        name: 'Nome',
        successEditSettings: 'As definições do teste foram atualizadas com sucesso',
      },
      rubric: {
        addRubric: 'Adicionar rubrica',
        selectRubricFromBank: 'Selecionar rubrica do banco',
        successSetRubric: 'A rubrica foi adicionada com sucesso',
        changeRubric: 'Alterar rubrica',
        noRubric: 'Ainda não há nenhuma rubrica associada a este teste',
        totalPonderations: 'Ponderação total dos critérios da rubrica',
        notEmpty: 'A ponderação do critério no exercício não pode estar vazia',
        negativeValueError: 'A ponderação do critério no exercício não pode ter um valor negativo',
        weightTooHigh: 'A ponderação do critério no exercício não pode estar acima de 100%',
        successUpdateCriteriaWeight: 'A ponderação do critério no exercício foi alterada com sucesso',
        errorTotal: 'O total das ponderações do exercício deve ser igual a 100%',
        createRubric: 'Criar nova rubrica',
        editRubric: 'Editar rubrica',
        successEditRubric: 'A rubrica foi alterada com sucesso',
      },
      exercises: {
        addSection: 'Adicionar secção',
        sectionNameLabel: 'Nome da secção',
        sectionDescriptionLabel: 'Descrição da secção',
        sectionNameRequiredError: 'O nome da secção é obrigatório',
        addSectionSuccessful: 'A secção foi adicionada ao teste com sucesso',
        editSectionSuccessful: 'A secção foi alterada com sucesso',
        sectionPresentation: 'Apresentação da secção',
        sectionNavigation: 'Navegação da secção',
        sectionTimerLabel: 'Tempo da secção em minutos (opcional)',
        sectionTimerWarning: 'O tempo de secção só funciona em testes incrementais',
        pauseItem: 'Item de pausa',
        addPauseItem: 'Adicionar item de pausa',
        addPauseItemStepHeader: 'Mensagem de pausa',
        addPauseItemPlaceholder: 'Escreva aqui a mensagem de pausa',
        successAddPauseItem: 'O item de pausa foi criado com sucesso',
        editPublishedExerciseWarning: 'Após a publicação da avaliação, algumas opções não poderão ser editadas e ao guardar as alterações, o exercício será automaticamente corrigido e quaisquer notas anteriores serão substituídas.',
        editPointsSuccess: 'Os pontos do exercício foram atualizados com sucesso',
        editPointsSuccessAfterPublish: 'Os pontos do exercício foram atualizados com sucesso. As notas podem demorar a atualizar',
        editWeightsSuccess: 'Os pesos dos critérios do exercício foram atualizados com sucesso',
        editWeightsSuccessAfterPublish: 'Os pesos dos critérios do exercício foram atualizados com sucesso. As notas podem demorar a atualizar',
      },
    },
    exerciseForm: {
      createExerciseOf: 'Criar exercício de',
      editExerciseOf: 'Editar exercício de',
      editExercise: 'Editar exercício',
      selectExerciseType: 'Selecione o tipo de exercício',
      typeChoices: 'Escolha múltipla',
      typeTrueFalse: 'Verdadeiro / Falso',
      typeText: 'Texto',
      typeImage: 'Submissão de imagens',
      typeFilling: 'Preencher o texto',
      typeConnecting: 'Conectar',
      typeOrdering: 'Ordenar',
      typeCaption: 'Legendar',
      typeSegmentation: 'Segmentos',
      typeTable: 'Tabela',
      typeSmartChoices: 'Escolha múltipla inteligente',
      newChoices: 'Novo exercício de escolha múltipla',
      newTrueFalse: 'Novo exercício de verdadeiro / falso',
      newText: 'Novo exercício de texto',
      newImage: 'Novo exercício de submissão de imagens',
      newSmartChoices: 'Novo exercício de escolha múltipla inteligente',
      editChoices: 'Editar exercício de escolha múltipla',
      editTrueFalse: 'Editar exercício de verdadeiro / falso',
      editText: 'Editar exercício de texto',
      editImage: 'Editar exercício de submissão de imagens',
      editSmartChoices: 'Editar exercício de escolha múltipla inteligente',
      discardTypeTitle: 'Descartar exercício',
      discardTypeContent: 'Se continuar, todas as adições serão perdidas',
      saveWarningContent: 'Ao guardar, o exercício será automaticamente corrigido e quaisquer notas anteriores serão substituídas.',
      statement: 'Enunciado',
      statementPlaceholder: 'Escreva aqui o enunciado',
      statementPlaceholderInformation: 'Escreva aqui a informação',
      noEmptyStatement: 'O enunciado não pode estar vazio',
      noEmptyFormula: 'Não é possível inserir uma fórmula vazia',
      noInvalidFormula: 'A fórmula parece estar inválida. Se tem espaços no inicio ou no fim, remova. Por favor corrija antes de continuar',
      trueFalse: 'Verdadeiro / falso',
      noEmptyTruth: 'É necessário definir um valor de verdade',
      choices: 'Escolhas',
      choicesTip: 'Coloque as diferentes opções e escolha a opção correta carregando no botão de verificação à esquerda da opção.',
      oneCorrectOption: 'Como publicou a avaliação com o exercício tendo apenas uma escolha correta, deve manter apenas uma escolha correta.',
      multipleCorrectOptions: 'Como publicou a avaliação com o exercício tendo mais que uma escolha correta, deve manter pelo menos duas opções corretas.',
      itemstoOrder: 'Itens a ordenar',
      itemsToOrderTip: 'Os itens devem estar ordenados na ordem que considera como correta. Os itens serão depois apresentados de forma aleatória para os estudantes.',
      option: 'Opção',
      addOption: 'Adicionar nova opção',
      removeOption: 'Remover opção',
      noEmptyOption: 'Não pode haver opções vazias',
      noDuplicateOptions: 'Não pode haver opções duplicadas',
      noAnswerChoiceDefined: 'É necessário definir a opção correta',
      editMultipleOptionFail: 'É necessário definir pelo menos duas opções corretas',
      item: 'Item',
      addItem: 'Adicionar novo item',
      removeItem: 'Remover item',
      noEmptyItem: 'Não pode haver itens vazios',
      noDuplicateItems: 'Não pode haver itens duplicados',
      noAnswerGapDefined: 'É necessário definir pelo menos uma palavra a esconder',
      noExtraWordDefined: 'É necessário definir pelo menos uma opção extra para cada palavra selecionada',
      previewAndSave: 'Pré-visualizar e guardar',
      saveExercise: 'Guardar exercício',
      successExerciseCreated: 'Exercício criado com sucesso',
      successExerciseEdited: 'Alterações guardadas com sucesso',
      successExerciseEditedAfterPublish: 'Alterações guardadas com sucesso. As notas podem demorar a atualizar',
      advancedMode: 'Modo LaTeX',
      formulaHeader: 'Adicionar fórmula',
      formulaLabel: 'Escreva aqui a tua fórmula',
      formulaReference: 'Referência de fórmulas suportadas',
      formulaSectionGeneral: 'Geral',
      formulaSectionMath: 'Matemática',
      videoHeader: 'Adicionar vídeo',
      youtube: 'Vídeo do Youtube',
      youtubeLink: 'Link do youtube',
      invalidUrl: 'Apenas são aceites links de videos do Youtube',
      noVideoChosen: 'Não inseriu nenhum link',
      myVideo: 'O meu vídeo',
      addVideoLabel: 'Clique para adicionar um vídeo',
      justificationLabel: 'Justificação',
      justificationText: 'Adicionar espaço para justificação',
      askForImage: 'Imagem',
      askForImageError: 'Tipo de ficheiro errado! Por favor insira um ficheiro de imagem',
      caption: 'Legenda',
      createCaptions: 'Crie as legendas',
      editCaptions: 'Edite as legendas',
      createCaptionsDescription: 'Clique na imagem para adicionar uma legenda. Pode arrastar as legendas para não obstruir a imagem.',
      editCaptionsDescription: 'Clique na legenda para editar o texto.',
      captionWarning: 'Aviso: Este exercício tem melhor experiência ao responder no computador.',
      shortAnswer: 'Resposta',
      shortAnswerText: 'Tornar em exercício de resposta curta',
      characterLimit: 'Limite de caracteres',
      characterLimitError: 'Especifique o limite de caracteres',
      extraText: 'Texto extra',
      extraTextPlaceholder: 'Insira aqui o texto extra',
      extraTextStartOpen: 'Começar totalmente expandido',
      identifiers: 'Identificadores',
      useMathSymbols: 'Utilizar símbolos matemáticos (Aluno)',
      wordCountLabel: 'Mostrar contagem de palavras (Aluno)',
      exerciseSettings: {
        label: 'Definições de exercício',
        title: 'Título do exercício',
        whoCanSee: 'Quem pode ver este exercício?',
        areas: 'Quais as áreas disciplinares relevantes para este exercício?',
        years: 'Em que anos escolares?',
        errorTitleRequired: 'É necessário escolher um título',
        errorSubjectRequired: 'É necessário escolher uma área',
        errorYearRequired: 'É necessário escolher um ano',
        'public': 'Público',
        publicTip: 'Vísivel na página "Explorar"',
        'private': 'Privado',
        privateTip: 'Visível apenas para si ou para os grupos com os quais partilhar o exercício',
      },
      gap: {
        choosingGaps: 'Escolher as palavras a esconder',
        choosingGapsSub: 'Selecione as palavras que pretende esconder dos alunos',
        noGaps: 'Ainda não existem palavras no enunciado',
        choosingGapExtraOption: 'Opções de exercício (lado do aluno)',
        dragDropOption: 'Mostrar a lista de palavras escondidas (arrastar)',
        dropdownOption: 'Mostrar a lista de opções para cada palavra (escolher)',
        writeOption: 'Não mostrar a lista de palavras (escrever)',
        example: 'Exemplo',
        write: 'Escrever...',
        correctionAuto: 'Correção automática pela plataforma',
        correctionManual: 'Correção feita pelo professor',
        askForExtraGapsButton: 'Guardar palavras extra',
        addExtraGaps: 'Adicionar palavras extra',
        editExtraGaps: 'Editar palavras extra',
        addExtraGapsSub: 'Escrever palavras extra para adicionar dificuldade a este exercício',
        editExtraGapsSub: 'Editar palavras extra para adicionar dificuldade a este exercício',
        addGapOptions: 'Adicionar opções para a palavra: ',
        editGapOptions: 'Editar opções para a palavra: ',
        word: 'Palavra',
        orderGaps: 'Ordenar palavras',
        noExtraGaps: 'Não existem palavras extra para editar',
      },
      choice: {
        shuffleChoices: 'Baralhar escolhas múltiplas',
      },
      connecting: {
        shuffleConnectors: 'Baralhar conectores',
        connectors: 'Conectores',
        connector: 'Conector',
        connections: 'Conexões',
        addConnector: 'Adicionar conector',
        noEmptyConnector: 'Não pode existir conectores vazios',
        noConnections: 'É obrigatório existir pelo menos uma conexão',
        noConnectors: 'É obrigatório existirem pelo menos dois conectores',
        noConnectorsLeft: 'É obrigatório existir pelo menos um conector na coluna da esquerda',
        noConnectorsRight: 'É obrigatório existir pelo menos um conector na coluna da direita',
      },
      segmentation: {
        createConnectorsAndConnections: 'Criar conectores e conexões',
        createConnectorsAndConnectionsDescription: 'Clique na imagem para adicionar um conector. Pode adicionar conexões clicando nos dois conectores que pretende conectar.',
        showOnlyLine: 'Mostrar apenas linha',
      },
      table: {
        tableGrading: 'Opções de correção',
        gradeByRow: 'Por linha',
        gradeByTable: 'Por tabela',
        gradeByRowTip: 'O exercício será corrigido por linha, o que significa que o aluno deve responder corretamente em todas as colunas da linha, selecionando-a ou não, caso contrário, a linha é considerada incorreta.',
        gradeByTableTip: 'O exercício será corrigido por tabela, o que significa que cada célula deve ser respondida corretamente, caso contrário, o exercício é considerado incorreto.',
        noEmptyHeaderCell: 'Não podem existir células de cabeçalho principais vazias',
        headerPlaceholder: 'Cabeçalho',
        rowGradingExplanation: 'Corrigido por linha',
        tableGradingExplanation: 'Corrigido por tabela',
      },
      audio: {
        audioHeader: 'Adicionar áudio',
        addAudioLabel: 'Clique para adicionar um áudio',
        askForAudioError: 'Tipo de ficheiro errado! Por favor insira um ficheiro de áudio',
        noAudioChosen: 'Não escolheu nenhum ficheiro de áudio',
        canPauseLabel: 'O estudante pode parar a reprodução do áudio',
        canPause: 'Pode parar',
        cannotPause: 'Não pode parar',
        limitRepetitionsLabel: 'Limitar a quantidade de vezes que o estudante pode ouvir o áudio',
        maxRepetitionsLabel: 'Repetições máximas',
        repetitions: 'repetições',
        retryMessage: 'Ocorreu um erro. Volta a tentar reproduzir o audio',
      },
      video: {
        audioHeader: 'Adicionar vídeo',
        addAudioLabel: 'Clique para adicionar um vídeo',
        askForAudioError: 'Tipo de ficheiro errado! Por favor insira um ficheiro de vídeo',
        noAudioChosen: 'Não escolheu nenhum ficheiro de vídeo',
        canPauseLabel: 'O estudante pode parar a reprodução do vídeo',
        canPause: 'Pode parar',
        cannotPause: 'Não pode parar',
        limitRepetitionsLabel: 'Limitar a quantidade de vezes que o estudante pode ver o vídeo',
        maxRepetitionsLabel: 'Repetições máximas',
        repetitions: 'repetições',
      },
      modelAnswer: 'Resposta Modelo',
      modelAnswerPlaceholder: 'Insira aqui a resposta modelo',
    },
    exercises: {
      title: 'Banco de exercícios',
      subtitle: 'Todos os exercícios criados',
      createNewExercise: 'Criar exercício',
      createNewExerciseSubtitle: 'Aqui iremos criar um novo exercício',
      cancelCreation: 'Cancelar criação',
      cancelEdit: 'Cancelar edição',
      deleteExercise: 'Apagar exercício',
      deleteExercises: 'Apagar exercícios',
      deleteExerciseInfo: 'Tem a certeza que quer apagar este exercício?',
      deleteExercisesInfo: 'Tem a certeza que quer apagar estes exercícios?',
      deleteExerciseInfo2: 'Esta ação não é reversível',
      successDeleteExercise: 'O exercício foi removido com sucesso',
      successDeleteExercises: 'Os exercícios foram removidos com sucesso',
      successDuplicateExercise: 'O exercício foi duplicado com sucesso',
      deleteErrorDoesNotExist: 'Não foi possivel remover o exercício porque ele já não existe',
      creatingNewExerciseOn: 'A criar novo exercício em',
      editingExercise: 'A editar exercício em',
      previewExercise: 'Pré-visualizar exercício',
      previewErrorDoesNotExist: 'Não foi possível carregar a pré-visualização porque o exercício já não existe',
      noExercises: 'Ainda não existe nenhum exercício criado.',
      noExercisesForFilters: 'Não existem exercícios para os filtros selecionados.',
      gapList: 'Lista de palavras/expressões:',
      gapListUnused: 'Lista de palavras/expressões não utilizadas:',
      labels: {
        addLabel: 'Adicionar etiqueta',
        createLabel: 'Criar etiqueta',
        repeatedLabelError: 'Já existe uma etiqueta com esse nome',
        labelName: 'Nome da etiqueta',
        errorNoColorSelected: 'É obrigatório selecionar uma cor',
        errorNameRequired: 'É obrigatório preencher o nome',
        labels: 'Etiquetas',
        editLabels: 'Editar etiquetas',
        successEditLabel: 'A etiqueta foi editada com sucesso',
        successDeleteLabel: 'A etiqueta foi eliminada com sucesso',
      },
      filters: 'Filtros',
      teacherFilter: 'Professores',
      typeFilter: 'Tipo de exercícios',
      order: 'Ordenar',
      orderNewest: 'Mais recente',
      orderOldest: 'Mais antigo',
      orderLastModified: 'Último modificado',
      send: {
        sendExercise: 'Enviar exercício',
        sendExercises: 'Enviar exercícios',
        keepCopy: 'Manter original e enviar uma cópia',
        sendLabels: 'Enviar as etiquetas para o outro grupo',
        successSendExercise: 'O exercício foi enviado com sucesso',
        successSendExercises: 'O exercícios foram enviados com sucesso',
        errorSendExerciseGroup: 'Tem de escolher um grupo para enviar o exercício.',
        errorSendExercisesGroup: 'Tem de escolher um grupo para enviar os exercícios.',
        errorSendExercisesSchool: 'Tem de escolher um espaço para enviar os exercícios.',
        errorSendExercisesToMySpace: 'Não pode enviar exercícios de outros utilizadores para o seu espaço pessoal.',
      },
      modelAnswer: 'Resposta modelo',
    },
    attempt: {
      nextExercise: 'Próximo exercício',
      nextItem: 'Próximo',
      previousExercise: 'Exercício anterior',
      previousItem: 'Anterior',
      finishedAttempt: 'Terminado. Pode fechar a janela.',
      finishedAttemptIave: 'Prova terminada. Aguarda instruções do professor vigilante.',
      characterLimitReached: 'Número máximo de caracteres atingido',
      timesUp: 'O tempo da secção acabou',
      unsavedAnswers: 'Ocorreu um erro ao gravar a(s) sua(s) resposta(s). Verifique a sua ligação à internet e tente gravar',
      unsavedAnswersFinish: 'Tem respostas que não foram gravadas. Tem a certeza que quer terminar?',
      answersSaved: 'As respostas foram gravadas com sucesso',
      errorSaveAnswers: 'Ocorreu um erro ao guardar as respostas',
      exitPinModal: {
        pinLabel: 'Por favor insira o pin de saída do exame disponibilizado pelo supervisor',
        pinPlaceholder: 'Pin',
        modalHeader: 'Pin de saída de exame',
        wrongPin: 'O pin não está correto',
        emptyPin: 'Deve inserir um pin para proceder',
      },
      continueWithoutSaving: 'Continuar sem gravar',
      saveAndContinue: 'Gravar e continuar',
      status: 'Estado',
      statusValid: 'Válido',
      statusInvalid: 'Inválido',
      statusPending: 'Pendente',
      statusUpdated: 'Atualizado com sucesso',
    },
    groups: {
      title: 'Grupos',
      teachers: 'professores',
      teacher: 'professor',
      createNewGroup: 'Criar grupo',
      groupName: 'Nome do grupo',
      successCreateGroup: 'O grupo foi criado com sucesso',
      hintTargets: 'Este campo define quais os professores que vão ser adicionados ao grupo',
      errorNameRequired: 'É obrigatório preencher o nome',
      noGroups: 'Ainda não tens grupos? Crie um e comece a colaborar com outros professores!',
      successEditGroup: 'O grupo foi editado com sucesso',
      userNotAllowed: 'O utilizador já excedeu o limite de grupos em que pode estar',
    },
    group: {
      title: 'Grupo',
      leaveGroup: 'Sair do grupo',
      leaveGroupInfo: 'Tem a certeza que quer sair do grupo?',
      leaveGroupInfo2: 'Esta ação não é reversível e vai perder o acesso a todos conteúdos do grupo.',
      successLeaveGroup: 'Saíu do grupo com sucesso',
      removeTeacher: 'Remover professor?',
      removeTeacherInfo: 'Tem a certeza que quer remover este professor?',
      successRemoveTeacher: 'O professor foi removido com sucesso',
      successAddTeacher: 'Os professores foram adicionados com sucesso',
      addTeacher: 'Adicionar professores',
      hintTargets: 'Insira os e-mails dos professores que quer adicionar ao grupo',
      hintTargetsCreatable: 'Insira os e-mails dos professores que quer adicionar ao grupo. Pode adicionar e-mails de professores que não estejam na Intuitivo para os convidar.',
      groupSettings: 'Definições do grupo',
      deleteGroup: 'Eliminar grupo',
      deleteGroups: 'Eliminar grupos',
      deleteGroupInfo: 'Tem a certeza que quer eliminar este grupo? Depois não poderá recuperar os conteúdos. Esta ação não é reversível.',
      deleteGroupsInfo: 'Tem a certeza que quer eliminar estes grupos? Depois não poderá recuperar os conteúdos. Esta ação não é reversível.',
      successDeleteGroup: 'O grupo foi eliminado com sucesso',
      removeSelected: 'Remover selecionados',
    },
    publication: {
      password: 'Password do teste',
      testWindowClosedInfo: 'A tolerância de abertura do teste terminou.',
      studentName: 'Nome de estudante',
      errorNameRequired: 'É obrigatório preencher o nome.',
      errorEmail: 'O e-mail inserido não é válido.',
      passwordRequired: 'É obrigatório preencher a password.',
      emailHint: 'O e-mail inserido será utilizado para depois enviar a nota e correção mal estas sejam publicadas.',
      continueAsGuest: 'Continuar como convidado',
      continueAs: (name) => `Continuar como ${name}`,
      notYou: 'Não é o seu nome?',
      enterAsNewStudent: 'Entre como um novo estudante',
    },
    rubrics: {
      title: 'Rubricas',
      noRubrics: 'Ainda não existe nenhuma rubrica criada',
      previewRubric: 'Visualizar rubrica',
      performanceLevels: 'Níveis de desempenho',
      createRubric: 'Criar rubrica',
      editRubric: 'Editar rubrica',
      deleteRubric: 'Eliminar rubrica',
      deleteRubrics: 'Eliminar rubricas',
      deleteRubricInformation: 'Tem a certeza que quer eliminar esta rubrica? Esta ação não é reversível.',
      deleteRubricsInformation: 'Tem a certeza que quer eliminar estas rubricas? Esta ação não é reversível.',
      successDeleteRubric: 'A rubrica foi eliminada com sucesso',
      successDeleteRubrics: 'As rubricas foram eliminadas com sucesso',
      successDuplicateRubric: 'A rubrica foi duplicada com sucesso',
      form: {
        rubricNameLabel: 'Nome da rubrica',
        rubricDescriptionLabel: 'Descrição da rubrica',
        criteriaNameLabel: 'Nome do critério',
        criteriaDescriptionLabel: 'Descrição do critério',
        performanceLevelDescriptionLabel: 'Descrição do nível de desempenho',
        addCriteria: 'Adicionar critério',
        discardRubric: 'Descartar rubrica',
        nameRequiredError: 'É necessário inserir um nome para a rubrica.',
        criteriaNameRequiredError: 'É necessário inserir um nome para o critério.',
        criteriaRequiredError: 'É necessário criar pelo menos um critério para esta rubrica.',
        createRubricSuccess: 'A rubrica foi criada com sucesso',
        editRubricSuccess: 'A rubrica foi atualizada com sucesso',
      },
      send: {
        sendRubric: 'Enviar rubrica',
        sendRubrics: 'Enviar rubricas',
        keepCopy: 'Manter original e enviar uma cópia',
        successSendRubric: 'A rubrica foi enviada com sucesso',
        successSendRubrics: 'As rubricas foram enviadas com sucesso',
        errorSendRubricGroup: 'Tem de escolher um grupo para enviar a rubrica.',
        errorSendRubricsGroup: 'Tem de escolher um grupo para enviar as rubricas.',
        errorSendRubricsSchool: 'Tem de escolher um espaço para enviar as rubricas.',
        errorSendRubricsToMySpace: 'Não pode enviar rubricas de outros utilizadores para o seu espaço pessoal.',
      },
    },
    explore: {
      title: 'Explorar',
      header: 'Explore os conteúdos disponíveis',
      headerXs: 'Explorar',
      contentsListHeader: 'Conteúdos mais utilizados, consoante os filtros selecionados',
      contentsListHeaderLg: 'Conteúdos mais utilizados',
      contentsListHeaderXs: 'Conteúdos',
      noResultsHeader: 'Oops... Não encontramos nenhum conteúdo que corresponda à sua pesquisa :( ',
      noResultsDescription: 'Tente pesquisar por um termo mais geral, mude os filtros ou verifique se há alguma gralha nas palavras pesquisadas.',
      noResultsDescription2: 'Se essas dicas não funcionarem, convidamo-lo a criar novo conteúdo.',
      createNewExercise: 'Criar exercício',
      endMessage: 'Já viu todos os conteúdos que correspondem à sua pesquisa.',
      scrollBackToTopMessage: 'Voltar ao topo da página',
      searchPlaceholder: 'Pesquisar conteúdos na Intuitivo...',
      addFilter: 'Adicionar filtro',
      subjectAreas: 'Áreas disciplinares',
      schoolYears: 'Anos escolares',
      numberOfUses: 'Utilizações',
      copyInformation: 'Para que grupo gostaria de copiar este exercício?',
      successCopyExercise: 'O exercício foi copiado para o seu grupo com sucesso',
      infoTip: 'Preencha as suas preferências na página do seu perfil para uma experiência mais personalizada',
      searchAll: 'Todos os conteúdos',
    },
    profilePreferences: {
      setProfilePreferences: 'Antes de continuar, ajude-nos a melhorar a sua experiência',
      saveProfilePreferences: 'As preferências foram guardadas com sucesso',
      warningProfilePreferences: 'Para alterar as suas preferências vá à página "Conta"',
      subjects: 'Que áreas disciplinares leciona?',
      years: 'Em que anos escolares?',
    },
    login: {
      loginWithMicrosoft: 'Iniciar sessão com a Microsoft',
      poweredBy: 'Powered by',
    },
    tours: {
      tip: 'Guias',
      title: 'Precisa de ajuda?',
      startTour: 'Começar',
      subtitles: [
        'Aqui poderá encontrar vários guias que o irão ajudar a interagir com a Intuitivo.',
        'Para começar, basta selecionar um dos guias disponíveis abaixo.',
      ],
      back: 'Voltar atrás',
      next: 'Próximo',
      complete: 'Terminar',
      introduction: {
        title: 'Introdução',
        subtitle: 'Aprenda os básicos da Intuitivo.',
        steps: {
          introduction: 'Bem vindo à Intuitivo! Preparamos um guia para o ajudar a começar a usar a plataforma. Para continuar basta carregar no botão "Próximo". Se está familiarizado com a Intuitivo, pode fechar esta mensagem.',
          testCard: 'Para o ajudarmos na utilização da Intuitivo, criámos uma avaliação para explorar.',
          testCardPublishButton: 'As avaliações já publicadas estão identificadas com este ícone a verde. Caso ainda não tenha sido publicada, estará a cinzento escuro.',
          testCardLeft: 'Veja mais detalhes desta avaliação clicando no cartão.',
          tabExercises: 'Neste momento está na aba de "Exercícios". Aqui pode ver os exercícios que constituem a avaliação.',
          tabGrade: 'Para este guia, a avaliação já foi publicada e obteve 2 submissões. Selecione a aba "Correção" para ver as submissões dos alunos.',
          statistics: 'Aqui pode ver estatísticas gerais da correção da avaliação.',
          attempts: 'Nesta secção pode ver quais os alunos que submeteram a avaliação, assim como a sua nota. Ao clicar num nome, poderá ver a sua resolução.',
          attemptContainer: 'Esta secção contém toda a informação relativa a uma resolução individual.',
          attemptHeader: 'O cabeçalho de cada resolução contém informação do aluno, bem como a sua nota final e link de submissão.',
          multipleChoice: 'Nesta secção consegue reparar que o aluno respondeu corretamente a este exercício de "Escolha Múltipla".',
          multipleChoiceGrader: 'A Intuitivo é capaz de corrigir alguns tipos de exercícios automaticamente, como é o caso do tipo "Escolha Múltipla". Neste exercício o aluno obteve a pontuação máxima, mas pode editar livremente este valor.',
          openQuestionGrader: 'No entanto, para perguntas de resposta aberta terá que fazer a correção manual e atribuir a pontuação.',
          openQuestionFeedback: 'Pode também dar feedback a qualquer resposta de um aluno, mesmo tendo correção automática.',
          publishGradesButton: 'Assim que tiver corrigido a avaliação pode publicar as correções. Os alunos que tenham fornecido o seu endereço de email ao começar a avaliação irão receber a sua nota e correção.',
          tabStatistics: 'Na aba "Estatísticas" tem acesso a mais informação detalhada da avaliação e desempenho dos seus alunos.',
          assessmentSidebarOption: 'Vamos voltar à pagina de Avaliações.',
          testCardDropdown: 'Assim que acabar de explorar esta avaliação, pode apagá-la a qualquer momento clicando neste menu e escolhendo a opção "Apagar".',
          hub: 'Pode repetir este guia a qualquer altura na secção de guias. Recomendamos que explore esta secção caso precise de ajuda a criar avaliações e exercícios.',
        },
      },
      assessments: {
        title: 'Avaliações',
        subtitle: 'Aprenda a criar e publicar avaliações',
        steps: {
          sidebarGoToPage: 'Para começar, vá à página de "Avaliações".',
          sidebar: 'Pode aceder às avaliações na página de "Avaliações".',
          createButton: 'Para criar uma avaliação, clique no botão "Criar Avaliação".',
          nameInput: 'Dê um nome à sua avaliação, algo como "A minha avaliação".',
          typeInput: `Escolha o tipo de avaliação:
              <ul>
                <li><strong>Teste:</strong> Tem tempo limite e está agendado para uma data específica.</li>
                <li><strong>Ficha:</strong> Acessível até uma data de submissão.</li>
              </ul>
              Vamos deixar a opção pré-definida "Teste".`,
          classificationInput: `Escolha o tipo de classificação:
              <ul>
                <li><strong>Clássico:</strong> As submissões dos alunos são corrigidas e atribuídas uma nota</li>
                <li><strong>Rubrica:</strong> Tem um critério de correção específico (Pode aprender mais sobre rubricas no guia "Rubricas").</li>
                <li><strong>Sem classificação:</strong> As submissões dos alunos não ficam com uma nota atribuída</li>
              </ul>
              Vamos deixar a opção pré-definida "Clássico".`,
          groupInput: 'Pode guardar esta avaliação em qualquer grupo a que pertença. (Pode aprender mais sobre grupos no guia "Grupos").',
          submitButton: 'Complete a criação desta avaliação clicando em "Criar".',
          exercisesTab: 'Neste momento está na aba de "Exercícios". Aqui pode adicionar conteúdo à sua avaliação, como exercícios, secções ou blocos de informação.',
          gradesTab: 'Quando publicar o seu teste e este for respondido pelos seus alunos, irá conseguir ver as suas resoluções na aba "Correção".',
          physicalTab: 'Na aba "Versão Física", poderá exportar a avaliação para um documento PDF.',
          contentForm: 'Nesta secção poderá adicionar conteúdo à sua avaliação.',
          createNewExercise: 'Vamos fazer exatamente isso. Carregue em "Criar um novo exercício".',
          exerciseType: 'Pode escolher que tipo de exercício quer criar.',
          trueOrFalseExerciseType: 'Neste caso, escolha a opção "Verdadeiro/Falso".',
          exerciseStatement: 'Escreva o enunciado do exercício, algo como "Os golfinhos são mamíferos?"',
          exerciseCorrectAnswer: 'Nesta secção pode escolher a opção correta.',
          exerciseTrue: 'Neste caso, escolha a opção "Verdadeiro".',
          exerciseSubmit: 'Está pronto. Este exercício também será adicionado à sua biblioteca de exercícios. (Pode aprender mais sobre exercícios no guia "Exercícios"). Pode agora clicar em "Confirmar".',
          exerciseGrade: 'Pode editar a cotação de um exercício específico.',
          exerciseEdit: 'Pode editar um exercício a qualquer altura clicando no botão de editar.',
          exerciseDelete: 'Um exercício pode também ser apagado de uma avaliação.',
          publishButton: 'O seu teste está pronto para ser publicado. Clique no botão "Publicar".',
          publishFormFormat: 'Pode escolher se quer apresentar os exercícios todos juntos, ou um de cada vez.',
          publishFormStart: 'Escolha uma data de início para este teste.',
          publishFormTolerance: 'Escolha o número total (em minutos) que um aluno pode começar o teste depois da data de início.',
          publishFormDuration: 'Escolha o tempo total (em minutos) que os alunos têm para completar o teste.',
          publishFormPassword: 'Pode proteger o seu teste com uma palavra-chave que terá que ser introduzida pelos alunos para darem início ao teste.',
          publishFormShuffle: 'Pode escolher baralhar a ordem das perguntas, deste modo os seus alunos terão testes diferentes.',
          publishFormFeedback: 'Pode também permitir que os seus alunos tenham acesso à correção do teste após o submeterem.',
          publishFormModelAnswer: 'Se a opção the feedback imediato estiver escolhida, pode permitir que os alunos tenham acesso às respostas modelo após submeterem o teste.',
          publishFormSubmit: 'Está pronto, pode agora publicar o teste clicando em "Publicar".',
          publishLink: 'Parabéns, o seu teste foi publicado. Agora basta partilhar este link com os seus alunos para que eles consigam realizar o teste.',
          publishCloseModal: 'Pode fechar esta janela.',
          publishButtonFinal: 'Pode ver a janela anterior a qualquer altura clicando neste botão.',
          hub: 'Este e outros guias estão sempre disponíveis para consulta na secção de "Guias".',
        },
      },
      explore: {
        title: 'Banco Público',
        subtitle: 'Aprenda como usar recursos partilhados por outros Professores',
        steps: {
          sidebarExplore: 'Para começar, vá à página de "Explorar".',
          sidebar: 'Pode aceder ao banco público na página "Explorar".',
          exerciseList: 'Nesta página poderá encontrar todos os exercícios e avaliações criadas e partilhadas pela comunidade de professores e escolas que usam a Intuitivo. Para contribuir, basta marcar o seu conteúdo como público durante a criação de um exercício ou avaliação.',
          header: 'Nesta secção tem acesso a uma ferramenta de pesquisa para conteúdos do banco público.',
          headerTextInput: 'Pode pesquisar por palavras chave.',
          headerContentDropdown: 'Pode filtrar por tipo de conteúdo: Avaliações, Exercícios ou ambos.',
          headerFilters: 'Pode também adicionar filtros por Área Disciplinar ou Ano de Escolaridade. Por defeito, estão selecionados os filtros relativos às suas preferências (Pode alterá-las nas definições da sua conta).',
          exerciseCardClick: 'Clique no seguinte conteúdo para ter uma vista detalhada.',
          exerciseCardPreview: 'Aqui pode ver em detalhe a estrutura do conteúdo.',
          exerciseCardPreviewCopy: 'Para adicionar este conteúdo ao seu banco privado basta clicar no botão "Copiar".',
          exerciseCardPreviewSelectGroup: 'De seguida necessita de escolher o grupo de destino.',
          exerciseCardPreviewSubmit: 'E finalmente clicar em "Confirmar".',
          exerciseCardClosed: 'Agora pode ver e editar este conteúdo na página de "Avaliações" ou "Exercícios", dependendo do tipo.',
          hub: 'Este e outros guias estão sempre disponíveis para consulta na secção de "Guias".',
        },
      },
      groups: {
        title: 'Grupos',
        subtitle: 'Aprenda a criar e utilizar grupos para colaborar com os seus colegas',
        steps: {
          sidebarGoToPage: 'Para começar, vá à página de "Grupos".',
          sidebar: 'Pode aceder aos grupos na página de "Grupos".',
          createNewGroup: 'Para criar um grupo basta clicar no botão "Criar Grupo".',
          formNameInput: 'Dê um nome a este grupo, por exemplo "Professores de Matemática 3º Ciclo".',
          formTeachersInput: 'Nesta secção poderá adicionar os seus colegas que também usam a Intuitivo, através dos seus endereços de email. No entanto, para efeitos deste guia poderá deixar este campo vazio',
          formConfirm: 'Termine a criação do grupo clicando em "Confirmar"',
          groupCard: 'Cada grupo será um cartão com o nome do mesmo. Clique no cartão para ter uma vista detalhada.',
          teachersTable: 'Nesta tabela estão presentes o nome e endereço de email de cada participante do grupo.',
          addTeacherButton: 'Para adicionar novos membros pode clicar neste botão, que irá apresentar uma formulário para adicionar o endereço de email de cada professor.',
          groupShortcuts: `
            <p>
              Neste cartão pode encontrar atalhos para as páginas de "Avaliações", "Exercícios" e "Rubricas" pertencentes a este grupo.
              Em cada uma destas páginas irá encontrar um selecionador para navegar entre grupos, como ilustra a imagem:
            </p>
            <img style="width: 100%" src="${process.env.REACT_APP_S3_URL}/resources/groups-header-shortcut-pt.png" style="width: 100%">
          `,
          leaveGroup: 'Para sair de um grupo basta clicar neste menu e escolher a opção "Sair do Grupo".',
          hub: 'Este e outros guias estão sempre disponíveis para consulta na secção de "Guias".',
        },
      },
      rubrics: {
        title: 'Rubricas',
        subtitle: 'Aprenda a criar rubricas e a utilizá-las nas avaliações',
        steps: {
          sidebarGoToPage: 'Para começar, vá à página de "Rubricas".',
          sidebar: 'Pode aceder às rubricas na página "Rubricas".',
          createNewButton: 'Para criar uma rubrica clique no botão "Criar rubrica".',
          nameInput: 'Dê um nome à rubrica, por exemplo "Critérios de Avaliação - História 12º ano".',
          descriptionInput: 'Pode escrever uma descrição de rubrica para adicionar mais contexto.',
          criteriaCard: 'Nesta secção pode adicionar todos os critérios que achar necessários. Os niveis dos critérios vão de 1 a 5.',
          criteria0NameInput: 'Dê o nome "Conteúdos" a este critério.',
          criteria0ValueInput: `
            De seguida pode adicionar descritores de desempenho a este rubrica. Por exemplo:
            <ul>
              <li><strong>(5):</strong> Explica, de forma completa, o pretendido;</li>
              <li><strong>(4):</strong> Explica, de forma completa, apenas parte do pretendido;</li>
              <li><strong>(3):</strong> Explica, de forma incompleta, o pretendido;</li>
              <li><strong>(2):</strong> Explica, de forma incompleta, grande parte do pretendido;</li>
              <li><strong>(1):</strong> Explica, de forma incompleta, apenas parte do pretendido;</li>
            </ul>`,
          addCriteriaButton: 'Adicione um novo critério clicando neste botão.',
          criteria1NameInput: 'Dê o nome "Comunicação" a este critério.',
          criteria1ValueInput: 'Semelhante ao critério anterior, pode adicionar os descritores de desempenho que achar indicado. No entanto, para efeitos deste guia pode passar este passo.',
          criteria1RemoveButton: `Caso pretenda eliminar um critério, basta clicar no icon
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" class="svg-inline--fa fa-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path>
            </svg>`,
          exerciseExample: `
            <p>
              Durante a correção de uma avaliação do tipo rubrica, a avaliação de cada pergunta será feita por critérios.
              Assim pode atribuir o peso de cada critério a cada exercício, como ilustra a seguinte imagem:
            </p>
            <img src="${process.env.REACT_APP_S3_URL}/resources/exercise-rubric-example-pt.png" style="width: 100%">
            <p>Caso necessite, tem os guias "Avaliações" e "Introdução", que demonstram os processos de criação, publicação e correção de uma avaliação.</p>
          `,
          groupDropdown: 'Como em outros exemplos, pode sempre escolher o grupo de destino de uma rubrica. O guia "Grupos" contém mais informações sobre esta funcionalidade.',
          saveButton: 'Termine a criação da rubrica clicando no botão "Gravar"',
          card: 'Uma rubrica criada irá aparecer da seguinte forma.',
          cardActions: `
            Nesta secção do cartão da rubrica pode:
            <ul>
              <li>Pré-visualizar;</li>
              <li>Editar;</li>
              <li>Enviar para outro grupo;</li>
              <li>Duplicar;</li>
              <li>Apagar;</li>
            </ul>
          `,
          hub: 'Este e outros guias estão sempre disponíveis para consulta na secção de "Guias".',
        },
      },
      exercises: {
        title: 'Exercícios',
        subtitle: 'Aprenda a criar e usar exercícios',
        steps: {
          sidebarGoToPage: 'Para começar, vá à página de "Exercícios".',
          sidebar: 'Pode aceder aos exercícios na página de "Exercícios".',
          createExerciseButton: 'Para criar um exercício, carregue no botão "Criar Exercício".',
          createExerciseType: 'Existem vários tipos de exercícios que tem à sua disposição. Recomendamos que dedique algum tempo a explorar os diferentes tipos de exercícios. Caso necessite, pode assistir aos nossos <a href="https://intuitivo.pt/suporte/tutoriais" target="_blank">Tutoriais</a>.',
          createExerciseTypeText: 'Para efeitos deste guia, crie um simples exercício de texto.',
          editorHeaderTypeDropdown: 'O primeiro passo para a criação de um exercício está completo. No entanto, se preferir alterar o tipo de exercício pode fazê-lo nesta área.',
          editorHeaderGroupDropdown: 'Da mesma forma, pode escolher o grupo de destino deste exercício. Para mais informações sobre grupos, tem disponível o guia "Grupos" na secção de guias.',
          editorStatement: `
            Escreva o enunciado deste exercício. Pode adicionar conteúdos multimédia como imagens, vídeos e áudios. Também tem acesso a um editor de fórmulas matemáticas.
            <br/>
            Para este caso, pode escrever algo simples como <i>"Quem foi o primeiro rei de Portugal?"</i>
          `,
          editorShortAnswer: 'Dependendo do tipo de exercício, terá opções diferentes de customização. Para exercícios de texto pode limitar o número de caracteres da resposta, e permitir ao aluno acesso ao editor de fórmulas matemáticas.',
          editorExtraText: 'Em todos os tipos exercícios, pode adicionar texto extra ao exercício que estará disponível depois do campo de resposta do aluno.',
          editorModelAnswer: 'Dependendo do tipo de exercício, tem a opção adicionar uma resposta modelo ao exercício, que não será apresentada ao estudante durante a avaliação mas pode ser opcionalmente apresentada depois da avaliação terminar.',
          editorPreview: 'Terá sempre uma pré-visualização do exercício. Isto será a versão que os alunos irão ver durante a realização de uma avaliação.',
          editorSettingsFormTitle: `
            Para terminar a criação do exercício, terá que lhe dar um nome identificativo.
            <br/>
            Que tal algo como <i>"O primeiro rei de Portugal"</i>?
          `,
          editorSettingsFormVisibility: `
            De seguida pode escolher se quer manter o seu exercício público ou privado:
            <ul>
              <li><strong>Público:</strong> o exercício estará disponível na página <i>"Explorar"</i>, e poderá ser copiado e utilizado por outros professores.</li>
              <li><strong>Privado:</strong> o exercício apenas estará visível para si e para membros do grupo onde guardar este exercício.</li>
            </ul>
            Para mais informações sobre a página <i>"Explorar"</i> e <i>"Grupos"</i> tem à sua disposição guias sobre essas funcionalidades.
          `,
          editorSettingsFormVisibilityPrivate: 'Para este caso, vamos manter o exercício privado. Selecione a opção <i>"Privado"</i>.',
          editorLabelsButton: 'Se achar necessário, pode criar e adicionar etiquetas para catalogar este exercício. Este esforço irá facilitar a filtragem e pesquisa de exercícios.',
          editorHeaderSaveExercise: 'Finalmente, termine a criação de exercícios clicando no botão "Gravar".',
          exerciseCard: 'Um exercício criado irá aparecer da seguinte forma.',
          exerciseCardActions: `
            Nesta secção do cartão do exercício pode.
            <ul>
              <li>Pré-visualizar o exercício;</li>
              <li>Editá-lo;</li>
              <li>Enviar para outro grupo;</li>
              <li>Duplicá-lo;</li>
              <li>Apagá-lo;</li>
            </ul>
          `,
          exerciseFilters: `
            Terá sempre a possibilidade de filtrar exercícios por:
            <ul>
              <li>Professor que o criou;</li>
              <li>Tipo;</li>
              <li>Etiquetas;</li>
            </ul>
            E ordenar por:
            <ul>
              <li>Mais recente;</li>
              <li>Mais antigo;</li>
              <li>Último modificado;</li>
            </ul>
          `,
          hub: 'Este e outros guias estão sempre disponíveis para consulta na secção de "Guias".',
        },
      },
    },
    plans: {
      premium: 'Premium',
      free: 'Básico',
      exceededTests: (limit) => `Excedeu o limite de ${limit} ${limit === 1 ? 'avaliação' : 'avaliações'} ${limit === 1 ? 'gratuita' : 'gratuitas'} criadas por si, englobando todos os grupos em que participa. Elimine avaliações ou atualize para o premium com uma das duas opções abaixo.`,
      exceededTestsTip: (limit) => `Excedeu o limite de ${limit} ${limit === 1 ? 'avaliação' : 'avaliações'} ${limit === 1 ? 'gratuita' : 'gratuitas'} criados por si, englobando todos os grupos em que participa. Elimine avaliações ou atualize para o premium.`,
      upgrade: 'ATUALIZAR',
      getPremium: 'Obter Premium',
      unlimitedAccessMessage: 'Desbloqueie acesso ilimitado à Intuitivo individualmente.',
      recommendSchool: 'Recomendar Escola',
      recommend: 'RECOMENDAR',
      dedicatedSpace: 'Desbloqueie um espaço dedicado para a sua escola com acesso ilimitado à Intuitivo.',
      exceededExercises: (limit) => `Excedeu o limite de ${limit} ${limit === 1 ? 'exercício' : 'exercícios'} ${limit === 1 ? 'gratuito' : 'gratuitos'} criados por si, englobando todos os grupos em que participa. Elimine exercícios ou atualize para o premium com uma das duas opções abaixo.`,
      exceededExercisesTip: (limit) => `Excedeu o limite de ${limit} ${limit === 1 ? 'exercício' : 'exercícios'} ${limit === 1 ? 'gratuito' : 'gratuitos'} criados por si, englobando todos os grupos em que participa. Elimine exercícios ou atualize para o premium.`,
      exceededRubrics: (limit) => `Excedeu o limite de ${limit} ${limit === 1 ? 'rubrica' : 'rubricas'} ${limit === 1 ? 'gratuita' : 'gratuitas'} criadas por si, englobando todos os grupos em que participa. Elimine rubricas ou atualize para o premium com uma das duas opções abaixo.`,
      exceededRubricsTip: (limit) => `Excedeu o limite de ${limit} ${limit === 1 ? 'rubrica' : 'rubricas'} ${limit === 1 ? 'gratuita' : 'gratuitas'} criadas por si, englobando todos os grupos em que participa. Elimine rubricas ou atualize para o premium.`,
      exceededGroups: (limit) => `Excedeu o limite de ${limit} ${limit === 1 ? 'grupo' : 'grupos'} ${limit === 1 ? 'gratuito' : 'gratuitos'}. Saia de grupos ou atualize para o premium com uma das duas opções abaixo.`,
      exceededSections: (limit) => `Excedeu o limite de ${limit} ${limit === 1 ? 'secção' : 'secções'} ${limit === 1 ? 'gratuita' : 'gratuitas'} por teste. Elimine secções ou atualize para o premium com uma das duas opções abaixo.`,
      premiumFeature: 'Esta é uma funcionalidade premium. Atualize para o premium para desbloquear.',
      warningCreatePremiumExercise: 'Pode criar este tipo de exercício, mas não pode publicar testes que o contenha.',
      warningPublishTestWithPremiumExercise: 'Não pode publicar um teste que contenha exercícios premium. Remova esses exercícios ou atualize para o premium com uma das duas opções abaixo.',
      warningPublishTestWithExceededSections: (limit) => `Não pode publicar um teste que contenha mais de ${limit} ${limit === 1 ? 'secção' : 'secções'}. Remova essas secções ou atualize para o premium com uma das duas opções abaixo.`,
      warningExportTestMaxUsages: (limit) => `Só pode exportar no máximo ${limit} ${limit === 1 ? 'versão' : 'versões'}. Atualize para o premium para desbloquear mais.`,
      schools: 'Escolas',
    },
    navigation: {
      title: 'Navegação',
      section: 'Secção',
    },
    curricula: {
      pt_PT: 'Português',
      other: 'Outro',
      curriculum: 'Currículo',
    },
  },
});

export default lang;
