import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  exerciseContainer: {
    width: '100% !important',
    '& > .otl-cardContentContainer': {
      padding: '0px',
    },
    borderRadius: '18px',
    overflow: 'hidden',
    border: `1px solid ${theme.paginationBorder}`,
    boxShadow: '0 4px 4px #00000040',
    '&.noCard': {
      border: 'none',
      backgroundColor: 'unset',
      boxShadow: 'unset',
    },
    '&.colors': {
      boxShadow: 'unset',
      border: 'none',
    },
  },
}));
