import React, { useCallback, useMemo, useRef, useState } from 'react';
import { InputArea } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { extractOpsOnlyText } from 'utils';

import Input from 'components/common/Input';
import QuillRenderer from 'components/common/QuillRenderer';

import useStyles from './styles';

const ExerciseAnswerText = ({ exerciseId, answer, onAnswer, answerable, color, isShortAnswer, characterLimit, mathSymbols, enableMathSymbols, setWriting, hasWordCount }) => {
  const isDark = useSelector(state => state.page.isDark);
  const classes = useStyles();
  const timeoutRef = useRef();
  const quillAnswersToggle = useFeature(toggles.quillAnswers);
  const iaveToggle = useFeature(toggles.iave);
  const asyncAnswersToggle = useFeature(toggles.asyncAnswers);
  const wordCountToggle = useFeature(toggles.wordCount);

  const [characterLimitReached, setCharacterLimitReached] = useState(answer?.length === characterLimit);

  const words = useMemo(() => {
    const value = isShortAnswer ? answer : answer?.ops;
    const regex = /[^A-Za-z0-9'-]/;

    const extractedWords = isShortAnswer ? value?.split(regex) : extractOpsOnlyText(value)?.split(regex);
    const filteredWords = extractedWords?.filter(word => word !== '');
    return filteredWords?.length ?? 0;
  }, [answer, isShortAnswer]);

  const onInput = useCallback((event) => {
    const value = event.target?.value ?? event;

    if (asyncAnswersToggle) {
      onAnswer(value);
      return;
    }

    setWriting(true);

    setCharacterLimitReached(false);

    if (isShortAnswer && value.length === characterLimit) {
      setCharacterLimitReached(true);
    }

    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      onAnswer(value);
      setWriting(false);
    }, 2000);
  }, [isShortAnswer, characterLimit, onAnswer, setWriting, asyncAnswersToggle]);

  const onBlur = useCallback((event) => {
    if (asyncAnswersToggle) {
      return;
    }

    setWriting(false);
    const value = event.target?.value ?? event;

    clearTimeout(timeoutRef.current);

    onAnswer(value);
  }, [asyncAnswersToggle, onAnswer, setWriting]);

  return (
    <div>
      {!quillAnswersToggle && (
        <InputArea
          id={`${exerciseId}-input`}
          dark={isDark}
          placeholder={iaveToggle ? null : lang.test.textPlaceholder}
          defaultValue={answer}
          onInput={onInput}
          onBlur={onBlur}
          disabled={!answerable}
          maxLength={20000}
          className={cx(classes.inputArea, { color })}
        />
      )}
      {quillAnswersToggle && (
        <>
          {answerable && (
            <>
              {!isShortAnswer && (
                <Input
                  type="richtext"
                  placeholder={iaveToggle ? null : lang.test.textPlaceholder}
                  value={answer ?? {}}
                  onChange={onInput}
                  onBlur={(_range, _source, editor) => onBlur(editor.getContents())}
                  className={classes.richText}
                  enableMathSymbols={enableMathSymbols && answerable}
                  mathSymbols={mathSymbols}
                />
              )}
              {isShortAnswer && (
                <>
                  <Input
                    type="textarea"
                    placeholder={iaveToggle ? null : lang.test.textPlaceholder}
                    defaultValue={answer}
                    maxSize={characterLimit}
                    onChange={onInput}
                    onBlur={onBlur}
                    rows={1}
                  />
                  {characterLimitReached ? (
                    <div className={classes.characterLimitWarning}>
                      {lang.attempt.characterLimitReached}
                    </div>
                  ) : null
                  }
                </>
              )}
            </>
          )}
          {!answerable && (
            <>
              {isShortAnswer && (
                <Input
                  placeholder={iaveToggle ? null : lang.test.textPlaceholder}
                  value={answer}
                  disabled
                />
              )}
              {!isShortAnswer && (
                <>
                  {answer && (
                    <div className={classes.fakeBox}>
                      {typeof answer === 'string' && (
                        <>
                          {answer}
                        </>
                      )}
                      {typeof answer === 'object' && (
                        <QuillRenderer
                          value={answer}
                        />
                      )}
                    </div>
                  )}
                  {!answer && (
                    <Input
                      type="richtext"
                      placeholder={iaveToggle ? null : lang.test.textPlaceholder}
                      value={{}}
                      onChange={onInput}
                      onBlur={(_range, _source, editor) => onBlur(editor.getContents())}
                      className={classes.richText}
                      enableMathSymbols={enableMathSymbols}
                      disabled
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {hasWordCount && wordCountToggle && (
        <div className={classes.wordCount}>
          {`${lang.words}: ${words}`}
        </div>
      )}
    </div>
  );
};

ExerciseAnswerText.propTypes = {
  exerciseId: PropTypes.string,
  answer: PropTypes.any,
  onAnswer: PropTypes.func,
  answerable: PropTypes.bool,
  color: PropTypes.bool,
  isShortAnswer: PropTypes.bool,
  characterLimit: PropTypes.number,
  enableMathSymbols: PropTypes.number,
  mathSymbols: PropTypes.object,
  setWriting: PropTypes.func,
  hasWordCount: PropTypes.bool,
};

export default ExerciseAnswerText;
